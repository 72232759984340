/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
/* eslint-disable-next-line no-unused-vars */
import React from 'react';

export const locale = {
  "locales": ["de"], "messages": {
    "editor": {
      "first_name": "Vorname",
      "name": "Name",
      "subject": "Betreff",
      "message": "Nachricht",
      "custom_dropdown": "benutzerdefiniertes Dropdown",
      "custom_label": "Eigene Beschriftung",
      "set_autoplay": "Autoplay",
      "autoplay_info": "Achtung! Manche Browser erlauben in den meisten Fällen kein Autoplay. Stummgeschaltetes Autoplay ist jedoch immer erlaubt.",
      "set_muted": "Stumm",
      "data_protection_officer": "Datenschutzbeauftragter",
      "data_protection_authority": "Datenschutzbehörde",
      "button_font_color": "Schriftfarbe",
      "set_label_dropdown": "Bitte vergeben Sie ein Label für das Dropdown",
      "set_label_custom_field": "Bitte vergeben Sie ein Label für das Eingabefeld",
      "empty_field": "freies Feld",
      "custom_field": "Benutzerdefiniertes Feld",
      "change_label": "Label ändern",
      "edit_label": "Label bearbeiten",
      "salutation": "Anrede",
      "title": "Titel",
      "link_title": "Linkbezeichnung",
      "main_text": "Haupttext",
      "more_text": "weiterer Text",
      "create_text": "Text anlegen",
      "edit_text_slider": "Es wurde noch kein Textelement erstellt",
      "edit_rss_elements": "Noch keine Formelemente ausgewählt, hier klicken zum hinzufügen.",
      "rss_settings": "RSS Feed Einstellungen",
      "select_rss": "Sie können einen RSS Feed aus den Vorlagen auswählen",
      "available_rss": "Verfügbare RSS Feeds",
      "load_rss_part_1": "Sie haben den folgenden RSS Feed ausgewählt.",
      "load_rss_part_2": "Klicken Sie auf den Button 'RSS Feeds' abrufen, um die Feeds zu laden",
      "load_rss_feeds": "RSS Feeds abrufen",
      "color": "Farbe",
      "background_color": "Hintergrundfarbe",
      "distance_to_border": "Abstand zum Rand",
      "color_of_qrcode": "QR-Code Farbe",
      "click_me": "Klick mich",
      "new_menu_item": "Neuer Menüpunkt",
      "external": "extern",
      "imprint": "Impressum",
      "privacy": "Datenschutz",
      "january": "Januar",
      "february": "Februar",
      "march": "März",
      "april": "April",
      "may": "Mai",
      "june": "Juni",
      "july": "Juli",
      "august": "August",
      "september": "September",
      "october": "Oktober",
      "november": "November",
      "december": "Dezember",
      "monday": "Montag",
      "tuesday": "Dienstag",
      "wednesday": "Mittwoch",
      "thursday": "Donnerstag",
      "friday": "Freitag",
      "saturday": "Samstag",
      "sunday": "Sonntag",
      "edit_date": "Termin bearbeiten",
      "oclock": "Uhr",
      "filter_selection": "Filterauswahl",
      "use_filter": "Anwenden",
      "sample_picture_found": "Musterbild gefunden",
      "already_edited_in_another_tab": "Diese Edition wird möglicherweise bereits in einem anderen Tab bearbeitet.",
      "information_for_contact": "Daten für Kontaktformular",
      "mail_receiver": "E-Mail Empfänger",
      "google_analytics": "Google Analytics",
      "measurement_id": "Mess-ID für Google Analytics",
      "new_picture_inserted": "Neues Bild eingefügt",
      "text_changed": "Text geändert",
      "edit_title": "Hier klicken um den Titel zu bearbeiten",
      "edit_description": "Hier klicken um die Bildbeschreibung zu bearbeiten",
      "edit_copyright": "Hier Klicken um das Copyright zu bearbeiten",
      "no_changes": "Keine Änderungen",
      "changes_on_document": "Änderungen am Dokument",
      "changes_on_page": "Änderungen auf Seite",
      "page": "Seite",
      "choose_button_label": "Bitte wählen Sie eine Beschriftung für den Button",
      "edit_calendly": "Hier klicken um die Einstellungen für Calendly zu bearbeiten",
      "exchanged_with_page": "getauscht mit Seite",
      "upload_failed": "Upload fehlgeschlagen",
      "try_again": "Bitte versuchen Sie es erneut",
      "saving_failed": "Speichern fehlgeschlagen",
      "new_page_inserted": " neue Seite/n eingefügt nach Seite ",
      "delete_pages": "Seiten löschen",
      "all_content_deleted": "Seiten und sämtliche Inhalte darauf werden gelöscht. ",
      "yes_delete": "Ja, löschen",
      "pages_deleted": " Seite/n gelöscht, nach Seite ",
      "rather_not": "Lieber nicht",
      "new_here": "Sie sind neu hier?",
      "first_start": "Es sieht so aus als hätten Sie unseren neuen Editor zum ersten Mal geöffnet. Möchten Sie eine Einführung?",
      "yes_absolutely": "Ja, unbedingt",
      "im_fine": "Ich komm klar",
      "no_page_definitions_found": "Keine Seiten Definitionen gefunden!",
      "move_up": " Seite(n) nach oben verschieben",
      "side_position_fixed": "Seitenposition fixiert",
      "generated_in_pdf": "Seiten werden automatisch in der PDF generiert",
      "move_down": " Seite(n) nach unten verschieben",
      "by": "Um ",
      "add_page": " Seiten einfügen",
      "remove_page": " Seiten entfernen",
      "unsaved_changes": "Sie haben ungespeicherte Änderungen",
      "i_have_it": "Ok, jetzt hab ich's drauf!",
      "document_pictures": "Bilder im Dokument",
      "used_images": "Verwendete Bilder",
      "take_a_photo": "Bild aufnehmen",
      "unused_images": "Nicht verwendete Bilder",
      "content_is_saved": "Inhalte werden gespeichert",
      "page_arrangement": "Seitenanordnung",
      "print_sheet_view": "Ansicht Druckbogen",
      "view_safety_distance": "Ansicht Sicherheitsabstand",
      "view_structure": "Ansicht Struktur",
      "start_tutorial": "Tutorial starten",
      "adjust_by_window": "Seite an Fenster anpassen",
      "adjust_by_width": "Seite an Breite anpassen",
      "adjust_by_height": "Seite an Höhe anpassen",
      "delete_content": "Inhalt löschen",
      "delete_selected_content_block": "Dies entfernt den gewählten Inhaltsblock und seine Inhalte (Bilder, Texte).",
      "delete_children_of_parents": "Achtung es handelt sich um ein Elternelement. Wenn Sie dieses löschen werden alle dazugehörigen Kindelemente ebenfalls gelöscht.",
      "reset_content": "Inhalt zurücksetzen",
      "reset_content_block_to_root": "Dies setzt den gewählten Inhaltsblock und seine Inhalte (Bilder, Texte) auf den Ursprungszustand zurück.",
      "yes_reset": "Ja, zurücksetzen",
      "copy_full_content": "Gesamten Seiteninhalt kopieren",
      "paste_content": "Inhalt aus Zwischenablage einfügen",
      "enlarge_block": "Block vergrößern",
      "shrink_block": "Block verkleinern",
      "text_overflow": "Textüberlauf",
      "initial_image_available": " Initialbild vorhanden",
      "paste_content_after": "Inhalt aus Zwischenablage nach diesen einfügen",
      "deselect_element": "Element abwählen",
      "select_element": "Element auswählen",
      "block_can_not_move": "Block kann nicht verschoben werden",
      "up": "Nach oben",
      "reset_block": "Block zurücksetzen",
      "change_backgroundcolor": "Hintergrundfarbe ändern",
      "change_fontcolor": "Schriftfarbe ändern",
      "copy_block": "Block kopieren",
      "delete_block": "Block löschen",
      "down": "Nach unten",
      "product_in_cart": "Produkt im Warenkorb",
      "added_to_cart": " wurde Ihrem Warenkorb hinzugefügt.",
      "great": "Prima!",
      "to_cart": "Zum Warenkorb",
      "are_you_sure": "Sind Sie sich sicher?",
      "reset_to_selected_status": "Das Dokument wird auf den gewählten Stand zurückgesetzt",
      "abort": "Abbrechen",
      "reset_to_version": "Auf Version zurücksetzen",
      "choose_a_campaign": "Kampagne wählen",
      "name_for_edition": "Name für diese Edition",
      "save_to": "Speichern unter...",
      "save": "Speichern",
      "settings": "Einstellungen",
      "short_summary": "Kurze Inhaltsangabe",
      "keywords_separated": "Keywords mit Komma getrennt",
      "serial_letter": "Serienbrief",
      "activate_serial_letter": "Serienbrief aktivieren",
      "serial_letter_database": "Datenbank Serienbrief",
      "serial_letter_database_upload_info": "Der Upload unterstützt folgende Dateiformate - .xlsx, .xls und .csv",
      "serial_letter_info": "Bitte tragen Sie in der Datenbank in die Zellen der ersten Zeile die Platzhalter ein. Darunter die jeweiligen Werte. Zum Beispiel wie folgend: ",
      "preview_of_database": "Datenbankvorschau",
      "serial_letter_database_upload": "Hochladen",
      "no_error": "Ohne Fehler",
      "error": "Fehler",
      "information_for_imprint": "Angaben für Impressum",
      "responsible_for_content": "Inhaltlich Verantwortlicher",
      "company_association": "Firma / Verband",
      "street": "Straße",
      "zip_code": "Postleitzahl",
      "city": "Ort",
      "email": "E-Mail-Adresse",
      "phone_number": "Telefon",
      "data_protection_information": "Angaben zum Datenschutz",
      "responsible": "Verantwortlicher",
      "change": "Ändern",
      "export_image": "Bild exportieren",
      "preview": "Vorschau",
      "preview_button": "Button-Vorschau",
      "print_pdf": "Druck-PDF",
      "initialize": "Initialize!",
      "order": "Bestellen",
      "versions": "Versionen",
      "currently_used_image": "Aktuell verwendetes Bild",
      "upload_new_image": "Neues Bild hochladen",
      "new_cut": "Neu zuschneiden",
      "take_on": "Übernehmen",
      "choose_file": "Datei auswählen",
      "image_description": "Bildbeschreibung",
      "image_upload": "Bildupload",
      "mediathek": "Mediathek",
      "pixabay": "Pixabay",
      "add_content": "Inhalte hinzufügen",
      "copied_content_block": "Inhaltsblock kopiert",
      "unsaved_content": "Sie haben ungespeicherte Änderungen! Wollen Sie den Editor wirklich verlassen?",
      "filter_settings": "Filter-Einstellungen",
      "filter_grayscale": "Graustufen",
      "filter_sepia": "Sepia",
      "filter_blur": "verwischen",
      "filter_brightness": "Helligkeit",
      "filter_contrast": "Kontrast",
      "filter_hueRotate": "Farbton drehen",
      "filter_invert": "Farben umkehren",
      "filter_opacity": "Transparenz",
      "filter_saturate": "Sättigung",
      "filter_default": "Standard",
      "filter_set": "Eingestellt",
      "work_surface": "Hier befindet sich die Arbeitsfläche.",
      "work_surface_description": "Hier findet der Gestaltungsprozess statt. Bilder, Texte und Elemente können hier eingefügt und bearbeitet werden.",
      "page_navigation": "Hier befindet sich die Seitennavigation",
      "page_navigation_description": "Sie können hier zwischen den einzelnen Seiten wechseln und Seiten hinzufügen, entfernen oder miteinander tauschen.",
      "content_blocks": "Hier finden Sie die Inhaltsblöcke",
      "content_blocks_description": "Hier können Sie verschiedene Bild- und Textelemente durch Anklicken in Ihr Werbemittel einfügen. Sollte der Platz auf dem Werbemittel nicht ausreichen, können Sie jederzeit überflüssige Elemente löschen.",
      "screen_view": "Hier können Sie die Bildschirmansicht ändern.",
      "screen_view_description": "Hier können Sie die Ansicht Ihres Webemittels verändern. 100% stellt immer die Originalgröße des Werbemittels dar.",
      "error_display": "Hier werden Fehler angezeigt.",
      "error_display_description": "Das System benachrichtigt Sie hier, wenn ein Textüberlauf oder andere technische Fehler gefunden wurden.",
      "options": "Hier finden Sie Optionen für Ihr Werbemittel.",
      "options_description": "Klicken Sie auf den Vorschau-Button, um eine Vorschau-PDF zu generieren oder auf den Bestell-Button um das Werbemittel in den Warenkorb zu legen. Mit 'Speichern unter' können Sie ihr Werbemittel in eine andere Kampagne kopieren.",
      "tutorial_end": "Das war in aller Kürze unser Editor!",
      "tutorial_end_description": "Nun sind Sie in der Lage schnell und einfach Ihr Werbemittel unter Einhaltung des Corporate Designs zu gestalten.",
      "content_block_copied": "Inhaltsblock kopiert",
      "content_blocks_switched": "Inhaltsblöcke getauscht",
      "content_block_reset": "Inhaltsblock zurückgesetzt",
      "content_block_resize": "Größe Inhaltsblock geändert",
      "content_block_removed": "Inhaltsblock gelöscht",
      "no_place": "Kein Platz",
      "content_block_inserted": "Inhaltsblock eingefügt",
      "no_dates_available": "Keine Termine vorhanden, hier klicken um einen hinzuzufügen.",
      "new_date": "Neuer Termin",
      "edit_dates": "Termine bearbeiten",
      "date_description": "Beschreibung des Termins",
      "add": "Hinzufügen",
      "no_dates_availableTwo": "Keine Termine vorhanden",
      "date_title": "Titel des Termins",
      "date_place": "Ort des Termins",
      "date_from": "Am/Ab",
      "date_to": "Bis",
      "no_forms": "Noch keine Formelemente ausgewählt, hier klicken zum hinzufügen",
      "read_privacy_policy": "Ich habe die Datenschutzerklärung gelesen und verstanden.",
      "contact_form_settings": "Kontaktformular-Einstellungen",
      "dropdown_settings": "Dropdown-Einstellungen",
      "add_options": "Optionen hinzufügen",
      "dropdown_label": "Dropdown Label",
      "dropdown_option_fields": "Dropdown Optionsfelder",
      "pls_choose": "Bitte wählen Sie ein Formularelement",
      "pls_chooseTwo": "Bitte wählen Sie Ihre gewünschten Kontaktformularelemente",
      "send": "Absenden",
      "google_maps_element_settings": "Standorteinstellungen Google Maps",
      "no_location": "Sie haben noch keine Adresse hinzugefügt, hier klicken zum Bearbeiten",
      "found_textoverflow": "Textüberlauf gefunden",
      "minimum_size": "Mindestgröße",
      "too_low_resolution": "Bild hat zu geringe Auflösung!",
      "set_scale_free": "freies Skalieren aktivieren",
      "unset_scale_free": "freies Skalieren deaktivieren",
      "no_images_selected": "Noch keine Bilder ausgewählt, hier klicken zum hinzufügen",
      "gallery_settings": "Galerieeinstellungen",
      "add_image": "Bild hinzufügen",
      "add_image_front": "Bild am Anfang hinzufügen",
      "add_image_back": "Bild am Ende hinzufügen",
      "slider_images_invert": "Invertiere Bilderordnung",
      "image_text_settings": "Bildtexteinstellungen",
      "change_slider": "Um den Titel oder die Beschreibung zu ändern klicken Sie auf den jeweiligen Text im Slider",
      "change_image_title": "Bildtitel ändern",
      "change_image_description": "Bildbeschreibung ändern",
      "image_copyright": "Bildcopyright",
      "slider_settings": "Einstellungen Slider",
      "new_titel": "Neuer Titel",
      "new_link_title": "Neuer Linktitel",
      "edit_text_title": "Titel bearbeiten",
      "edit_text_link": "Link bearbeiten",
      "edit_link_title": "Linktitel bearbeiten",
      "edit_text_text": "Text bearbeiten",
      "add_element": "Element hinzufügen",
      "add_element_before": "Element vorne hinzufügen",
      "add_element_after": "Element hinten hinzufügen",
      "edit_text_of_element": "Um die Texte zu bearbeiten, klicken Sie im Slider auf den jeweiligen Text",
      "invert_elements": "Invertiere Reihenfolge",
      "edit_nav": "Navigation bearbeiten",
      "term": "Bezeichnung",
      "link": "Link",
      "actions": "Aktionen",
      "content_of_QRCode": "Welche Inhalte sollen im QR-Code enthalten sein:",
      "address_of_website": "Adresse der Website eingeben:",
      "name_surname": "Vor- und Nachname",
      "business_card": "Visitenkarte",
      "must_url": "Muss eine URL sein",
      "position": "Position",
      "phone": "Telefon",
      "website": "Webseite",
      "address": "Adresse",
      "email_message": "E-Mail Nachricht",
      "text": "Text",
      "recipient_email_address": "Empfänger E-Mail-Adresse",
      "reference": "Betreff",
      "content": "Inhalt",
      "your_text": "Ihr Text",
      "add_QRCode": "QR-Code einfügen",
      "no_preview_of_twitter": "Für Twitter ist hier leider keine Anzeige möglich, bitte nutzen Sie die Webseiten-Vorschau Funktion",
      "no_preview_of_calendly": "Für Calendly ist hier leider keine Anzeige möglich, bitte nutzen Sie die Webseiten-Vorschau Funktion",
      "twitter_link": "Eingestellter Twitter-Link:",
      "social_media_settings": "Social Media Einstellungen",
      "calendly_settings": "Calendly Einstellungen",
      "button_settings": "Button Einstellungen",
      "action_button_content_text": "Hier klicken um die Einstellungen für den Action-Button zu bearbeiten",
      "action_button_link_element": "Verweis auf ein Element",
      "action_button_external_link": "Verweis auf einen externen Link",
      "action_button_or": "oder",
      "button_color": "Buttonfarbe",
      "add_social_media_account": "Noch kein Social Media Account hinzugefügt, hier klicken um einen auszuwählen.",
      "choice_of_provider": "Auswahl Social Media Anbieter",
      "embed_code": "Embed Code von Social Media Kanal",
      "link_to_your_page": "Link zu Ihrer Seite bei",
      "link_to_your_calendly_page": "Link zu Ihrer Calendly-Seite",
      "link_to_your_page_two": "Link zu Ihrer Seite",
      "video_settings": "Videoeinstellungen",
      "add_video": "Noch kein Video ausgewählt, hier klicken um eines einzufügen",
      "choice_video_provider": "Auswahl Video Anbieter",
      "upload_video": "Video hochladen",
      "choice_mp4_file": "Video (.mp4) Datei auswählen",
      "nav_type": "Typ",
      "nav_type_url": "URL",
      "nav_type_page": "Seite",
      "nav_type_page_prefix": "Seite",
    },
    "medialibrary": {
      "new_folder": "Neuer Ordner",
      "sorting": "Sortierung",
      "directories": "Verzeichnisse",
      "medialibrary": "Mediathek",
      "media": "Medien",
      "upload_media": "Medien hochladen",
      "sorting_description": "Bitte beachten Sie, dass die Sortierung nach dem Dateinamen erfolgt und nicht nach dem definierten Titel.",
      "sorting_info_text": "Wählen Sie eine Sortierung für die Ordner und Medien. Die gesetzte Sortierung gilt auch innerhalb aller Ordneraufrufe.",
      "paste": "Einfügen",
      "info": "Hinweis!",
      "info_text": "Eine oder mehrere Dateien existieren bereits. Diese wurden nicht erneut hochgeladen.",
      "back_to_root": "Zurück ins Stammverzeichnis",
      "directory_by": "Verzeichnis von",
      "created_by": "Erstellt von",
      "no_folder_created": "Es wurden keine Ordner angelegt",
      "no_media_found": "Keine Medien im Ordner",
      "upload_processing": "Ihre Uploads werden verarbeitet",
      "not_uploaded": "Medien konnten nicht hochgeladen werden!",
      "close": "Schließen",
      "preview": "Bild-/ Videovorschau",
      "name": "Name",
      "upload_info": "Info",
      "options": "Optionen",
      "status": "Status",
      "adjust_meta": "Metadaten anpassen",
      "title": "Titel",
      "description": "Beschreibung",
      "save": "Speichern",
      "create_new_directory": "Neues Verzeichnis anlegen",
      "directory_name": "Verzeichnisname",
      "rename_folder": "Ordner umbenennen",
      "copyright": "Copyright",
      "upload": "Hochladen",
      "wastebasket": "Papierkorb",
      "are_you_sure_erase_file": "Sind Sie sicher, dass Sie die Datei unwiderruflich löschen wollen?",
      "are_you_sure_erase_folder": "Sind Sie sicher, dass Sie den Ordner unwiderruflich löschen wollen?",
      "erase": "Löschen"
    },
    "meta": {
      "campaigns": {
        "title": "Kampagnen",
        "description": "Kampagnen",
        "name": "Kampagnen"
      },
      "productShop": {
        "title": "Shop",
        "description": "Du suchst nach Merchandise-Artikel? Dann bist du hier genau richtig.",
        "name": "Artikel"
      },
      "carShop": {
        "title": "Fahrzeuge-Shop",
        "description": "Du suchst dein Traumauto? Dann schaue dir jetzt unsere Fahrzeuge an.",
        "name": "Fahrzeuge"
      },
      "car_detail_search": {
        "title": "Nicht sicher, welches Auto zu Ihnen passt? Nutzen Sie unsere Autosuche, um das perfekte Auto zu finden",
        "description": "Sie suchen ein Auto mit bestimmten Anforderungen, wissen jedoch nicht genau, welches Auto Sie benötigen? Wählen Sie Fahrzeugtyp, Antriebsstrang und Ausstattungsniveau.",
        "name": "Detailsuche"
      },
      "car_brands": {
        "title": "Wählen Sie Ihre Marke aus",
        "description": "Wählen Sie Ihre Marke aus und entdecken Sie die besten Angebote auf dem Markt.",
        "name": "Markenauswahl"
      },
      "car_models": {
        "title": "Wählen Sie das Modell Ihres {brandName}",
        "description": "Wählen Sie ein Modell aus dem beliebtesten {modelOne} bis {modelTwo}.",
        "name": "Modelle"
      },
      "car_model_drive": {
        "title": "Wählen Sie die Kraftstoffart und Getriebe für Ihren {brandName} {modelName} aus",
        "description": "Wir haben die {brandName} {modelName} mit {fuelTypes} Motoren. Verfügbare Getriebetypen sind {gearboxTypes}.",
        "name": "Kraftstoffart"
      },
      "car_model_equipment": {
        "title": "Wählen Sie die Ausrüstungsstufe für Ihren {brandName} {modelName} aus",
        "description": "Wählen Sie aus Budget, Familie, Komfort oder Business. Am beliebtesten, Komfort, bietet Parksensoren, Bluetooth und mehr.",
        "name": "Ausrüstung"
      },
      "favorites": {
        "title": "Ihre Favoriten, für später gespeichert",
        "description": "Es ist großartig, dass Sie einige unserer besten Artikel für später aufgehoben haben. Versuchen Sie nun, die gewünschte auszuwählen.",
        "name": "Favoriten"
      },
      "cart": {
        "title": "Warenkorb",
        "description": "Warenkorb",
        "name": "Warenkorb"
      },
      "checkout": {
        "title": "Kasse",
        "description": "Kasse",
        "name": "Kasse"
      },
      "clients": {
        "title": "Kunden",
        "description": "Kunden",
        "name": "Kunden"
      },
      "client_details": {
        "title": "Firma",
        "description": "Firma",
        "name": "Firma"
      },
      "leads": {
        "title": "Leads",
        "description": "Leads",
        "name": "Leads"
      },
      "lead_details": {
        "title": "Lead",
        "description": "Lead",
        "name": "Lead"
      },
      "cars": {
        "title": "Fahrzeuge",
        "description": "Fahrzeuge",
        "name": "Fahrzeuge"
      },
      "car_overview": {
        "title": "Fahrzeugübersicht",
        "description": "Fahrzeugübersicht",
        "name": "Fahrzeugübersicht"
      },
      "car_details": {
        "title": "Fahrzeug",
        "description": "Fahrzeug",
        "name": "Fahrzeug"
      },
      "pages": {
        "title": "Seite",
        "description": "Seite",
        "name": "Seite"
      },
      "page_details": {
        "title": "Seite",
        "description": "Seite",
        "name": "Seite"
      },
      "profile": {
        "title": "Profil",
        "description": "Profil",
        "name": "Profil"
      },
      "locations": {
        "title": "Standorte",
        "description": "Standorte",
        "name": "Standorte"
      },
      "company_locations": {
        "title": "Standorte",
        "description": "Standorte",
        "name": "Standorte"
      },
      "data_push": {
        "title": "Daten-Push",
        "description": "Daten-Push",
        "name": "Daten-Push"
      },
      "white_label_projects": {
        "title": "White-Label Projekte",
        "description": "White-Label Projekte",
        "name": "White-Label Projekte"
      },
      "users": {
        "title": "Benutzer",
        "description": "Benutzer",
        "name": "Benutzer"
      },
      "user_details": {
        "title": "Benutzer",
        "description": "Benutzer",
        "name": "Benutzer"
      },
      "user_import": {
        "title": "Benutzer importieren",
        "description": "Benutzer importieren",
        "name": "Benutzer importieren"
      },
      "teams": {
        "title": "Teams",
        "description": "Teams",
        "name": "Teams"
      },
      "questionnaires": {
        "title": "Fragebögen",
        "description": "Fragebögen",
        "name": "Fragebögen"
      },
      "questionnaire_details": {
        "title": "Fragebogen",
        "description": "Fragebogen",
        "name": "Fragebogen"
      },
      "document_types": {
        "title": "Dokumenttypen",
        "description": "Dokumenttypen",
        "name": "Dokumenttypen"
      },
      "document_type_details": {
        "title": "Dokumenttyp",
        "description": "Dokumenttyp",
        "name": "Dokumenttyp"
      },
      "templates": {
        "title": "Vorlagen",
        "description": "Vorlagen",
        "name": "Vorlagen"
      },
      "cms_elements": {
        "title": "CMS Elemente",
        "description": "CMS Elemente",
        "name": "CMS Elemente"
      },
      "cms_element_details": {
        "title": "CMS Element",
        "description": "CMS Element",
        "name": "CMS Element"
      },
      "brands": {
        "title": "Marken",
        "description": "Marken",
        "name": "Marken"
      },
      "models": {
        "title": "Modelle",
        "description": "Modelle",
        "name": "Modelle"
      },
      "categories": {
        "title": "Kategorien",
        "description": "Kategorien",
        "name": "Kategorien"
      },
      "category_details": {
        "title": "Kategorie",
        "description": "Kategorie",
        "name": "Kategorie",
        "authorization_on_mobile_device": "Berechtigung auf Mobilgeräten",
        "hide_category": "Kategorie ausblenden"
      },
      "attributes": {
        "title": "Attribute",
        "description": "Attribute",
        "name": "Attribute"
      },
      "attribute_details": {
        "title": "Attribut",
        "description": "Attribut",
        "name": "Attribut"
      },
      "attribute_groups": {
        "title": "Attributgruppen",
        "description": "Attributgruppen",
        "name": "Attributgruppen"
      },
      "dynamic_data_definitions": {
        "title": "Dynamische Datendefinitionen",
        "description": "Dynamische Datendefinitionen",
        "name": "Dynamische Datendefinitionen"
      },
      "pipelines": {
        "title": "Pipelines",
        "description": "Pipelines",
        "name": "Pipelines"
      },
      "pipeline_details": {
        "title": "Pipeline",
        "description": "Pipeline",
        "name": "Pipeline"
      },
      "items": {
        "title": "Produkte",
        "description": "Produkte",
        "name": "Produkte"
      },
      "item_details": {
        "title": "Produkt",
        "description": "Produkt",
        "name": "Produkt"
      },
      "calendar": {
        "title": "Kalender",
        "description": "Kalender",
        "name": "Kalender"
      },
      "orders": {
        "title": "Bestellungen",
        "description": "Bestellungen",
        "name": "Bestellungen"
      },
      "settings": {
        "title": "Einstellungen",
        "description": "Einstellungen",
        "name": "Einstellungen"
      },
      "four_zero_four": {
        "title": "404",
        "description": "404",
        "name": "404"
      },
      "account": {
        "title": "Account",
        "description": "Account",
        "name": "Account"
      },
      "forgot_password": {
        "linktext": "Passwort vergessen?",
        "title": "Passwort vergessen",
        "description": "Passwort vergessen",
        "name": "Passwort vergessen"
      },
      "renew_password": {
        "title": "Passwort erneuern",
        "description": "Passwort erneuern",
        "name": "Passwort erneuern"
      },
      "new_password_after_reset": {
        "title": "Neues Passwort nach dem Zurücksetzen",
        "description": "Neues Passwort nach dem Zurücksetzen",
        "name": "Neues Passwort nach dem Zurücksetzen"
      },
      "pricing": {
        "title": "Preise",
        "description": "Preise",
        "name": "Preise"
      },
      "signedup": {
        "title": "Registriert",
        "description": "Registriert",
        "name": "Registriert"
      },
      "go_pro": {
        "title": "Go pro",
        "description": "Go pro",
        "name": "Go pro"
      },
      "paypal": {
        "title": "Bezahle mit Paypal",
        "description": "Bezahle mit Paypal",
        "name": "Bezahle mit Paypal"
      },
      "lead_catchers": {
        "title": "Lead catchers",
        "description": "Lead catchers",
        "name": "Lead catchers"
      },
      "bills": {
        "title": "Rechnungen",
        "description": "Rechnungen",
        "name": "Rechnungen"
      },
      "provisionServiceDocuments": {
        "title": "Provisionen",
        "description": "",
        "name": ""
      },
      "commissionServiceDocuments": {
        "title": "Kommissionsdokumente",
        "description": "",
        "name": ""
      },
      "integrations": {
        "title": "Integrationen",
        "description": "Integrationen",
        "name": "Integrationen"
      },
      "order_complete": {
        "title": "Bestellung ausgeführt. Vielen Dank",
        "description": "Bestellung ausgeführt. Vielen Dank",
        "name": "Bestellung ausgeführt. Vielen Dank"
      },
      "order_details": {
        "title": "Bestelldetails",
        "description": "Bestelldetails",
        "name": "Bestelldetails"
      },
      "my_company": {
        "title": "Meine Firma",
        "description": "Meine Firma",
        "name": "Meine Firma"
      },
      "white_label_plugins": {
        "title": "White-Label Plugins",
        "description": "White-Label Plugins",
        "name": "White-Label Plugins"
      },
      "plugins": {
        "title": "Creacheck Plugins",
        "description": "Creacheck Plugins",
        "name": "Creacheck Plugins"
      },
      "bill_details": {
        "title": "Rechnungsdetails",
        "description": "Rechnungsdetails",
        "name": "Rechnungsdetails"
      },
      "activate_plugin": {
        "title": "Plugin aktivieren",
        "description": "Plugin aktivieren",
        "name": "Plugin aktivieren"
      },
      "model_groups": {
        "title": "Modellgruppen",
        "description": "Modellgruppen",
        "name": "Modellgruppen"
      },
      "model_group_details": {
        "title": "Modellgruppendetails",
        "description": "Modellgruppendetails",
        "name": "Modellgruppendetails"
      },
      "model_assets": {
        "title": "Modellbilder",
        "description": "Modellbilder",
        "name": "Modellbilder"
      },
      "model_asset_details": {
        "title": "Modell Bilddetails",
        "description": "Modell Bilddetails",
        "name": "Modell Bilddetails"
      },
      "lead_per_mail_rules": {
        "title": "Lead per E-Mail Regeln",
        "description": "Lead per E-Mail Regeln",
        "name": "Lead per E-Mail Regeln"
      },
      "car_rental": {
        "title": "Sichere und schnelle Buchung in {city}. {count} Autos zur Auswahl",
        "description": "Buchen Sie Ihr Auto schnell und sicher. Glauben Sie uns, ein Auto zu mieten war noch nie so einfach und schnell.",
        "name": "Vermietung"
      },
      "failed_refunds": {
        "title": "Fehlgeschlagene Erstattungen",
        "description": "Fehlgeschlagene Erstattungen",
        "name": "Fehlgeschlagene Erstattungen"
      },
      "companySignup": {
        "title": "Registrierung für Unternehmen",
        "description": "Registrieren Sie Ihr Unternehmen und profitieren Sie vom neuesten Ökosystem für Autohäuser",
        "name": "Registrierung für Unternehmen"
      },
      "price_negotiations": {
        "title": "Preisverhandlungen",
        "description": "Preisverhandlungen",
        "name": "Preisverhandlungen"
      },
      "price_negotiation_details": {
        "title": "Preisverhandlung",
        "description": "Preisverhandlung",
        "name": "Preisverhandlung"
      },
      "communities": {
        "title": "Communities Liste",
        "description": "Communities Liste",
        "name": "Communities Liste"
      },
      "car_service_locations": {
        "title": "Standorte an denen Servicetermine verfügbar ist",
        "description": "Standorte",
        "name": "Standorte"
      },
      "car_service_resources": {
        "title": "Wählen Sie einen Serviceberater",
        "description": "Serviceberater",
        "name": "Serviceberater"
      },
      "car_service_free_slots": {
        "title": "Wählen Sie ein freies Zeitfenster, um Ihr Auto abzustellen",
        "description": "Zeitfenster",
        "name": "Zeitfenster"
      },
      "car_service_client_data": {
        "title": "Loggen Sie sich ein oder registrieren Sie sich, um Ihren Termin zu buchen",
        "description": "Login",
        "name": "Login"
      },
      "car_service_car_data": {
        "title": "Geben Sie die Fahrzeugdaten ein, um den Service zu buchen",
        "description": "Fahrzeugdaten",
        "name": "Fahrzeugdaten"
      },
      "car_service_summary": {
        "title": "Letzter Schritt - Überprüfen Sie Ihre Daten und Auswahl",
        "description": "Überprüfen",
        "name": "Überprüfen"
      },
      "car_services": {
        "title": "Werkstatt Dienstleistungen",
        "description": "Dienstleistungen",
        "name": "Dienstleistungen"
      },
      "car_service_stations": {
        "title": "Werkstatt Arbeitsstationen",
        "description": "Arbeitsstationen",
        "name": "Arbeitsstationen"
      },
      "document_templates": {
        "title": "Documentvorlagen",
        "description": "Documentvorlagen",
        "name": "Documentvorlagen"
      },
      "document_template_details": {
        "title": "Documentvorlage",
        "description": "Documentvorlage",
        "name": "Documentvorlage"
      }
    },
    "sort": {
      "relevance": "Relevanz",
      "username": "Email",
      "lastName": "Nachname",
      "cdate": "Neueste zuerst",
      "cdateDesc": "Neueste zuerst",
      "dueDate": "Fälligkeitsdatum",
      "fav": "Beliebteste",
      "priceAsc": "Niedrigster Preis",
      "rentPriceAsc": "Niedrigster Preis",
      "priceDesc": "Höchster Preis",
      "rentPriceDesc": "Höchster Preis",
      "priority": "Priorität",
      "mdate": "Zuletzt aktualisiert",
      "countryName": "Land",
      "vat": "MwSt",
      "sumGross": "Betrag",
    },
    "jobTypes": {
      "wedding": "Hochzeit",
      "family_portrait": "Familienporträt",
      "maternity": "Mutterschaft",
      "newborn": "Neugeborenes",
      "couple": "Paar",
      "pet": "Haustier",
      "commercial_advertising": "Kommerziell / Werbung",
      "fashion": "Mode",
      "model_portfolio": "Modellportfolio",
      "event": "Event",
      "headshots": "Kopfschüsse",
      "real_estate_and_architecture": "Immobilien und Architektur",
      "other": "Sonstige"
    },
    "pipelineEvent": {
      "afterLeadCreated": "nach Lead erstellt",
      "afterJobAccepted": "nach dem Job akzeptiert",
      "beforeJobScheduled": "vor dem geplanten Job",
      "afterJobScheduled": "nach dem geplanten Job",
      "afterGalleryCreated": "nach Galerie erstellt",
      "afterPreviousStage": "nach vorherige Stufe"
    },
    "paymentEvents": {
      "beforeJobDate": "vor Jobsdatum",
      "onJobDate": "auf Jobsdatum",
      "afterJobDate": "nach Jobsdatum",
      "afterInvoiceDate": "nach Rechnungsdatum"
    },
    "autocomplete": {
      "placeholder": "Wählen...",
      "noResultsText": "Keine Ergebnisse gefunden",
      "loadingPlaceholder": "Laden"
    },
    "salesDoc": {
      "debtor_number": "Kundennummer",
      "order_number": "Bestellnummer",
      "order_totals": "Auftragssummen",
      "quote_from": "Von",
      "quote": "Angebot",
      "quote_for": "Angebot für",
      "quotes": "Angebote",
      "new_quote": "Neues Angebot",
      "invoice_from": "Von",
      "invoice": "Rechnung",
      "invoice_date": "Rechnungsdatum",
      "tax_invoice": "Rechnung",
      "order": "Bestellung",
      "credit_note": "Gutschrift",
      "credit_notes": "Gutschriften",
      "invoice_for": "Rechnung für",
      "invoices": "Rechnungen",
      "new_invoice": "Neue Rechnung",
      "cancellation_invoice_to": "Stornorechnung zu",
      "cancellation_invoice": "Stornorechnung",
      "to_refunded": "Noch zu erstatten: ",
      "positions": "Positionen",
      "issued": "Ausgestellt",
      "cancelled": "STORNO",
      "billing_period": "Abrechnungszeitraum",
      "billing_period_not_valid": "Abrechnungszeitraum ungültig",
      "pro_rate": "anteilig",
      "name": "Name",
      "description": "Beschreibung",
      "quantity": "Menge",
      "unit_price": "Einzelpreis",
      "inc_tax": "Inkl. Steuern",
      "exc_tax": "Ohne Steuern",
      "subtotal": "Zwischensumme",
      "total": "Gesamtbetrag",
      "amount_due": "Summe netto",
      "total_due": "Rechnungsbetrag",
      "target": "Mandant",
      "due_date": "Fällig am",
      "to_pay": "Noch zu zahlen: ",
      "payed": "Bezahlt",
      "payment": "Zahlung",
      "download_csv_ready": "CSV Export abgeschlossen",
      "no_vat_eu_country": "Steuerfreie Innergemeinschaftliche Lieferungen nach § 4 Nr. 1b UstG.",
      "no_vat_non_eu_country": "Steuerfreie Ausfuhrlieferung nach § 6 UstG",
      "thanks_for_order": "Wir bedanken uns für Ihren Auftrag. Sofern nicht anders angegeben, erfolgt die Ausführung der oben genannten Leistungen zum Datum dieser Rechnung.",
      "bill_by_date": "Bitte überweisen Sie den Betrag unter Angabe der Rechnungsnummer bis zum %date% auf folgendes Konto:",
      "intended_use": "Verwendungszweck",
      "paid_with_cash_cc": "Die Rechnung wurde bar / mit EC-Karte bezahlt",
      "amount_from_credit_card": "Der Betrag in Höhe von %amount% wird von Ihrer Kreditkarte abgebucht.",
      "amount_from_paypal": "Der Betrag in Höhe von %amount% wird von Ihrem PayPal-Konto abgebucht.",
      "debit_from_account": "Der Betrag in Höhe von %amount% wird von Ihrem Konto abgebucht.",
      "register_and_taxes": "Handelsregister & Steuern",
      "managing_director": "Geschäftsführer"
    },
    "provisionDoc": {
      "provision_calculation": "Provisionsberechnung",
      "client": "Kunde",
      "invoice_no_date": "Rechnungs-Nr. / -Datum",
      "payedDate": "bezahlt am",
      "serviceType": "Service Type",
      "amount": "Betrag",
      "provisionRate": "Provisionsrate",
      "provisionValue": "Provision",
      "totalAmount": "Gesamtbetrag",
      "provision": "Provision",
      "provisions": "Provisionen",
      "provision_for": "Provision für",
      "download_pdf": "Download PDF"
    },
    "paymentStatus": {
      "late": "Spät",
      "paid": "Bezahlt",
      "unpaid": "Unbezahlt",
      "inProgress": "In Bearbeitung"
    },
    "shopItem": {
      "plus_shipping": "zzgl. Versandkosten",
      "variants_possible": "Es sind verschiedene Auswahlmöglichkeiten vorhanden"
    },
    "sepaAgreement": {
      "sepa_direct_debit_mandate": "SEPA-Lastschrift-Mandat (Einzugsermächtigung)",
      "mandate_reference": "Mandatsreferenz",
      "will_be_communicated_separately": "wird separat mitgeteilt",
      "payee": "Zahlungsempfänger",
      "creditor": "Gläubiger",
      "payer": "Zahlungspflichtiger",
      "i_allow_payee_direct_debit": "Hiermit ermächtige ich den Zahlungsempfänger bis auf Widerruf, Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von dem Zahlungsempfänger auf mein Konto gezogenen Lastschriften einzulösen.",
      "note_i_can_claim_reimbursement_within_eight_weeks": "Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen.",
      "document_issued_electronically_and_valid_without_signature": "Diese Schreiben wurde maschinell erstellt und ist auch ohne Unterschrift gültig",
      "shortening_notice_period": "Verkürzung der Ankündigungsfrist",
      "deadline_for_advance_information_of_direct_debit_is_shortened_to_one_day": "Die Frist für die Vorabinformation der SEPA-Lastschrift wird auf einen Tag verkürzt.",
      "i_issue_sepa_direct_debit_mandate": "Hiermit erteile ich das SEPA-Lastschriftmandat"
    },
    "sad": {
      "offer": "Angebot",
      "modification": "Änderung",
      "order": "Bestellung",
      "offers": "Angebote",
      "orders": "Bestellungen",
      "offer_short": "Ang.",
      "order_short": "Best.",
      "conversion_rate_short": "CR",
      "conversion_rate": "Conversion Rate",
      "last_offer_date": "Letztes Angebot",
      "last_offer_date_short": "Letztes Ang.",
      "car_data": "Fahrzeugdaten",
      "class": "Klasse",
      "model_name": "Modellname",
      "model_name_short": "Modellname (kurz)",
      "model_range": "Baureihe",
      "body_design": "Karosserieform",
      "car_color": "Farbe",
      "base_price": "Grundpreis",
      "list_price": "Listenpreis",
      "optional_equipment": "Sonderausstattung",
      "incl": "inkl.",
      "factory_discount": "Werksnachlass",
      "cash_discount": "Barnachlass",
      "volume_discount": "Mengenrabatt",
      "transportation_costs": "Überführungskosten",
      "price_reduction": "Preisminderung",
      "car_image": "Fahrzeugbild aus Konfigurator",
      "discount": "Nachlass",
      "sales_price": "Verkaufspreis",
      "max_speed": "Höchstgeschwindigkeit",
      "car_acceleration": "Beschleunigung (0 - 100)",
      "online_code": "Online-Code",
      "sales_activity": "Verkaufsaktivität",
      "last_modified": "Letzte Änderung",
      "offer_date": "Angebotsdatum",
      "order_date": "Bestelldatum",
      "order_location": "Bestellort",
      "pre_contract_nr": "V-Auftrags-Nr.",
      "pre_contract_date": "V-Auftragsdat.",
      "contract_nr": "Auftrags-Nr.",
      "shipment_place": "Lieferort",
      "shipment_key": "Lieferschlüssel",
      "special_agreement": "Sondervereinbarung",
      "contact_person": "Ansprechpartner",
      "customer_account_number": "Debitorenkonto-Nr.",
      "financing": "Finanzierung",
      "leasing": "Leasing",
      "financing_product": "Produkt",
      "paid_deposit": "Anzahlung",
      "paid_deposit_leasing": "Leasing-Sonderzahlung",
      "credit_amount": "Gesamtkreditbetrag",
      "monthly_rate": "Monatliche Rate",
      "contract_duration": "Laufzeit",
      "total_mileage": "Laufleistung",
      "purchase_price": "Kaufpreis",
      "annual_percentage_rate": "Effektiver Jahreszins",
      "nominal_interest_percentage_rate": "Sollzins gebunden p.a.",
      "debit_interest_percentage_rate": "Nominalzins p.a.",
      "total_amount": "Gesamtbetrag",
      "final_instalment": "Schlussrate",
      "application_date": "Antragsdatum",
      "application_approval_date": "Genehmigungsdatum",
      "application_rejection_date": "Ablehnungsdatum",
      "client_private": "Privat",
      "client_business": "Gewerblich",
      "monthly_leasing_rate": "Monatl. Leasingrate",
      "monthly_financing_rate": "Monatl. Finanzierungsrate",
      "monthly_insurance_rate": "Monatl. KFZ-Versichungsbeitrag",
      "no_winleas_data_available": "Keine WinLEAS Daten verfügbar",
      "winleas_data_available": "Finanzierungs- (F) und / oder Leasing (L) Daten aus WinLEAS verfügbar",
      "discount_cash": "Barkulanz",
      "discount_volume": "Mengen-/Verwerterrabatt",
      "discount_environmental": "Umweltbonus",
      "discount_additional": "Zusatzkonditionen",
      "deduction": "Abschlag",
      "deduction_mbl": "Abschlag MBL",
      "deduction_mbl_interest": "Zinsabschlag MBL",
      "deduction_consultant": "Abschlag Berater",
      "deduction_consultant_interest": "Zinsabschlag Berater",
      "commission": "Provision",
      "commission_standard": "Standard",
      "commission_addition_deduction": "Auf- / Abschlag",
      "commission_subsequent_scope_of_supply": "Weiter. Lieferumfang",
      "commission_total": "Gesamt",
      "subsidy": "Subvention",
      "subsidy_contract_partner": "Subvention VP",
      "subsidy_factory": "Subvention Werk",
      "subsidy_mbvd": "Subvention MBVD",
      "shortcut_lp_kp": "LP/KP",
      "campaign": "Aktion",
      "service_rate": "Service-Rate",
      "residual_value": "Restwert",
      "table_residual_value": "Tabellenrestwert",
      "residual_value_change": "Restwertänderung",
      "ref": "bzgl.",
      "net": "netto",
      "gross": "brutto",
      "list_price_short": "LP",
      "sales_price_short": "KP",
      "note_save": "Notiz speichern",
      "notes": "Notizen",
      "salesman": "Verkäufer",
      "salesman_blacklist": "Verkäufer (Blacklist)",
      "salesman_number": "Verkäufer-Nr.",
      "salesman_number_short": "Nr.",
      "enterprise_number": "Betriebs-Nr.",
      "Mercedes": "Mercdes",
      "Smart": "Smart",
      "equipment_line": "Linie",
      "packages": "Pakete",
      "upholstery": "Polster",
      "single_equipments": "Sonstige",
      "included_in": "enthalten in",
      "store": "Filiale",
      "save_filterset": "Filtersatz speichern",
      "internal_activity": "Internes Geschäft",
      "offer_made": "Angebot erstellt",
      "in_progress": "In Bearbeitung",
      "predecessor_offer": "Vorgänger-Angebot / Duplikat",
      "lost": "Geschäft verloren",
      "ordered": "Bestellung durchgeführt",
      "canceled": "Bestellung storniert"
    },
    "rad": {
      "earnings": "Ertrag",
      "occupancy_rate": "Auslastung",
      "amount": "Betrag"
    },
    "external_account_status": {
      "login_fail": "Bitte überprüfen Sie die Zugangsdaten! Ansonsten können keine Daten mehr ausgelesen werden.",
      "login_warning": "Bitte überprüfen Sie die Zugangsdaten!",
      "login_valid": "Zugangsdaten korrekt",
      "last_successful_login": "Letzter erfolgreicher Login"
    },
    "categoryitems_stock": "Stück",
    "initialize": "Init",
    "customer": "Firma",
    "customer_": "Kunde",
    "select_mandant": "Mandant auswählen",
    "initial_title": "Initialtitel des Werbeträgers",
    "will_be_shown_to_the_user_in_frontend": "Wird User im Frontend angezeigt",
    "producttemplate_data_saved": "Templatedaten gespeichert",
    "product_size": "Produktformat / -größe",
    "template_upload_files": "Datei/en zum hochladen auswählen",
    "example_of_product_size": "z.B.: DIN A4, DIN lang...",
    "output_format": "Ausgabeformat",
    "product_size_width": "Breite in Pixel",
    "product_size_height": "Höhe in Pixel",
    "template_folder": "Templatepfad",
    "template_dpi": "DPI für Ausgabe",
    "template_upload": "Upload",
    "template_pageSpace": "Rasterplätze",
    "output_exportType": "Ausgabetyp",
    "template_fonts": "Schriftarten",
    "template_fontSizeMin": "Schriftgröße min",
    "template_styles": "CSS & LESS Daten",
    "template_fontSizeMax": "Schriftgröße max",
    "template_init_amount_of_pages": "Seitenanzahl initial",
    "template_page_add_increase": "Seitensprung",
    "template_pageMaxAmount": "Seitenanzahl max",
    "template_pageMinAmount": "Seitenanzahl min",
    "template_pageTop": "Padding Top",
    "template_pageBottom": "Padding Bottom",
    "template_pageFirst": "Seitenzahl Titelseite",
    "template_pageFirstFixed": "Titelseite fixiert",
    "template_pageLast": "Seitenzahl Letzte Seite",
    "template_pageLastFixed": "Letzte Seite fixiert",
    "template_endless": "Endlose Seite",
    "template_fixed": "Seite fixiert",
    "template_sample_page": "Seitenzahl Standardinhalt",
    "font_colors": "Textfarben",
    "back_colors": "Hintergrundfarben",
    "client_logo": "Firmenlogo",
    "template_pageNumber": "Seitenzahl",
    "page_names": "Seiteneinstellungen",
    "add_contentBlock": "Inhaltsblock hinzufügen",
    "css": "Generelles CSS",
    "cssPDF": "CSS nur für PDF Ausgabe",
    "cssPrintPDF": "CSS nur für Ausgabe druckfähige PDF",
    "cssWebsite": "CSS für Ausgabe als Webseite",
    "chosenItem": "Aktuelle Produktauswahl",
    "pdf_price": "PDF-Preis",
    "auflagen": "Auflagen",
    "print_prices": "Grundpreise",
    "proof_base_price": "Basis-Preis Datenprüfung",
    "new_proof_base_price": "Neue Basis-Preis Datenprüfung",
    "get_vendor_price": "Preise abrufen",
    "add_variant_line": "Zeile hinzufügen",
    "remove_all_item_variant": "Alle Auflagen löschen",
    "get_vendor_prices": "Preisabruf Lieferant",
    "get_vendor_id": "Lieferanten Produkt ID abfragen",
    "no_vendor_id": "Keine Lieferanten Produkt ID gesetzt!",
    "cc_item_id": "Produkt ID CreaCheck",
    "vendor_item_id": "Produkt ID Lieferant",
    "vendor_item_name": "Produktname Lieferant",
    "vendor_item_missing_attributes": "Für diese Attributkombination kann kein Preis gefunden werden.",
    "vendor_item_save_attributes": "Bitte wählen Sie die Attribute aus!",
    "vendor_item_must_define_attributes": "Sie müssen Attribute definieren, um Varianten zu erstellen.",
    "vendor_item_has_no_attributes": "Diese Produktgruppe hat keine Attribute",
    "vendor_item_all_n_quantities": "Zeigt alle {quantityCount} Mengen",
    "vendor": "Lieferant",
    "internal_item_id": "Interne ID",
    "select_vendor_product": "Produktgruppe auswählen",
    "item_type": "Produkt Typ",
    "print_item": "Druckprodukt",
    "website_item": "Webseite",
    "normal_item": "Anderes Produkt",
    "not_more_pages": "Es können nicht mehr Seitennamen angelegt werden, als Seiten möglich sind!",
    "info_title_page": "Titelseite kann nicht verschoben oder gelöscht werden",
    "info_last_page": "Letzte Seite kann nicht verschoben oder gelöscht werden",
    "page_definition_page_count_info": "Seitenzahl 0 = default Wert für alle Seiten ohne Definition, last für letzte Seite",
    "padding_on_top": "Abstände oben",
    "padding_on_bottom": "Abstände unten",
    "padding_on_left": "Abstände links",
    "padding_on_right": "Abstände rechts",
    "delimitation_of_grid_blocks": "Es können ohne Begrenzung Rasterblöcke hinzugefügt werden. Die Seite wird dann immer länger.",
    "fixed_page": "Die Seite wird fixiert und kann nicht verschoben werden.",
    "page_definition": "Seitendefinition",
    "add_definition": "Definition hinzufügen",
    "page_default_value": "Seitenzahl 0 = default Wert für alle Seiten ohne Definition",
    "page_count": "Seitenanzahl",
    "page_widths": "Seitenbreiten",
    "page_heights": "Seitenhöhen",
    "no_special_formatting": "Keine spezielle Formatierung",
    "min_max_font_size": "Min-/Max-FontSize",
    "activate_min_max_font_size": "Aktiviere oder Deaktiviere Min-/Max-FontSize",
    "filename": "Dateiname",
    "for_user": "Für Nutzer",
    "user_can_use_font": "User kann diese Schriftart im Editor verwenden",
    "import_successful": "Import abgeschlossen, schau in die anderen Tabs",
    "import_resets_template": "Import setzt das Template zurück, es muss neu Initialisiert werden, alle bestehenden Kunden-Editionen mit diesem Template werden unbrauchbar!!!",
    "problem": "Es ist ein Problem aufgetreten",
    "reading_pages": "Lese Seiten",
    "done": "Fertig",
    "versions": "Versionen",
    "template_not_edited": "Dieses Template wurde noch nicht bearbeitet.",
    "dataProof_infoText": "In einer manuellen Datenprüfung überprüfen unsere Experten alle technischen Kriterien der Druckvorstufe für Sie. Darunter sind die Druckbarkeit, die Einhaltung aller technischen Vorgaben und die subjektive Farbwirkung. Genauere Informationen zur Datenprüfung finden Sie unter FAQ.",
    "media_library": "Mediathek",
    "count": "Anzahl",
    "welcome_message": "Hallo, {name}",
    "email": "Email",
    "website": "Webseite",
    "phone": "Telefon",
    "fax": "Fax",
    "password": "Passwort",
    "repeat_password": "Passwort wiederholen",
    "company": "Firma",
    "company_name": "Firmenname",
    "company_data_timeline_tab_title": "Zeitleiste der Unternehmensdaten",
    "company_data_success": "Firmendaten werden erfolgreich geändert!",
    "company_data_error": "Fehler beim Ändern der Firmendaten!",
    "updated_by": "Aktualisiert von",
    "companies": "Firmen",
    "first_name": "Vorname",
    "last_name": "Nachname",
    "street": "Straße",
    "house_number": "Hausnummer",
    "apartment_number": "Wohnungsnummer",
    "address_line2": "Adresszeile 2",
    "zip_code": "Postleitzahl",
    "city": "Stadt",
    "country": "Land",
    "currency": "Währung",
    "previous_year": "Vorjahr",
    "vat_name": "VAT Name",
    "vat_id_name": "VAT ID Name",
    "vat_id": "USt - IdNr",
    "vat_rates": "VAT Sätze",
    "coc_number": "COC-Nummer",
    "registered_at": "Eingetragen bei",
    "page": "Seite",
    "page_of": "von",
    "save": "Speichern",
    "save_and_email": "Speichern und E-Mail",
    "add": "Hinzufügen",
    "add_client": "Firma hinzufügen",
    "update_company_data": "Firmenname ändern",
    "add_user": "Benutzer hinzufügen",
    "add_product": "Produkt hinzufügen",
    "add_producttemplate": "Template hinzufügen",
    "add_question": "Frage hinzufügen",
    "add_option": "Option hinzufügen",
    "add_questionnaire": "Fragebogen hinzufügen",
    "add_template": "Vorlage hinzufügen",
    "add_position": "Position hinzufügen",
    "add_pipeline": "Pipeline hinzufügen",
    "add_quote": "Angebot hinzufügen",
    "add_invoice": "Rechnung hinzufügen",
    "add_stage": "Stufe hinzufügen",
    "add_gallery": "Galerie hinzufügen",
    "add_job": "Job hinzufügen",
    "add_payment_schedule": "Zahlungsplan hinzufügen",
    "add_payment": "Zahlung hinzufügen",
    "add_location": "Standort hinzufügen",
    "add_data_definition": "Datendefinition hinzufügen",
    "add_car": "Fahrzeug hinzufügen",
    "add_document": "Dokument hinzufügen",
    "add_document_type": "Dokumenttyp hinzufügen",
    "add_team": "Team hinzufügen",
    "add_brand": "Marke hinzufügen",
    "add_model": "Modell hinzufügen",
    "add_model_group": "Modellgruppe hinzufügen",
    "add_model_asset": "Modellbild hinzufügen",
    "add_category": "Kategorie hinzufügen",
    "add_attribute": "Eigenschaft hinzufügen",
    "add_attribute_group": "Attributgruppe hinzufügen",
    "add_value": "Wert hinzufügen",
    "add_variant": "Variant hinzufügen",
    "add_lead": "Lead hinzufügen",
    "add_task": "Aufgabe hinzufügen",
    "add_page": "Seite hinzufügen",
    "add_data_push": "Daten-Push hinzufügen",
    "add_white_label": "White-Label-Projekt hinzufügen",
    "add_menu_element": "Menüelement hinzufügen",
    "add_secondary_menu_element": "Sekundäres Menüelement hinzufügen",
    "add_cms_element": "CMS Element hinzufügen",
    "add_lead_catcher": "Lead catcher hinzufügen",
    "add_white_label_plugin": "White-Label Plugin hinzufügen",
    "add_lead_per_mail_rule": "Lead per E-Mail Regel hinzufügen",
    "add_message": "Eine Nachricht hinzufügen",
    "add_car_service": "Dienstleistung hinzufügen",
    "add_car_service_station": "Arbeitsstation hinzufügen",
    "add_team_member": "Teammitglied hinzufügen",
    "add_document_template": "Dokumentvorlage hinzufügen",
    "add_lead_autoresponse": "Lead Autoresponse hinzufügen",
    "add_main_menu_version": "Hauptmenüversion hinzufügen",
    "add_lead_tagger": "Lead Tagger hinzufügen",
    "add_lead_assignment_strategy": "Zuweisungsstrategie hinzufügen",
    "add_escalation_rule": "Eskalationsregel hinzufügen",
    "add_coupon": "Gutschein hinzufügen",
    "add_company": "Firma hinzufügen",
    "add_item": "Produkt hinzufügen",
    "add_manual_entry": "manuelle Eingabe hinzufügen",
    "add_allowed_domain": "Erlaubte Domain hinzufügen",
    "update_allowed_domain": "Erlaubte Domain aktualisieren",
    "send_message": "Nachricht senden",
    "send": "Senden",
    "details": "Details",
    "delete": "Löschen",
    "undelete": "Wiederherstellen",
    "undelete_user_confirm": "Den Benutzer wiederherstellen?",
    "or": "oder",
    "actions": "Aktionen",
    "client": "Firma",
    "job": "Job",
    "active_jobs": "Aktive Jobs",
    "questionnaire": "Fragebogen",
    "review_and_send": "Überprüfen und senden",
    "download": "Herunterladen",
    "duplicate": "Duplizieren",
    "client_name": "Name",
    "address": "Adresse",
    "title": "Titel",
    "uploading": "Hochladen",
    "status": "Status",
    "next_stage": "Nächste Stufe",
    "type": "Typ",
    "schedule": "Zeitplan",
    "location": "Standort",
    "sent_on": "Gesendet am",
    "name": "Name",
    "description": "Beschreibung",
    "bill_number": "Rechnungsnummer",
    "quote_number": "Angebotsnummer",
    "do_sort": "Sortieren",
    "date": "Datum",
    "gallery": "Galerie",
    "questionnaires": "Fragebögen",
    "job_takes_whole_day": "Dieser Job nimmt den ganzen Tag",
    "from": "Von",
    "to": "An",
    "question": "Frage",
    "subject": "Thema",
    "content": "Inhalt",
    "template": "Vorlage",
    "stage_has_due_date": "Diese Stufe hat ein Fälligkeitsdatum",
    "stage_sends_automated_email": "Diese Stufe sendet eine automatisierte E-Mail",
    "day": "Tag",
    "days": "Tage",
    "hours": "Stunden",
    "review": "Überprüfen",
    "timezone": "Zeitzone",
    "client_since": "Firma hinzugefügt am",
    "user_since": "Benutzer seit",
    "payment_instructions": "Zahlungsanweisungen",
    "bank_name": "Bank Name",
    "debtor_number": "Debitor-Id",
    "account_holder": "Kontoinhaber",
    "account_number": "Kontonummer",
    "bank_location": "Bankstandort",
    "bank_code_number": "Bankleitzahl",
    "iban": "IBAN",
    "swift_bic": "SWIFT/BIC",
    "company_logo": "Firmenlogo",
    "percent_of_order": "Prozentsatz der Bestellung",
    "percent_of_remaining_balance": "Prozent des Restbetrags",
    "amount": "Betrag",
    "due": "Fällig",
    "payment_schedule": "Zahlungsplan",
    "record_payment": "Zahlung protokollieren",
    "mark_as_unpaid": "Als unbezahlt markieren",
    "close": "Schließen",
    "yes": "Ja",
    "want_to_mark_the_payment_as_paid": "Möchten Sie diese Zahlung wirklich als bezahlt markieren?",
    "want_to_mark_the_payment_as_unpaid": "Möchten Sie diese Zahlung wirklich als unbezahlt markieren?",
    "payment_marked_as_paid": "Zahlung protokolliert",
    "payment_marked_as_unpaid": "Zahlung als unbezahlt markiert",
    "overdue_payments": "Überfällige Zahlungen",
    "next_payment": "Nächste Zahlung",
    "quote_accepted": "Angebot akzeptiert",
    "quote_rejected": "Angebot abgelehnt",
    "todays_tasks": "Die heutigen Aufgaben",
    "overdue_tasks": "Überfällige Aufgaben",
    "overdue_and_upcoming_payments": "Überfällige und bevorstehende Zahlungen",
    "jobs_in_progress": "Jobs in Arbeit",
    "appointments": "Termine",
    "appointment": "Termin",
    "last_update": "Letzte Aktualisierung",
    "mark_as_completed": "Als abgeschlossen markieren",
    "want_to_mark_stage_complete": "Möchten Sie die Stufe als abgeschlossen markieren?",
    "stage_marked_complete": "Stufe als abgeschlossen markiert",
    "gallery_download": "Bild herunterladen",
    "gallery_sharing": "Galerie teilen",
    "gallery_social_media": "Bilder auf Social Media",
    "preview": "Vorschau",
    "invoice_preview": "Rechnungsvorschau",
    "preview_pdf": "Vorschau-PDF",
    "message": "Nachricht",
    "signup": "Registrieren",
    "continue": "Fortsetzen",
    "password_assistance": "Passwort Hilfe",
    "password_renewing": "Neues Passwort",
    "not_any_user_information": "Keine Benutzerinformationen.",
    "failed_redirection": "Weiterleitung fehlgeschlagen.",
    "renew_password": "Bevor Sie sich einloggen können, müssen Sie ein neues Passwort vergeben. Wir haben Ihnen gerade eine E-Mail mit Anweisungen gesendet.",
    "get_new_password": "Neues Passwort anfordern",
    "set_new_password": "Setzen Sie Ihr neues Passwort ein",
    "forgot_password.infotext": "Wenn Sie Ihr Passwort vergessen haben, können Sie es hier zurücksetzen. Bitte geben Sie Ihre registrierte E-Mail Adresse in das untenstehende Formular ein und klicken Sie auf „Fortsetzen“. Darauf senden wir Ihnen eine E-Mail mit weiteren Infos.",
    "forgot_password.infotext_robethood": "Wenn Du dein Passwort vergessen hast, kannst du es hier zurücksetzen. Bitte gebe die registrierte E-Mail Adresse in das untenstehende Formular ein und klicke auf „Fortsetzen“. Darauf senden wir Dir eine E-Mail mit weiteren Infos.",
    "forgot_password.info_email_not_used": "Wenn Sie die mit Ihrem Konto verknüpfte E-Mail-Adresse nicht mehr verwenden, kontaktieren Sie uns bitte über unser Kontaktformular: {link}.",
    "forgot_password.info_email_not_used_robethood": "Wenn Du die mit Deinem Konto verknüpfte E-Mail-Adresse nicht mehr verwendest, kontaktiere uns bitte über unser Kontaktformular: {link}.",
    "forgot_password.enter_new_password": "Es gibt nur diesen Schritt vor Ihnen, um sich bei Creacheck anzumelden. Geben Sie einfach Ihr neues Passwort zweimal ein. Es ist so einfach.",
    "thanks_for_signing_up": "Danke für's Registrieren",
    "confirm_your_email": "Bestätigen Sie Ihre E-Mail-Adresse",
    "signed_up_gratulations": "Herzlich Willkommen bei CreaCheck. Um den Anmeldevorgang abzuschließen, haben wir Ihnen eine E-Mail gesendet. Bitte bestätigen Sie Ihre Anmeldung.",
    "signed_up_gratulations_robethood": "Herzlich Willkommen. Um den Anmeldevorgang abzuschließen, haben wir Dir eine E-Mail gesendet. Bitte bestätige Deine Anmeldung.",
    "check_email_for_message_from_us": "Überprüfen Sie bitte Ihren E-Mail-Posteingang.",
    "check_email_for_message_from_us_robethood": "Überprüfe bitte deinen E-Mail-Posteingang",
    "new_clients": "Neue Kunden",
    "users": "Benutzer",
    "user": "Benutzer",
    "won_from_count_leads": "Gewann von {count} Leads",
    "deals_won_out_of_count": "Deals gewann von {count}",
    "new_badge": "Neu",
    "answer_badge": "Antwort",
    "accept": "Akzeptieren",
    "reject": "Ablehnen",
    "quote_rejected_on_date": "Angebot abgelehnt am {date}",
    "quote_accepted_on_date": "Angebot akzeptiert am {date}",
    "want_to_accept_the_quote": "Wollen Sie das Angebot akzeptieren? Der Auftragnehmer wird direkt über Ihre Entscheidung informiert.",
    "want_to_reject_the_quote": "Wollen Sie das Angebot ablehnen? Der Auftragnehmer wird direkt über Ihre Entscheidung informiert.",
    "want_to_convert_quote_to_invoice": "Möchten Sie dieses Angebot in Rechnung konvertieren?",
    "convert_quote_to_invoice": "Das Angebot in Rechnung konvertieren",
    "convert_to_invoice": "Konvertieren in Rechnung",
    "convert_quote_from_template": "Angebot in eine andere Vorlage konvertieren",
    "completed_on_date": "Abgeschlossen am {date}",
    "due_date_on_date": "Fälligkeitsdatum {date}",
    "due_date": "Fälligkeitsdatum",
    "current_stages_due_date": "Fälligkeitsdatum der aktuellen Stufe",
    "go_pro": "Go Pro",
    "recent_activity": "Letzte Aktivität",
    "load_more": "Mehr laden",
    "your_galleries": "Deine Galerien",
    "unread_by_you": "Von Ihnen ungelesen",
    "count_images": "{count} Bilder",
    "view": "Ansehen",
    "filled_out_on": "Ausgefüllt am",
    "filled_out_by": "Ausgefüllt von",
    "you_answered_the_questionnaire_on_date": "Sie haben am {date} den Fragebogen beantwortet",
    "company_sent_a_questionnaire": "{company} hat einen Fragebogen gesendet und bittet um Antworten",
    "allow_to_download_full_res": "Erlauben Sie dem Kunden, Bilder in voller Auflösung herunterzuladen",
    "allow_to_share_with_friends": "Erlaube dem Kunden, die Galerie mit Freunden zu teilen",
    "allow_to_share_on_social_media": "Erlauben Sie dem Kunden, Bilder über Social Media zu teilen",
    "got_it": "OK",
    "learn_more": "Mehr erfahren",
    "this_website_uses_cookies": "Unsere Website verwendet Cookies, um sicherzustellen, dass Sie die beste Erfahrung auf unserer Website erhalten.",
    "are_you_sure": "Sind Sie sicher?",
    "no": "Nein",
    "closed_please_add_timeframe": "<span><span className='text-danger semi-bold'>Geschlossen</span> - bitte fügen Sie einen Zeitrahmen hinzu</span>",
    "not_active_please_add_timeframe": "<span><span className='text-danger semi-bold'>Nicht aktiv</span> - bitte fügen Sie einen Zeitrahmen hinzu</span>",
    "note": "Notiz",
    "change_password": "Passwort ändern",
    "roles": "Rollen & Rechte",
    "active_set": "Aktuelles Abo",
    "infos": "Informationen",
    "documents": "Dokumente",
    "document": "Dokument",
    "financial": "Finanziell",
    "bookingAccountNumber": "Buchungskonto",
    "costAccountNumber": "Kostenstelle",
    "locations": "Standorte",
    "white_label": "White-Label",
    "show_password_text": "Passworttext anzeigen",
    "parent": "Parent",
    "change_parent": "Parent ändern",
    "cancel": "Abbrechen",
    "timeline_undo": "Rückgängig machen",
    "confirm_action_ramifications": "Bitte bestätigen Sie, dass Sie die Auswirkungen Ihrer Aktion verstehen",
    "client_will_not_be_visible_in_search_results": "Der ausgewählte Kunde wird in Ihrem Kundensuche nicht mehr sichtbar",
    "changing_parent_takes_a_while": "Das Ändern des Vorläufers kann eine Weile dauern",
    "load_message_from_template": "Nachricht aus der Vorlage laden",
    "questions": "Fragen",
    "search": "Suchen",
    "search_for": "Suche nach",
    "dynamic_data_definitions": "Dynamische Datendefinitionen",
    "dynamic_data_definition": "Dynamische Datendefinition",
    "data_identifier": "Datenidentifizierer",
    "cars": "Fahrzeuge",
    "rentals": "Mieten",
    "no_car_available": "Kein Wagen verfügbar",
    "file": "Datei",
    "files": "Dateien",
    "teams": "Teams",
    "team": "Team",
    "document_types": "Dokumenttypen",
    "document_type": "Dokumenttyp",
    "system_type": "Systemtyp",
    "brands": "Marken",
    "models": "Modelle",
    "model": "Modell",
    "model_groups": "Modellgruppen",
    "model_group": "Modellgruppe",
    "model_assets": "Modellbilder",
    "model_asset": "Modellbild",
    "categories": "Kategorien",
    "attributes": "Attribute",
    "attribute": "Attribut",
    "values": "Werte",
    "value": "Wert",
    "select_category": "Kategorie wählen",
    "position": "Position",
    "color": "Farbe",
    "car": "Produkt",
    "category": "Kategorie",
    "brand": "Marke",
    "required": "Pflichtfeld",
    "multilanguage": "Mehrere Sprachen",
    "seo": "SEO",
    "page_title": "Seitentitel",
    "keywords": "Schlüsselwörter",
    "item": "Artikel",
    "sku": "SKU",
    "variants": "Varianten",
    "variant": "Variante",
    "price": "Preis",
    "prices": "Preise",
    "variant_attributes": "Variantattribute",
    "no_value_set": "Kein Wert gesetzt",
    "identification": "Identifizierung",
    "templates": "Templates",
    "vin": "FIN",
    "leads": "Leads",
    "lead": "Lead",
    "order_it": "Bestellen",
    "order": "Bestellung",
    "orders": "Bestellungen",
    "images": "Bilder",
    "image": "Bild",
    "upc": "UPC",
    "visibility": "Sichtbarkeit",
    "show_item_online": "Produkt Online sichtbar",
    "use_as_resource": "Als Ressource verwenden",
    "stock_and_prices": "Bestand und Preise",
    "sales_price": "Verkaufspreis",
    "sales_point": "Vetrieb",
    "warehouse": "Lager",
    "stock_level": "Bestand",
    "rent_price": "Mietpreis",
    "pseudo_price": "Pseudopreis",
    "express_price": "Express Preis",
    "overnight_price": "Overnight Preis",
    "sameday_price": "Sameday Preis",
    "basic_price": "Grundpreis",
    "weekday_price": "Wochentag Preis",
    "weekend_price": "Wochenendpreis",
    "week_price": "Wochenpreis",
    "month_price": "Monatspreis",
    "unit_of_measure": "Maßeinheit",
    "basic_unit": "Grundeinheit",
    "packaging_unit": "Verpackungseinheit",
    "subscribe": "Abonnieren",
    "all": "Alle",
    "pipeline": "Pipeline",
    "pipelines": "Pipelines",
    "default_team": "Standard-Team",
    "select_pipeline": "Eine Pipeline auswählen",
    "please_select_pipeline_first": "Bitte zuerst eine Pipeline auswählen",
    "view_type": "Ansicht",
    "stage": "Stufe",
    "stages": "Stufen",
    "properties": "Eigenschaften",
    "unassigned": "Ohne Zuweisung",
    "assignee": "Bearbeiter",
    "order_type": "Bestelltyp",
    "internal_comment": "Interner Kommentar",
    "comments": "Kommentare",
    "comment": "Kommentar",
    "no_comments_yet": "Es gibt noch keine Kommentare",
    "show_all_comments": "Alle Kommentare anzeigen",
    "people": "Personen",
    "watchers": "Beobachter",
    "dates": "Termine",
    "date_created": "Erstellt",
    "date_updated": "Aktualisiert",
    "closed": "Geschlossen",
    "start_watching_lead": "Fang an, dieses Lead zu beobachten",
    "stop_watching_lead": "Hör auf, dieses Lead zu beobachten",
    "no_longer_watching_lead": "Sie beobachten dieses Lead nicht mehr",
    "watching_lead": "Sie beobachten dieses Lead",
    "wrote_comment": "Hat einen Kommentar geschrieben",
    "replied_to_comment": "Hat auf einen Kommentar geantwortet",
    "language": "Sprache",
    "languages": "Sprachen",
    "primary_language": "Hauptsprache",
    "secondary_languages": "Sekundärsprachen",
    "won": "Gewonnen",
    "lost": "Verloren",
    "change_pipeline": "Pipeline ändern",
    "start_pipeline": "Pipeline starten",
    "pipeline_started": "Pipeline gestartet",
    "change_stage": "Stufe ändern",
    "switch_team": "Team wechseln",
    "open": "Offen",
    "budget": "Budget",
    "always_available": "Immer verfügbar",
    "default_location": "Standardstandort",
    "selected_category_has_no_attributes": "Ausgewählte Kategorie hat keine Attribute",
    "car_overview": "Fahrzeugübersicht",
    "make_reservation": "Reservieren",
    "reservation": "Reservierung",
    "cms_pages": "Seiten",
    "grid_fluid": "Flüssig",
    "grid_normal": "Normal",
    "cms_add_container": "Container hinzufügen",
    "cms_add_row": "Zeile hinzufügen",
    "cms_add_element": "Element hinzufügen",
    "media_files": "Mediendateien",
    "upload": "Hochladen",
    "page_design": "Seitengestaltung",
    "translations": "Übersetzungen",
    "translate": "Übersetzen",
    "category_filters": "Kategoriefilter",
    "attribute_groups": "Attributgruppen",
    "select_attribute": "Attribut wählen",
    "display": "Darstellen",
    "select_multiple": "Mehrfachauswahl",
    "use_null_as_min_val": "0 als Minimum setzen",
    "filter": "Filter",
    "do_filter": "Filtern",
    "mobile_phone": "Mobiltelefon",
    "mark_lead_won": "Markiere Lead als gewonnen",
    "mark_lead_lost": "Markiere Lead als verloren",
    "mark_lead_deleted": "Markiere Lead als gelöscht",
    "reopen_lead": "Lead wieder öffnen",
    "won_reason": "Grund, warum gewonnen",
    "lost_reason": "Grund, warum verloren",
    "delete_reason": "Grund, warum gelöscht",
    "reopen_reason": "Grund zur Wiedereröffnung",
    "no_reason_specified": "Kein Grund angegeben",
    "lead_marked_won": "Lead markiert gewonnen. Glückwünsche",
    "lead_marked_lost": "Lead markiert verloren",
    "lead_marked_deleted": "Lead markiert gelöscht",
    "lead_reopened": "Lead wieder eröffnet",
    "stage_changed": "Lead Stufe geändert",
    "pipeline_changed": "Lead Pipeline geändert",
    "team_changed": "Lead einem anderen Team zugeordnet",
    "current_pipeline": "Aktuelle Pipeline",
    "current_stage": "Aktuelle Stufe",
    "file_extension": "Erweiterung",
    "file_size": "Größe",
    "file_uploaded": "Hochgeladen",
    "no_client_assigned": "Kein Kunde zugeordnet",
    "no_products_assigned": "Keine Produkte zugeordnet",
    "no_order_assigned": "Keine Bestellung zugeordnet",
    "planned_duration_in_days": "Geplante Dauer (Tage)",
    "lead_budget_must_be_set": "Lead-Budget muss gesetzt werden",
    "client_must_be_assigned": "Kunde muss zugeordnet werden",
    "order_must_be_assigned": "Bestellung muss zugeordnet werden",
    "product_must_be_assigned": "Produkt muss zugeordnet werden",
    "lead_has_to_have_following_documents": "Lead muss folgende Dokumente haben (Dokumente müssen gültig sein)",
    "client_has_to_have_following_documents": "Kunde muss folgende Dokumente haben (Dokumente müssen gültig sein)",
    "count_days": "{count, plural, one {# Tag} other {# Tage}}",
    "longer": "Länger",
    "date_from": "Datum von",
    "time_from": "Zeit von",
    "date_to": "Datum bis",
    "time_to": "Zeit bis",
    "rate": "Rate",
    "car_rental_weekday": "Vermietung - Wochentag Rate",
    "car_rental_weekend": "Vermietung - Weekend Rate",
    "car_rental_week": "Vermietung - Wochen Rate",
    "car_rental_month": "Vermietung - Monat Rate",
    "document_overdue_please_review": "Dokument überfällig. Bitte überprüfen",
    "count_cars": "{count, plural, one {# Fahrzeug} other {# Fahrzeuge}}",
    "count_products": "{count, plural, one {# Produkt} other {# Produkte}}",
    "we_found_count_cars": "Wir haben {count, plural, one {# Fahrzeug} other {# Fahrzeuge}} gefunden",
    "we_found_count_products": "Wir haben {count, plural, one {# Produkt} other {# Produkte}} gefunden",
    "delete_all_filters": "Alle Filter löschen",
    "image_recognition_tags": "Tags aus dem Bild erkannt",
    "username": "Benutzername",
    "handover_must_be_performed": "Das Fahrzeugübergabeprotokoll muss ausgefüllt sein",
    "document_pages": "Dokumentseiten",
    "pages": "Seiten",
    "handover": "Übergabe",
    "mileage": "Laufleistung",
    "client_documents": "Kundendokumente",
    "lead_documents": "Lead-Dokumente",
    "fuel_level": "Kraftstoffstand",
    "start_editing": "Bearbeitung starten",
    "design": "Design",
    "responsive": "Responsive",
    "back": "Zurück",
    "use_text_description": "Textbeschreibung verwenden",
    "use_visual_builder": "Visuellen Builder verwenden",
    "set_image": "Bild einstellen",
    "select_image": "Bild auswählen",
    "uploaded_by_me": "Von mir hochgeladen",
    "current_item": "Aktueller Artikel",
    "current_page": "Aktuelle Seite",
    "difficulties": "Schwierigkeiten",
    "damages": "Schäden",
    "damage_note": "Schadenshinweis",
    "no_damages_in_protocol": "Keine Schäden im Protokoll",
    "add_damage": "Schaden hinzufügen",
    "my_company": "Meine Firma",
    "user_administration": "Benutzerverwaltung",
    "client_administration": "Kontaktverwaltung",
    "crm_administraion": "CRM-Verwaltung",
    "content_administration": "Inhaltsverwaltung",
    "product_administration": "Produktverwaltung",
    "producttemplate_administration": "Templateverwaltung",
    "car_service_administration": "Werkstattverwaltung",
    "site_administration": "Website-Administration",
    "data_push": "Daten-Push",
    "client_has_no_ancestors": "Kunde hat keine Vorfahren",
    "domain": "Domäne",
    "entity": "Entität",
    "event": "Ereignis",
    "endpoint_url": "Endpunkt-URL",
    "authentication": "Authentifizierung",
    "headers": "Kopfzeilen",
    "none": "Kein",
    "social_media": "Sozialen Medien",
    "style": "Stil",
    "html_header": "HTML-Kopf",
    "custom_html_header": "Benutzerdefinierter HTML-Kopf",
    "css_file_name": "Benutzerdefinierter CSS-Dateiname",
    "custom_css": "Benutzerdefinierte CSS",
    "custom_less": "Benutzerdefinierte LESS",
    "custom_font_folder": "Schriftarten Verzeichnis auswählen",
    "custom_font": "Upload benutzerdefinierte Schriftart",
    "custom_css_mail": "Benutzerdefiniertes CSS für E-Mails",
    "main_menu": "Hauptmenü",
    "menu_css_class": "Hauptmenü CSS-Klasse",
    "menu_css_class_scroll": "Hauptmenü CSS-Klasse beim Scrollen",
    "white_label_projects": "White-Label Projekte",
    "imprint": "Impressum",
    "terms_of_service": "AGB",
    "rental_terms_of_service": "Allgemeine Mietbedingungen",
    "car_sales_terms_of_service": "Allgemeine Verkaufbedingungen Gebrauchtfahrzeuge",
    "item_sales_terms_of_service": "Allgemeine Verkaufbedingungen Produkte",
    "privacy_policy": "Datenschutz",
    "cancellation_policy": "Widerrufsbelehrung",
    "footer": "Fußzeile",
    "hide_footer": "Fußzeile ausblenden",
    "unknown_property": "Unbekanntes parameter",
    "unknown": "unbekannt",
    "price_from": "ab",
    "price_to": "bis",
    "slider_from": "ab",
    "slider_to": "bis",
    "within": "innerhalb von",
    "of": "von",
    "within_count_km_of_place": "innerhalb von {count}km von {place}",
    "click_to_start_geolocation": "Hier klicken, um die Geolokalisierung zu starten",
    "geolocation_error": "Wir hatten ein Problem, Ihre Position zu erkennen, oder Ihre Position ist nicht bekannt. Bitte geben Sie Ihre Position manuell ein.",
    "count_km_from": "km von",
    "car_power": "Leistung",
    "car_power_unit": "PS",
    "car_gear": "Getriebe",
    "car_capacity": "Hubraum",
    "car_capacity_unit": "cm³",
    "car_fueltype": "Kraftstoffart",
    "car_type": "Fahrzeugtyp",
    "car_num_previous_owners": "Anzahl der Fahrzeughalter",
    "car_num_doors": "Anzahl der Türen",
    "car_num_seats": "Anzahl Sitzplätze",
    "car_emission_class": "Schadstoffklasse",
    "car_condition": "Fahrzeugzustand",
    "car_construction_year": "Baujahr",
    "car_color_manufacturer": "Farbe (Hersteller)",
    "car_parking_assistance": "Einparkhilfe",
    "car_interior_material": "Material der Innenaustattung",
    "car_airbags": "Airbags",
    "car_air_conditioning": "Klimatisierung",
    "car_environment_badge": "Umweltplakette",
    "car_co2_emissions": "CO2-Emissionen",
    "car_co2_emissions_unit": "g/km",
    "car_fuel_consumption": "Verbrauch",
    "car_fuel_consumption_unit": "l/100km",
    "combined": "kombiniert",
    "combined_short": "komb.",
    "urban": "innerorts",
    "extra_urban": "außerorts",
    "energy_efficiency_class": "Energieeffizienzklasse",
    "technical_data": "Technische Daten",
    "first_registration": "Erstzulassung",
    "proceed_to_checkout": "Zur Kasse",
    "home": "Startseite",
    "checkout": "Kasse",
    "your_cart": "Dein Einkaufswagen",
    "your_car": "Ihr Auto",
    "your_data": "Ihre Daten",
    "services": "Service",
    "shipping_information": "Verbringung",
    "payment_details": "Zahlung",
    "summary": "Zusammenfassung",
    "find_out_more": "Mehr erfahren",
    "qty": "Menge",
    "checkout_next": "Weiter",
    "checkout_previous": "Zurück",
    "please_select": "Bitte auswählen",
    "enum_many_glue": ", ",
    "enum_last_glue": " und ",
    "enum_end": " auswählen",
    "show_details": "Details zeigen",
    "add_to_cart": "In den Warenkorb legen",
    "show_cart": "Warenkorb anzeigen",
    "cart": "Warenkorb",
    "missingQuantity": {
      "cart": "warenkorb"
    },
    "cart_is_empty": "Warenkorb ist leer",
    "item_already_in_cart": "Dieser Artikel befindet sich bereits in Ihrem Warenkorb",
    "remove": "Löschen",
    "removed_from_cart": "wurde aus Warenkorb gelöscht",
    "continue_with_login": "Mit Login fortfahren",
    "dont_have_an_account": "Noch kein Konto",
    "continue_without_login": "Ohne Login fortfahren",
    "already_have_an_account_enter_username_and_password": "Sie haben bereits ein Konto? Bitte geben Sie Ihren Benutzernamen und Ihr Passwort in das unten stehende Formular ein.",
    "continue_without_login_and_register": "Sie können den Kaufvorgang fortsetzen und Ihr Konto hier erstellen.",
    "continue_booking_without_login_and_register": "Sie können den Buchungsvorgang fortsetzen und Ihr Konto hier erstellen",
    "online_discount": "Online-Rabatt",
    "your_personal_data": "Ihre persönlichen Daten",
    "login_data": "Zugangsdaten",
    "shipping": "Versand",
    "payment_cash": "Bargeld",
    "payment_creditCard": "Kreditkarte",
    "payment_payPal": "PayPal",
    "payment_wire_transfer": "Banküberweisung",
    "payment_bill": "Rechnung",
    "choose_different_payment_method": "Eine andere Zahlungsmethode auswählen",
    "i_will_pick_up_my_car": "Ich werde mein Auto abholen",
    "i_will_pick_up_my_order": "Ich werde meine Bestellung abholen",
    "i_want_my_car_delivered": "Ich möchte mein Fahrzeug geliefert haben.",
    "i_want_my_order_delivered": "Ich möchte, dass meine Bestellung geliefert wird",
    "we_could_not_calculate_route_to_address": "Wir konnten keine Route zu der angegebenen Adresse berechnen. Bitte überprüfen Sie es und versuchen Sie es erneut.",
    "credit_card_holder": "Kreditkarteninhaber",
    "credit_card_number": "Kreditkartennummer",
    "car_registration": "Fahrzeugzulassung",
    "car_insurance": "Autoversicherung",
    "service_provided_in_country_by_company": "Service in {country} von {company} zur Verfügung gestellt",
    "i_wish_to_have_car_registered": "Ja, ich möchte den Zulassungsservice nutzen",
    "i_wish_to_get_car_insurance": "Ich möchte eine Autoversicherung abschließen",
    "today": "Heute",
    "in_10_days": "In 10 Tage",
    "your_money_is_safe_with_us": "Dein Geld ist bei uns sicher",
    "car_transport": "Autotransport",
    "delivery": "Lieferung",
    "decide_car_delivery_or_pickup": "Es liegt Ihnen zu entscheiden, ob Sie Ihr Auto selbst abholen oder Ihr Auto liefern lassen wollen. So oder so, das Auto wird in perfekter Form sein, sauber und vollgetankt.",
    "secured_payment_until_car_is_delivered": "Sicher Zahlvorgang bis zur Zustellung des Fahrzeugs",
    "e_payment_to_reserve_car_and_secure_services": "E-Zahlung, um das Auto zu reservieren und ausgewählte Dienste zu sichern",
    "e_payment_credit_card_paypal": "Elektronische Zahlung (Kreditkarte oder PayPal)",
    "multiple_secure_options_to_pay_for_the_car": "Sichere Zahlungsmöglichkeiten für das Auto (Überweisung, Bar bei Abholung)",
    "buy_car_by_clicking_button": "Sie können dieses Auto jetzt kaufen, indem Sie auf die Schaltfläche unten klicken",
    "payment_with_credit_card_ending_last4_expiring_exp_month_exp_year_issued_to_card_holder": "Zahlung mit Kreditkarte, die mit {last4} endet und in {expMonth}/{expYear} ausläuft, ausgestellt für {cardHolder}",
    "click_on_paypal_checkout_to_continue": "Bitte klicken Sie auf den Paypal Checkout-Button, um fortzufahren.",
    "paypal_is_approved_continue_to_summary": "Ihre PayPal-Zahlung wurde genehmigt. Bitte fahren Sie mit der Zusammenfassung fort, um die Bestellung abzuschließen und die Zahlung zu autorisieren.",
    "paypal_buttons_loading": "PayPal-Schaltflächen laden...",
    "please_wait_saving_order": "Speichern der Bestellung. Bitte warten...",
    "error_loading_paypal_scripts": "PayPal-Skripte konnten nicht geladen werden. Bitte schließen Sie dieses Popup und öffnen Sie es erneut.",
    "link": "Link",
    "hide_on_devices": "Auf Geräten ausblenden",
    "mobile_phones": "Mobiltelefone",
    "tablets": "Tablets",
    "desktop_pcs": "Desktop-PCs",
    "show_content_on_mouse_over": "Inhalt anzeigen, wenn die Maus über ist",
    "menu_content": "Menü Inhalt",
    "link_to_page": "Link zur Seite",
    "page_has_no_translations": "Diese Seite hat keine Übersetzungen",
    "search_results": "Suchergebnisse",
    "press_enter_to_search": "Drücken Sie die Eingabetaste um zu suchen",
    "no_results_found": "Keine Ergebnisse gefunden",
    "create_new_entry_now": "Sie können jetzt einen neuen Eintrag erstellen:",
    "no_results_for_your_search": "Wir haben keine Ergebnisse für Ihre Suchanfrage gefunden",
    "change_image": "Bild ändern",
    "employee_data": "Mitarbeiterdaten",
    "newsletter": "Erlaubnis Newsletter zu empfangen",
    "editorAutosave": "Automatische Speicherung im Editor",
    "is_profile_public": "Ist das Profil öffentlich?",
    "parent_page": "Parent-Seite",
    "cms_elements": "CMS Elemente",
    "cms_element": "CMS Element",
    "new_lead_from_website": "Neuer Lead von der Website",
    "website_settings": "Website-Einstellungen",
    "profile": "Profil",
    "my_bills": "Meine Rechnungen",
    "integrations": "Integrationen",
    "email_templates": "E-mail Vorlagen",
    "cms_templates": "CMS Vorlagen",
    "cms_template": "CMS Vorlage",
    "select_brand": "Marke auswählen",
    "all_brands_visible": "Alle Marken sind sichtbar",
    "contact_us": "Kontaktiere uns",
    "thank_you": "Vielen Dank",
    "thank_you_for_contacting_us": "Danke, dass Sie uns kontaktiert haben. Wir haben Ihre Anfrage erhalten und melden uns so schnell wie möglich bei Ihnen",
    "cms_standard": "Standard",
    "select_element": "CMS Element auswählen",
    "divider": "Teiler",
    "testimonial": "Empfehlung",
    "hero_unit": "Hero-Element",
    "image_carousel": "Slider",
    "call_to_action": "Handlugsaufruf",
    "bar_counter": "Balkenzähler",
    "products": "Produkte",
    "producttemplates": "Templates",
    "single_product": "Einzelnes Produkt",
    "single_producttemplate": "Einzelnes Template",
    "lead_form": "Lead-Formular",
    "image_carousel_element": "Slider Bild",
    "cms_grid": "Container",
    "cms_row": "Zeile",
    "cms_col": "Spalte",
    "text_color": "Textfarbe",
    "bar_counter_now": "Jetzt",
    "shadow": "Schatten",
    "no_shadow": "Kein Schatten",
    "small": "Klein",
    "big": "Groß",
    "rounded_corners": "Abgerundete Ecken",
    "no_rounded_corners": "Keine abgerundeten Ecken",
    "image_position": "Bildposition",
    "icon": "Ikon",
    "icon_class": "Ikon-Klasse (CSS)",
    "position_top": "Oben",
    "position_left": "Links",
    "bar_color": "Balkenfarbe",
    "bar_background_color": "Balken-Hintergrundfarbe",
    "button_icon": "Button-Symbol",
    "button_text": "Button-Text",
    "full_height": "Volle Höhe",
    "padding_top": "Abstand oben",
    "padding_bottom": "Abstand unten",
    "lead_source": "Lead-Quelle",
    "change_item": "Artikel ändern",
    "show_infos_on_top": "Zeige Infos oben",
    "show_infos_on_bottom": "Zeige Infos unten",
    "condensed": "Kondensiert",
    "assign_item": "Produkt zuweisen",
    "image_animation": "Bildanimation",
    "no_animation": "Keine Animation",
    "fade_in": "Einblenden",
    "from_left": "Von links",
    "from_right": "Von rechts",
    "from_top": "Von oben",
    "from_bottom": "Von unten",
    "image_gallery": "Bildergalerie",
    "elements": "Elemente",
    "accordion": "Akkordeon",
    "map": "Karte",
    "height": "Höhe",
    "video": "Video",
    "link_to_video": "Link zum Video",
    "video_possible_sources": "Videos from the following portals are supported:: YouTube, Vimeo, Dailymotion",
    "autoplay": "Automatisches Abspielen",
    "aspect_ratio": "Seitenverhältnis",
    "image_compare": "Bild vergleichen",
    "second_image": "Zweites Bild",
    "login_form": "Login Formular",
    "button_signin": "Einloggen",
    "alternative_login": "...oder loge sich mit:",
    "alternative_login_long": "Nutzen Sie unsere Social-Login-Funktion",
    "login_with": "Anmelden mit",
    "show_facebook_login": "Facebook-Login anzeigen",
    "show_google_login": "Google-Login anzeigen",
    "signup_form": "Anmeldeformular",
    "signup_legal_explanation": "Anmeldung - rechtliche Erklärung",
    "signup_agreement_text": "Mit der Registrierung erkläre ich mich mit den {terms} einverstanden.",
    "contact_agreement_text": "Ich habe die {data_use} gelesen und verstanden.",
    "signup_agreement_text_terms": "Allgemeinen Geschäftsbedingungen",
    "signup_agreement_text_data_use": "Datenschutzerklärung",
    "signup_agreement_text_cancellation_policy_and_form": "Widerrufsbelehrung sowie das Widerrufsformular",
    "will_become_link_with_title_title": "wird ein Link mit dem Titel: {title}",
    "show_only_when_user_logged_id": "Nur anzeigen, wenn der Benutzer angemeldet ist",
    "car_rental_form": "Autovermietung Formular",
    "car_search_form": "Autosuch-Formular",
    "car_search_funnel": "Autosuch-Konfigurator",
    "background_image": "Hintergrundbild",
    "background_color": "Hintergrundfarbe",
    "same_col_height": "Gleiche Spaltenhöhe",
    "pick_up_and_drop_off": "Abholung & Rückgabe",
    "pick_up_time": "Abholzeit",
    "drop_off_time": "Rückgabezeit",
    "get_a_quote": "Preise ermitteln",
    "select_location": "Bitte wählen Sie einen Standort",
    "type_address_to_start_search": "Bitte geben Sie eine Adresse ein, um die Suche zu starten",
    "menu_version": "Menüversion",
    "menu_transparent": "Transparent",
    "menu_dark": "Dunkel",
    "menu_light": "Hell",
    "logo_version": "Logo-Version",
    "logo_default": "Standardlogo verwenden",
    "logo_scroll": "Scroll-Logo verwenden",
    "select_a_pick_up_location": "Bitte wählen Sie zuerst einen Abholort",
    "could_not_find_location_please_enter_more_details": "Wir konnten keinen Standort finden. Bitte geben Sie Ihren Abholort mit weiteren Details an",
    "client_not_found": "Kunde nicht gefunden",
    "order_will_directly_be_marked_paid": "Die Bestellung wird direkt als bezahlt markiert",
    "invoice_will_directly_be_marked_paid": "Die Rechnung wird direkt als bezahlt markiert",
    "rental_period": "Mietdauer",
    "change_booking_dates": "Buchungsdaten ändern",
    "per_day": "pro Tag",
    "buy_now": "Jetzt kaufen",
    "book_now": "Jetzt buchen",
    "rent_car_now": "Jetzt mieten",
    "commit_to_buy": "Zahlungspflichtig bestellen",
    "back_to_cart": "Zurück zum Warenkorb",
    "equipment": "Austattung",
    "technical_specifications": "Technische Daten",
    "cancel_checkout_question": "Checkout abbrechen?",
    "how_it_works": "So funktioniert es",
    "secure_payment": "Sichere Zahlung",
    "top_services": "Top Dienstleistungen",
    "if_you_buy_this_car_you_will_get_lots_of_services": "Wenn Sie dieses Auto kaufen, haben Sie keine Angst vor Papierkram. Wir können die Registrierung, Versicherung und Autokredit organisieren, wenn Sie es wünschen. Wählen Sie einfach den gewünschten Service beim Checkout.",
    "need_more_info_question": "Benötigen Sie weitere Informationen?",
    "contact_dealer": "Händler kontaktieren",
    "move_around_to_see_changes": "Verschieben, um die Änderungen zu sehen",
    "lead_catchers": "Lead catchers",
    "lead_catcher": "Lead catcher",
    "advanced_routing": "Erweitertes Routing",
    "add_route": "Route hinzufügen",
    "source": "Quelle",
    "can_be_ordered_online": "Online bestellbar",
    "count_years_warranty": "{count} {count, plural, one {Jahr} other {Jahre}} Garantie",
    "amount_online_advantage": "{amount} Onlinevorteil",
    "your_order": "Ihre Bestellung",
    "file_name": "Dateiname",
    "tags": "Tags",
    "save_and_set_image": "Speichern und einstellen",
    "choose_or_drop_file_to_upload": "Wählen oder ziehen Sie eine Datei über die Seite zum Hochladen.",
    "media_formats_supported": "JPG und PNG unterstützt.",
    "max_file_upload_size": "Die maximale Dateigröße beträgt 15 MB",
    "no_files_found": "Keine Dateien gefunden.",
    "upload_file_by_dragging_over": "Laden Sie eine Datei hoch, indem Sie einfach eine Datei über die Seite ziehen",
    "your_car_is_ready_to_check_out": "Ihr Wunschauto steht für Sie bereit",
    "choosing_one_of_our_cars_is_a_good_decision": "Nur noch wenige Schritte bis zu Ihrem Wunschfahrzeug. Profitieren Sie von unseren Online-Vorteilen.",
    "your_car_will_be_delivered_to_you": "Ihr Fahrzeug wird an Ihren Wunschort geliefert",
    "your_will_get_a_amount_discount_for_ordering_online": "Bei Online-Bestellungen erhalten Sie einen Rabatt von {amount}",
    "you_will_receive_extended_warranty": "Sie erhalten eine erweiterte Garantie",
    "you_will_get_exclusive_offers_when_using_our_app": "Sie erhalten exklusive Angebote bei der Nutzung unserer mobilen App",
    "you_dont_risk_when_ordering_online": "Sie riskieren nicht viel, wenn Sie online bestellen.",
    "your_car_rent_is_nearly_done": "Ihre Autovermietung ist fast fertig!",
    "please_continue_with_booking_and_payment": "Bitte fahren Sie mit der Buchung und Bezahlung fort. Wir stellen sicher, dass Ihr Auto am ausgewählten Abholtermin bereit ist. Online-Reservierung spart Ihnen Zeit!",
    "please_provide_address_delivery_method_payment": "Bitte geben Sie Ihre Adresse an, wählen Sie die Versandart und die Zahlungsdetails.",
    "your_information_is_safe_with_us": "Ihre Informationen sind bei uns sicher.",
    "we_offer_parcel_shipment_for_just_amount": "Für Ihre Bequemlichkeit bieten wir Ihnen eine Paketsendung für alle unsere Bestellung für nur {amount}.",
    "free_delivery_on_all_our_orders": "Wir bieten Ihnen kostenlose Lieferung für alle unsere Bestellungen.",
    "get_your_car_delivered": "Lieferung",
    "cant_pick_your_car_yourself_we_got_you_covered": "Was passiert, wenn Sie Ihr Traumauto finden und es nicht selbst abholen können? Keine Sorgen. Wir haben eine Lösung. Nachdem Ihr Auto registriert und versichert ist, wird ein Fahrer einen Liefertermin mit Ihnen vereinbaren und Ihr Auto in Ihrer Einfahrt parken.",
    "we_are_more_than_happy_to_show_you_around_your_new_car": "Natürlich freuen wir uns, Sie persönlich begrüßen zu dürfen und Ihnen Ihr neues Auto zu zeigen.",
    "we_can_complete_your_order_in_shop_for_pickup": "Aber, wenn Sie es wünschen, können wir Ihre Bestellung in unserem Shop vervollständigen und Sie können es abholen, wenn Sie Zeit haben.",
    "we_will_inform_you_when_we_complete_order_for_pickup": "Wir werden Sie informieren, wenn wir Ihre Bestellung vervollständigen. Dann werden wir geduldig warten, bis Sie es abholen.",
    "we_will_ship_your_order_to_provided_address": "Wir versenden Ihre Bestellung mit DHL an die von Ihnen angegebene Adresse. Wir werden Sie informieren, wenn die Bestellung versandt wird. Sie erhalten außerdem eine Bestellverfolgungsnummer.",
    "we_calculated_route_from_car_to_you": "Wir haben eine Route von wo sich das Auto befindet zu der von Ihnen angegebenen Adresse berechnet. Es sieht so aus, als ob unser Fahrer {distance}km fahren muss, um das Auto zu Ihrer Haustür zu bringen.",
    "service_includes": "Dienstleistung beinhaltet:",
    "driver_will_call_you_and_schedule_delivery": "Unser Fahrer wird Sie anrufen und das Auto liefern, wenn es Ihnen am besten passt",
    "your_car_will_be_washed": "Dein Auto wird gewaschen",
    "your_car_will_be_fully_tanked": "Ihr Auto wird voll getankt",
    "you_will_get_quick_tour_around_car": "Sie erhalten eine kurze Tour des Autos",
    "you_can_pick_up_car_yourself": "Sie haben noch Fragen? Unser Team steht Ihnen gerne zur Verfügung.",
    "only_one_step_left": "Nur noch ein Schritt!",
    "check_if_everything_is_correct_and_provide_payment_data": "Bitte prüfen Sie, ob alles korrekt ist, geben Sie uns Ihre Zahlungsdaten und übermitteln Sie die Bestellung.",
    "terms_of_contract_for_additional_services": "Vertragsbedingungen für Zusatzleistungen",
    "terms_of_use_marketplace": "Nutzungsbedingungen des Marktplatzes",
    "for_the_sales_of_used_vehicles": "Verkauf von Gebrauchtfahrzeugen",
    "for_the_rental_of_vehicles": "Vermietung von Fahrzeugen",
    "your_payment_schedule": "Zahlungslauf",
    "shipping_pickup": "Abholung",
    "shipping_parcel": "Paket",
    "shipping_transport": "Transport",
    "car_interior": "Innenausstattung",
    "car_exterior": "Außenausstattung",
    "we_are_processing_your_order_please_wait": "Wir bearbeiten Ihre Bestellung. Bitte warten...",
    "we_are_processing_your_booking_please_wait": "Wir bearbeiten Ihre Buchung. Bitte warten...",
    "your_order_was_completed_successfully": "Ihre Bestellung wurde erfolgreich abgeschlossen.",
    "we_created_a_user_profile": "Wir haben ein Benutzerprofil für Sie erstellt",
    "check_your_email": "Überprüfen Sie Ihren Posteingang",
    "your_orders": "{count, plural, one {Ihre Bestellnummer} other {Ihre Bestellnummern}}:",
    "first_experience_with_our_shop_saved_profile": "Es scheint, dass es Ihre erste Erfahrung mit unserem Onlineshop ist. Wir haben Ihre Profilinformationen erfolgreich in unserer Datenbank gespeichert. Um Ihre Bestellung weiter zu bearbeiten, überprüfen Sie bitte Ihre Mailbox und bestätigen Sie Ihre E-Mail-Adresse.",
    "order_details_sent_to_email_check_history_for_details": "Alle Bestelldetails wurden an Ihre E-Mail Adresse gesendet. Die Bestellhistorie sowie den Bestellstatus können Sie jederzeit in Ihrem Benutzerkonto einsehen.",
    "timeline": "Timeline",
    "promotions": "Aktionen",
    "payment_method": "Zahlungsart",
    "first_payment": "1. Zahlung",
    "second_payment": "2. Zahlung",
    "shipping_method": "Versandart",
    "payment_1_e_payment_to_secure_your_order": "Anzahlung über elektronische Zahlung um Ihr Fahrzeug zu reservieren.",
    "payment_2_choose_payment_method_for_the_car": "Wählen Sie eine Zahlungsmethode für Ihr Fahrzeug.",
    "payment_escrow": "Treuhandkonto",
    "payment_car_credit": "Finanzierung",
    "full_refund": "Volle Erstattung",
    "partial_refund": "Teilerstattung",
    "cancel_order": "Bestellung stornieren",
    "create_shipping": "Versand erstellen",
    "schedule_car_registration": "Zulassung",
    "schedule_transport": "Transport planen",
    "is_published": "Ist veröffentlicht",
    "no_salesman_available": "Zur Zeit ist kein Verkäufer verfügbar",
    "any_questions": "Fragen?",
    "feel_free_to_ask_our_online_sales_team": "Kontaktieren Sie einfach unser Online-Verkaufsteam",
    "please_complete_your_details": "Bitte ergänze deine Angaben",
    "registration_is_stressfull_get_your_car_registered": "Für manche Menschen ist ein Auto zuzulassen stressig und zeitaufwänd. Lassen Sie sich Ihr Auto in Ihrem Namen ohne Aufwand registrieren.",
    "review_your_order": "Überprüfen Sie Ihre Bestellung",
    "in_order_to_confirm_identity_please_complete_your_details": "Um Ihre Identität zu bestätigen, benötigen wir einige zusätzliche Informationen:",
    "payment_instructions_will_be_provided_after_seller_accepts_order": "Der Verkäufer wird Ihnen eine detaillierte Zahlungsanweisung geben, nachdem Ihre Bestellung akzeptiert wurde.",
    "nationality": "Staatsangehörigkeit",
    "date_of_birth": "Geburtsdatum",
    "place_of_birth": "Geburtsort",
    "discover_more": "Mehr entdecken",
    "more_of_product": "Mehr {product}",
    "more_of_product_in_city": "Mehr {product} in {city}",
    "order_placed": "Bestellung aufgegeben",
    "show_order_details": "Bestelldetails anzeigen",
    "white_label_plugins": "White-Label Plugins",
    "white_label_plugin": "White-Label Plugin",
    "enhance_chedri_project_with_plugins": "Verbessere Creacheck-Erfahrung mit Plugins",
    "plugin": "Plugin",
    "plugins": "Plugins",
    "enhance_your_project_with_plugins": "Sie können wählen, wie viel von Creacheck-Funktionalität Sie benötigen. Überprüfen Sie verfügbare Plugins, ihre Funktionen und Preise.",
    "week": "Woche",
    "month": "Monat",
    "quarter": "Quartal",
    "year": "Jahr",
    "more_infos": "Mehr Informationen",
    "less_infos": "Weniger Informationen",
    "activate_plugin": "Plugin aktivieren",
    "partially_activate_plugin": "Plugin aktivierung unterschreiben",
    "terminate_plugin": "Plugin beenden",
    "detailed_description": "Detaillierte Beschreibung",
    "installation_instructions": "Installationsanleitung",
    "you_are_not_an_adminitrator": "Sie sind kein Administrator",
    "creditor_id": "Gläubiger-ID",
    "i_understand_the_cost_and_want_activate_plugin": "Ich verstehe die Kosten und möchte das Plugin aktivieren",
    "i_understand_the_running_cost_when_terminating_plugin": "Ich verstehe die Kosten, die am Ende des Abrechnungszeitraums berechnet werden",
    "are_you_sure_you_want_to_terminate_plugin": "Möchtest du das Plugin wirklich beenden?",
    "security_settings": "Sicherheitseinstellungen",
    "limit_write_rights": "Schreibrechte beschränken",
    "limit_read_rights": "Leserechte beschränken",
    "edit_your_company_data": "Sie können Ihre Firmendaten hier bearbeiten. Ändern Sie die Kontakt- und Finanzdaten, die für Abrechnungszwecke verwendet werden",
    "latest_order": "Letzte Bestellung",
    "latest_orders": "Letzte Bestellungen",
    "latest_invoices": "Letzte Rechnungen",
    "latest_editions": "Zuletzt bearbeitet",
    "overdue_invoices": "Fällige Rechnungen",
    "view_all": "Alle anzeigen",
    "i_accept": "Ich akzeptiere",
    "accept_terms_before_continuing": "Bevor Sie fortfahren, müssen Sie unsere AGBs und Datenschutzbestimmungen akzeptieren",
    "approve_car_sale": "Autoverkauf genehmigen",
    "enter_vin_number": "Fahrgestellnummer des Fahrzeugs eingeben",
    "mark_as_paid": "Als bezahlt markieren",
    "enter_ein_number": "Elektronische Versicherungsbestätigung eingeben",
    "send_payment_instructions": "Zahlungsanweisungen senden",
    "submit_vin_number": "Fahrgestellnummer des Fahrzeugs senden",
    "approve_and_inform_client": "Genehmigen und den Kunden informieren",
    "submit_ein_number": "Elektronische Versicherungsbestätigung senden",
    "ein": "Elektronische Versicherungsbestätigung",
    "payment_reference": "Zahlungsreferenz",
    "upload_sales_cotract": "Verkaufsvertrag hochladen",
    "cancellation_reason": "Stornierungsgrund",
    "enter_bank_data": "Bankdaten eingeben",
    "pickup_invite": "Einladung zur Abholung",
    "mark_picked_up": "Als abgeholt markieren",
    "refunds": "Rückerstattungen",
    "sent_on_date": "Gesendet am {date}",
    "not_sent_yet": "Noch nicht versendet",
    "pick_up_location": "Abholort",
    "emails": "E-Mails",
    "use_following_list_to_personalize_your_emails": "Verwende folgende Liste, um E-Mails zu personalisieren:",
    "recipients_first_name": "Vorname des Empfängers",
    "recipients_last_name": "Nachname des Empfängers",
    "senders_company_name": "Firmenname des Absenders",
    "system_mail_registration_description": "Wird gesendet, wenn sich ein neuer Benutzer auf der Webseite registriert",
    "system_mail_invitation_description": "Wird gesendet, wenn ein Mitarbeiter einen neuen Benutzer mit einer E-Mail-Adresse erstellt",
    "system_mail_car_rental_pickup_reminder_description": "Wird gesendet ein Tag bevor der Kunde sein Mietauto abholt",
    "system_mail_car_rental_dropoff_reminder_description": "Wird gesendet ein Tag bevor der Kunde sein Mietauto abgibt",
    "terms_of_use": "Nutzungsbedingungen",
    "contract": "Vertrag",
    "order_data_processing": "Auftragsverarbeitungsvertrag",
    "i_read_and_accept_plugins_terms_of_use": "Ich habe die Nutzungsbedingungen gelesen und verstanden und akzeptiere diese ausdrücklich.",
    "i_read_and_accept_plugins_contract": "Ich habe den Vertrag gelesen und verstanden und akzeptiere ihn ausdrücklich.",
    "i_read_and_accept_plugins_order_data_processing": "Ich habe die Auftragsdatenverarbeitung gelesen und verstanden und akzeptiere sie ausdrücklich.",
    "send_activation_request": "Aktivierungsanfrage senden",
    "missing_companys_bank_data": "Scheint, dass wir die Bankdaten Ihres Unternehmens vermissen.",
    "you_can_fill_missing_data_in_settings": "Sie können fehlende Daten in {settings} eingeben.",
    "not_sole_representative_click_below_to_request_plugin_activation": "Sie sind nicht berechtigt, Plugins selbst zu aktivieren. Klicken Sie auf den Button um das Plugin zu aktivieren. Der autorisierte Benutzer wird aufgefordert, den Aktivierungsprozess zu beenden.",
    "not_authorized_click_below_to_request_plugin_activation": "Sie sind nicht berechtigt Plugins zu aktivieren. Klicken Sie auf die Schaltfläche, um eine Aktivierungsanfrage an autorisierte Benutzer zu senden.",
    "select": "Wählen",
    "enter_app_secret": "App secret eintragen",
    "enter_page_access_token": "Page Access Token eintragen",
    "legal": "Rechtlich",
    "activation_pending": "Aktivierung ausstehend",
    "termination_pending": "Kündigung ausstehend",
    "form_contains_errors": "Es gibt einige Probleme in dem Formular",
    "internal_car_reference": "Interne Auto Referenz",
    "system_debtor_number": "System-Debitorennummer",
    "internal_company_reference": "Interne Firmenreferenz",
    "internal_location_reference": "Interne Standortreferenz",
    "show_notes": "Notizen zeigen",
    "show_my_notes": "Zeige meine Notizen",
    "show_by_me": "Zeige von mir",
    "items": "Produkte",
    "send_text_message": "SMS senden",
    "wrote_message": "Nachricht geschrieben",
    "sent_text_message": "Eine SMS gesendet",
    "wrote_note": "Notiz geschrieben",
    "please_check_document_check_expiration_date": "Bitte überprüfen Sie das Dokument. Stellen Sie sicher, dass Sie das Dokument gesehen haben. Überprüfen Sie das Ablaufdatum",
    "i_reviewed_the_document": "Ich habe das Dokument überprüft",
    "receive_updates_in_messenger": "Möchten Sie Updates in Messenger erhalten?",
    "weve_received_your_request_and_will_contact_you": "Wir haben Ihre Anfrage erhalten und werden uns schnellstmöglich mit Ihnen in Verbindung setzen",
    "check_your_email_to_confirm": "Bitte überprüfen Sie Ihre E-Mail, um den Versand dieses Kontaktformulars zu bestätigen.",
    "response_text": "Antworttext",
    "entries": "Einträge",
    "timeline_block": "Timeline-Block",
    "external_accounts": "Externe Konten",
    "send_copy_to_me": "Sende eine Kopie an mich",
    "user_tracking": "Nutzer-Tracking",
    "select_model": "Modell auswählen",
    "select_model_group": "Modellgruppe auswählen",
    "equipment_level_budget": "Budget",
    "equipment_level_family": "Family",
    "equipment_level_comfort": "Comfort",
    "equipment_level_business": "Business",
    "salutation": "Anrede",
    "getting_started": "Erste Schritte",
    "set_your_locations": "Legen Sie Ihre Standorte fest",
    "set_up_locations_for_your_car_business_set_services_opening_hours_upload_photos": "Jedes Fahrzeug benötigt einen Standort, aus dem die Kunden wählen können. Gehen Sie zu den Einstellungen, in denen Sie alle gewünschten Standorte festlegen können. Dort können Sie entscheiden, welche Services Sie an jedem Standort anbieten, Öffnungszeiten festlegen und Fotos hochladen, damit die Kunden Ihr Geschäft besser finden können.",
    "now_that_locations_are_set_create_users_so_they_can_work_together": "Nun, da Ihre Kunden wissen, wo Sie Ihr Geschäft betreiben, lassen Sie uns wissen, wer für Sie arbeitet. Bitte legen Sie Benutzer an, damit sie mithilfe von Creacheck Technologien zusammenarbeiten können",
    "cms_content": "CMS Inhalt",
    "this_is_blank_page_use_editor_to_create_great_one": "Dies ist eine leere Seite. In unserem visuellen Editor können Sie eine schöne Seite mit Grafiken, schöner Typografie und kompatibel mit allen Geräten erstellen.",
    "cars_to_sell": "Fahrzeuge zu verkaufen",
    "your_business_is_car_sales_but_there_are_no_cars_create_yourself_or_use_import": "Ihr Geschäft besteht darin, Fahrzeuge zu verkaufen. Unseres, es Ihnen leichter zu machen. Bisher haben Sie noch keine Fahrzeuge in unser System gestellt. Verwenden Sie dafür einfach bequem den Fahrzeugimport oder geben Sie Ihre Fahrzeuge manuell ein.",
    "create_manually": "Manuell erstellen",
    "import": "Import",
    "car_import": "Fahrzeuge importieren",
    "most_car_dealers_use_mobilede_enter_your_interface_username_and_password_to_import": "Wie wir herausgefunden haben, verwenden die meisten Autohändler mobile.de, um ihre Autos zu präsentieren. Sie haben also höchstwahrscheinlich Zugriff auf den Webservice. Bitte gib deinen Benutzernamen und dein Passwort ein. Wir importieren alle Ihre Autos automatisch und halten Ihr Angebot synchron. Wenn Sie über mehrere Zugangsdaten verfügen, können Sie weitere in Ihr Profil eingeben.",
    "we_will_import_count_cars_for_this_username": "Wir werden {count} Autos für diesen Benutzernamen importieren",
    "mobile_de_username": "Mobile.de Seller-API Benutzername",
    "mobile_de_password": "Mobile.de Seller-API Passwort",
    "missing_bank_data": "Fehlende Bankdaten",
    "we_dont_have_your_bank_data": "Anscheinend haben wir Ihre Bankdaten nicht",
    "monthly_billing_relies_on_sepa_direct_debit_fill_in_bank_data_in_settings": "Unsere monatliche Abrechnung basiert auf der SEPA-Lastschrift. Da wir keine Bankdaten haben, können Sie viele unserer Plugins nicht verwenden. Bitte gehen Sie zu den Einstellungen und geben Sie Ihre Bankdaten ein.",
    "fill_in_bank_data": "Geben Sie die Bankdaten ein",
    "sepa_mandate": "SEPA-Mandat",
    "sepa_mandates": "SEPA-Mandate",
    "missing_sepa_mandate": "Uns fehlt ein SEPA-Mandat",
    "changed_bank_data_please_generate_new_sepa_mandate": "Scheint, Sie haben kürzlich Ihre Bankkontonummer geändert. Um unseren monatlichen Abrechnungsprozess zu automatisieren und weniger Stress für Sie zu erzeugen, erstellen Sie bitte ein neues SEPA-Mandat.",
    "generate_sepa_mandate_now": "Generieren Sie ein neues SEPA-Mandat",
    "columns": "Spalten",
    "send_doc_as_message": "Dokument an den Kunden senden (E-Mail)",
    "alternative_recepient": "Alternativ-Empfänger",
    "enter_email_address": "Bitte geben Sie eine gültige E-Mail Adresse ein",
    "job_title": "Jobtitel",
    "service_point": "Service",
    "rental_point": "Mietstützpunkt",
    "seller_data": "Verkäuferdaten",
    "car_location": "Fahrzeugstandort",
    "save_data_before_nav_to_pathname": "Ihre Daten werden nicht gespeichert. Möchten Sie ohne Speichern zu {pathname} wechseln?",
    "share": "Teilen",
    "assignee_changed_from_assignee_to_assignee": "Der Bearbeiter wurde von %oldAssignee% in %newAssignee% geändert",
    "stage_changed_from_name_to_name": "Die Stufe wurde von %oldName% in %newName% geändert",
    "handover_filled_out": "Fahrzeugübergabeprotokoll wurde ausgefüllt",
    "data_changed": "Daten aktualisiert",
    "key": "Schlüssel",
    "old_value": "Alter Wert",
    "new_value": "Neuer Wert",
    "no_details_available": "Detaillierte Informationen sind nicht verfügbar",
    "services_we_offer_in_city": "Dienstleistungen, die wir für Sie in {city} anbieten",
    "damages_present": "Schäden vorhanden",
    "user_is_online": "Benutzer ist online",
    "user_is_offline": "Benutzer ist offline",
    "click_to_start_chat": "Klicken Sie hier, um den Chat zu starten",
    "click_to_view_chat_history": "Klicken Sie hier, um den Chat-Verlauf anzuzeigen",
    "theme_colors": "Themefarben",
    "logos": "Logos",
    "send_invitation_with_username_and_password": "Einladung mit Benutzername und Passwort senden (Nur bei Neuanlage)",
    "cookie_settings": "Cookie-Details",
    "accept_only_necessary_cookies": "Nur notwendige Cookies akzeptieren",
    "accept_all_cookies": "Alle Cookies akzeptieren",
    "privacy_preference_center": "Datenschutzpräferenzzentrum",
    "your_privacy": "Ihre Privatsphäre",
    "basic_information_on_cookies": "Wenn Sie eine Website besuchen, werden möglicherweise Informationen in Ihrem Browser gespeichert oder abgerufen, meist in Form von Cookies. Bei diesen Informationen handelt es sich möglicherweise um Sie, Ihre Vorlieben oder Ihr Gerät. Diese Informationen werden hauptsächlich dazu verwendet, die Website wie erwartet zu funktionieren. Die Informationen identifizieren Sie normalerweise nicht direkt, aber können ein personalisierteres Web-Erlebnis bieten. Da wir Ihr Recht auf Privatsphäre respektieren, können Sie bestimmte Arten von Cookies nicht zulassen. Wählen Sie eine Kategorie aus, um mehr zu erfahren und unsere Standardeinstellungen zu ändern. Das Blockieren einiger Arten von Cookies kann sich jedoch auf Ihre Erfahrung mit der Website und den von uns angebotenen Diensten auswirken.",
    "necessary_cookies": "Notwendige Cookies",
    "performance_cookies": "Performance Cookies",
    "targeting_cookies": "Targeting Cookies",
    "always_active": "Immer aktiv",
    "cookies_used": "Verwendete Cookies",
    "necessary_cookies_information": "Diese Cookies sind für die Funktion der Website erforderlich und können in unseren Systemen nicht deaktiviert werden. Sie werden in der Regel nur als Reaktion auf von Ihnen ausgeführte Aktionen festgelegt, die sich auf eine Serviceanfrage beziehen, z. B. das Festlegen Ihrer Datenschutzeinstellungen, das Anmelden oder das Ausfüllen von Formularen. Sie können Ihren Browser so einstellen, dass er diese Cookies blockiert oder Sie über diese Cookies informiert, jedoch einige Teile der Website werden nicht funktionieren. Diese Cookies speichern keine persönlich identifizierbaren Informationen.",
    "save_settings": "Einstellungen speichern",
    "save_and_continue": "Speichern und weiter",
    "priority": "Priorität",
    "closed_leads": "Geschlossene Leads",
    "show": "Anzeigen",
    "hide": "Verstecken",
    "first_registration_from": "Erstzulassung ab",
    "first_registration_to": "Erstzulassung bis",
    "power_from": "Motorleistung ab",
    "power_to": "Motorleistung bis",
    "mileage_from": "Kilometerstand ab",
    "mileage_to": "Kilometerstand bis",
    "included_mileage": "{mileage} Kilometer",
    "really_cancel": "Wirklich abbrechen?",
    "unsaved_information_will_be_lost": "Nicht gespeicherte Daten gehen verloren",
    "client_signature": "Kundenunterschrift",
    "another_photo": "Weiteres Foto",
    "try_again": "Erneut versuchen",
    "back_to_vehicle": "Zurück zum Fahrzeug",
    "image_upload_finished": "Das Hochladen der Bilder ist abgeschlossen",
    "you_have_no_internet_connection": "Sie haben keine Internetverbindung",
    "waiting_to_reconnect": "Warten auf Wiederverbindung",
    "plus_count_more": "+ {count} mehr",
    "document_is_missing": "Ein Dokument fehlt",
    "upload_document_name_now": "Dokument '{name}' jetzt hochladen",
    "lead_per_mail": "Lead per E-Mail Regel",
    "create_rules_to_forward_emails": "Erstellen Sie Regeln, um E-Mails als Kopie an andere Benutzer weiterzuleiten. Wenn das 'Data e-mail plugin' aktiviert ist, können Sie außerdem E-Mails mit wichtigen Informationen im XML-Format direkt an Customer One weiterleiten.",
    "lead_per_mail_rules": "Lead per E-Mail Regeln",
    "include_xml_data_in_email": "Daten im XML-Format in die E-Mail einfügen",
    "sad_activity": "SAD Aktivität",
    "e_payments": "E-payments",
    "stripe_connect": "Stripe Connect",
    "we_will_handle_epayments_for_you": "Mit Stripe Connect können wir Kreditkartenzahlungen in Ihrem Namen abwickeln. Nachdem Sie auf \"Verbinden mit Stripe\" geklickt haben, können Sie Ihr bestehendes Stripe-Konto verwenden, um eine Verbindung zur Creacheck-Plattform herzustellen, oder ein neues Stripe-Konto erstellen, wenn Sie noch kein Konto haben. Mit Ihrem eigenen Stripe-Konto sehen Sie Ihren gesamten Zahlungsverlauf und können jederzeit Geld abheben.",
    "connect_with_stripe": "Mit Stripe verbinden",
    "stripe_accounts": "Stripe Konten",
    "stripe_account_connected": "Ihr Stripe-Konto ist jetzt verbunden",
    "stripe_accounts_are_connected": "Ihr Stripe-Konto ist jetzt mit {projectName} verbunden. {projectName} kann jetzt Kreditkartenzahlungen in Ihrem Namen akzeptieren.",
    "money_won": "Geld gewonnen",
    "leads_won": "Leads gewonnen",
    "offered_by": "Ihr Autohändler",
    "pay_with_saved_credit_card": "Mit Ihrer gespeicherten Kreditkarte zahlen",
    "pay_with_new_credit_card": "Mit einer neuen Kreditkarte zahlen",
    "settings": "Einstellungen",
    "account": "Funktionen",
    "clients": "Firmen",
    "payments": "Zahlungen",
    "calendar": "Kalender",
    "contact": "Kontakt",
    "login": "Log in",
    "logout": "Logout",
    "previous_odometer_reading": "Vorheriger Kilometerstand",
    "current_odometer_reading": "Aktueller Kilometerstand",
    "odometer_reading": "Kilometerstand",
    "tank_volume": "Tankvolumen",
    "vehicle_fuel_costs": "Fahrzeug volltanken kosten",
    "mileage_surcharge": "Mehrkilometerzuschlag",
    "insurance_self_participation": "Versicherung Selbstbeteiligung",
    "additional_costs_due": "Zusätzliche kosten fällig",
    "no_additional_costs": "Keine zusätzlichen Kosten",
    "charge_customer_additional_costs": "Dem Kunden zusätzliche Kosten berechnen",
    "warning_you_chose_not_to_charge_customer_for_additional_costs": "Achtung! Sie haben sich entschieden, dem Kunden keine zusätzlichen Kosten in Rechnung zu stellen",
    "cancel_booking": "Buchung stornieren",
    "no_invoices_found": "Keine Rechnungen gefunden",
    "upload_drivers_license": "Führerschein hochladen",
    "order_details": "Bestelldetails",
    "shipping_address": "Versandadresse",
    "order_overview": "Bestellübersicht",
    "booked_by_mistake": "Aus Versehen gebucht",
    "booked_wrong_dates": "Falsche Termine gebucht",
    "found_better_price": "Besseren Preis gefunden",
    "change_of_plans": "Planänderung",
    "client_called_to_cancel": "Der Kunde hat angerufen und gebeten zu stornieren",
    "client_wrote_email_to_cancel": "Der Kunde hat eine E-Mail geschrieben und gebeten zu stornieren",
    "car_damaged_before_transport": "Das Fahrzeug wurde vor dem Transport beschädigt",
    "car_damaged_in_transport": "Das Fahrzeug wurde beim Transport beschädigt",
    "item_not_available": "Artikel nicht mehr lieferbar",
    "other_reason": "Sonstige",
    "license_plate_number": "Kennzeichen",
    "open_in_google_maps": "In Google Maps öffnen",
    "access_identifier": "Zugangskennung",
    "branch": "Filiale",
    "new": "Neu",
    "in_progress": "In Bearbeitung",
    "failed": "Fehlgeschlagen",
    "not_started": "Nicht gestartet",
    "finished": "Beendet",
    "failed_refunds": "Fehlgeschlagene Erstattungen",
    "list_all_failed_refund_payments": "Alle nicht erfolgreichen Rückerstattungen mit Details auflisten",
    "rent_sync_vamoso_reservation_success": "Erfolgreich im Vamoso-System reserviert. Die Reservierungsnummer ist %reservationId%",
    "rent_sync_vamoso_reservation_failure": "Die Reservierung des Autos im Vamoso-System war nicht möglich. Das System hat folgende Fehlermeldung zurückgegeben: \"%errorText%\"",
    "rent_sync_vamoso_cancellation_success": "Die Reservierung im Vamoso-System wurde erfolgreich storniert",
    "rent_sync_vamoso_cancellation_failure": "Eine Stornierung der Reservierung im Vamoso-System war nicht möglich. Das System hat folgende Fehlermeldung zurückgegeben: \"%errorText%\"",
    "rent_sync_vamoso_booking_success": "Erfolgreich im Vamoso-System gebucht",
    "rent_sync_vamoso_booking_failure": "Buchung der Reservierung im Vamoso-System war nicht möglich. Das System hat folgende Fehlermeldung zurückgegeben: \"%errorText%\"",
    "rent_sync_vamoso_return_success": "Das Fahrzeug wurde erfolgreich als zurückgegeben im Vamoso-System markiert",
    "rent_sync_vamoso_return_failure": "Die Rückgabe des Autos in das Vamoso-System war nicht möglich. Das System hat folgende Fehlermeldung zurückgegeben: \"%errorText%\"",
    "view_lead_details": "Lead-Details anzeigen",
    "change_category": "Kategorie wechseln",
    "json_url_parameters": "JSON URL Parametern",
    "layout_version": "Layoutversion",
    "layout_wide": "Breit",
    "layout_narrow": "Eng",
    "layout_detailed": "Detailliert",
    "layout_slider": "Slider",
    "layout_list": "Liste",
    "search_version": "Suchmodul-Version",
    "not_sure_go_to_advisory_mode": "Ich bin mir nicht sicher: hier geht es zum {link}",
    "link_to_advisory_mode": "Beratungsmodus",
    "desired_pickup_location": "Ihr gewünschter Abholort",
    "incl_short": "inkl.",
    "included": "Inklusive",
    "included_services": "Inklusivleistungen",
    "insurance": "Versicherung",
    "liability_and_comprehensive_insurance_with_excess_amount_of_amount_per_claim": "Die Haftpflicht- und Vollkaskoversicherung mit einer Selbstbeteiligung in Höhe von {amount} je Schadensfall",
    "tyres_suitable_for_winter": "Wintertaugliche Bereifung",
    "additional_driver": "Zusatzfahrer",
    "on_request": "auf Wunsch",
    "navigation_system": "Navigationssystem",
    "child_seat": "Kindersitz",
    "order_comments_and_special_wishes": "Weitere Wünsche",
    "ordering_as_person": "Ich bestelle als Privatperson",
    "ordering_as_company": "Ich bestelle als Unternehmen",
    "filter_by_date": "Nach Datum filtrieren",
    "car_rental_max_days_count_exceeded": "Leider überschreitet der ausgewählte Zeitraum unsere maximale Mietdauer von {count, plural, one {# Tag} other {# Tage}}",
    "require_document_number": "Dokumentennummer anfordern",
    "require_document_issuing_city": "Austellungsort des Dokuments anfordern",
    "require_document_issuing_authority": "Ausstellungsbehörde des Dokuments anfordern",
    "require_document_issue_date": "Ausstellungsdatum des Dokuments anfordern",
    "require_document_expiration_date": "Ablaufdatum des Dokuments anfordern",
    "doc_number": "Dokumentennummer",
    "doc_issuing_city": "Austellungsort des Dokuments",
    "doc_issuing_authority": "Ausstellungsbehörde des Dokuments",
    "doc_issue_date": "Ausstellungsdatum des Dokuments",
    "doc_expiration_date": "Ablaufdatum des Dokuments",
    "chedri_plugins": "Creacheck Plugins",
    "in_order_to_use_our_software_in_its_full_potential_you_need_to_activate_plugins_in_order_to_do_that_please_confirm_that_you_may_sign_contracts_on_behalf_of_your_company": "<p>Um unsere Software nutzen zu können, müssen Sie die gewünschten Funktionen aktivieren. Jede Funktion wird in Form eines Plugins geliefert, was in der regel mitv einerv Zahlungsverpflichtung (Gebühr) einhergeht.<br/>Wenn Sie ein Plugin aktivieren, werden Sie aufgefordert, die Plugin-Bedingungen zu akzeptieren. Bevor Sie fortfahren können, müssen Sie jedoch bestätigen, dass Sie berechtigt sind, im Namen Ihres Unternehmens zu handeln.</p>",
    "i_declare_that_i_am_entitled_to_legally_sign_contracts_on_behalf_of_my_company": "Ich erkläre, dass ich ich berechtigt bin, rechtsverbindlich Verträge im Namen meiner Firma, der {company}, zu unterzeichnen",
    "your_company_was_successfully_registered": "Wir haben Sie und Ihr Unternehmen erfolgreich in unserem System registriert",
    "check_email_for_more_instructions_about_your_company_registration": "Wir haben Informationen zur Vorgehensweise an die von Ihnen angegebene E-Mail-Adresse gesendet. Bitte überprüfen Sie Ihren Posteingang. Wenn Sie Fragen haben oder nicht wissen, wie Sie vorgehen sollen, nehmen Sie bitte Kontakt mit uns auf.",
    "dependent_on": "Abhängig von",
    "we_have_more_plugins_that_further_enhance_the_functionality": "Wir haben weitere Plugins, die die Funktionalität von {plugin} weiter verbessern.",
    "finish_plugin_configuration": "Plugin-Konfiguration beenden",
    "it_seems_that_you_havent_finished_configuring_plugins": "Es scheint, dass Sie die Konfiguration der aktivierten Plugins noch nicht abgeschlossen haben. Für einige Plugins müssen externe Anmeldedaten definiert werden (z. B. für die mobile.de-Schnittstelle oder Vamoso-Anmeldedaten). Bitte gehen Sie zu den Einstellungen und fügen Sie fehlende Konten hinzu.",
    "lead_created_from_an_email": "Dieser Lead wurde aus einer E-Mail erstellt",
    "show_original_message": "Originalnachricht anzeigen",
    "we_found_count_similar_leads": "Wir haben {count, plural, one {# ähnliches Lead} other {# ähnliche Leads}} gefunden",
    "show_similiar_leads": "Ähnliche Leads zeigen",
    "new_lead_from_failed_refund": "Neuer Lead aufgrund fehlgeschlagener Rückerstattung",
    "you_can_apply_company_data_from_company_profile": "Du kannst Firmendaten aus dem Firmenprofil übernehmen",
    "load_company_data": "Firmendaten laden",
    "company_lookup": "Firmensuche",
    "match_lead_tags": "Lead-Tags abgleichen",
    "is_active": "Ist aktiv",
    "is_renew_password_at_next_login": "Passwort bei nächster Anmeldung erneuern",
    "no_overdue_tasks": "Es gibt keine überfälligen Aufgaben",
    "great_job": "Gut gemacht!",
    "no_leads_for_today": "Keine Leads für Sie heute",
    "address_not_set": "Anschrift nicht eingestellt",
    "statistics": "Statistik",
    "select_assignee": "Einen Bearbeiter auswählen",
    "kroschke_order_in_progress": "Kroschke-Auftrag mit Fahrzeug-ID %kroschkeVehicleId% wurde erfolgreich initialisiert",
    "in_original": "Im Original",
    "a_copy": "Eine Kopie",
    "mark_documents_sent": "Unterlagen als gesendet markieren",
    "i_sent_the_documents": "Ich habe die Unterlagen geschickt",
    "documents_were_sent": "Unterlagen wurden gesendet",
    "documents_sent": "Unterlagen gesendet",
    "define_custom_license_plate_number": "Wunschkennzeichen definieren",
    "about_car_registration": "Der Zulassungsservice beinhaltet",
    "i_would_like_individual_license_plate_number": "Ja, ich möchte mein Wunschkenzeichen auswählen",
    "we_gather_necessary_documents": "Anmeldung bei Ihrer Zulassungsbehörde",
    "we_produce_license_plates": "Besorgung und Montage der Nummernschilder",
    "license_plate_reservation_number": "Reservierungsnummer des Kennzeichens",
    "with_custom_license_plate_number": "Mit Wunschkennzeichen",
    "after_the_order_reserve_desired_license_plate_at_local_registration_office": "Nach der Bestellung müssen Sie Ihr gewünschtes Kennzeichen online bei Ihrer örtlichen Zulassungsstelle reservieren.",
    "inform_us_about_your_license_plate_reservation_so_we_can_start_the_process": "Wenn Sie dies getan haben, teilen Sie uns bitte Ihr gewünschtes Kennzeichen und Ihre Reservierungsnummer mit (die Sie von der Zulassungsstelle erhalten haben).",
    "clarify": "Klären",
    "notification": "Mitteilung",
    "license_plate_reservation_data_missing": "Die Reservierungsdaten für das Wunschkennzeichen fehlen",
    "license_plate_reservation_number_filled_out": "Reservierungsdaten für Wunschkennzeichen eingegeben. Wunschkennzeichen: %licensePlateNumber%. Reservierungsnummer: %reservationNumber%",
    "distance_planned": "Geplante Route",
    "distance_driven": "Gefahrene Route",
    "planned_pickup_date": "Geplantes Abholdatum",
    "pickup_date": "Abholdatum",
    "planned_delivery_date": "Geplante Zustellung",
    "your_initial_offer": "Ihr erstes Angebot",
    "your_next_offer": "Ihr nächstes Angebot",
    "car_number": "Fahrzeugnummer",
    "send_price_proposal": "Preisvorschlag senden",
    "our_price": "Unser Preis",
    "purchase_price": "Kaufpreis",
    "our_prices_are_already_optimized_but_if_you_think_price_is_too_high_negotiate_it": "Wir versuchen, unsere Preise für Sie zu optimieren, aber wenn Sie der Meinung sind, dass der Preis immer noch zu hoch ist, teilen Sie uns mit, was wir für Sie tun können.",
    "select_the_car_you_love": "Gewünschtes Auto auswählen",
    "sign_up_or_log_in_if_you_already_have_an_account": "Registrieren oder einloggen, wenn Sie bereits ein Konto haben",
    "enter_desired_price": "Geben Sie den gewünschten Preis ein",
    "send_the_form_our_employee_will_contact_you": "Formular senden. Unser Mitarbeiter wird Sie so schnell wie möglich kontaktieren",
    "if_we_settle_on_a_price_you_can_buy_the_car_online": "Wenn wir uns auf einen Preis einigen, haben Sie die Möglichkeit, das Auto direkt auf unserer Website zu kaufen",
    "you_have_to_log_in_to_proceed": "Sie müssen sich einloggen, um fortzufahren",
    "rent_a_car": "Fahrzeug mieten",
    "pending_price_negotiations_count": "Ausstehende {count, plural, one {# Preisvorschlag} other {# Preisvorschläge}}",
    "my_price_negotiation": "Mein Preisvorschlag",
    "price_negotiation": "Preisvorschlag",
    "price_negotiations": "Preisvorschläge",
    "new_price_negotiation_request": "Neue Preisverhandlungsanfrage",
    "expired": "Abgelaufen",
    "first_offer": "Erstes Angebot",
    "current_offer": "Aktuelles Angebot",
    "expires": "Läuft ab",
    "accept_the_offer": "Angebot annehmen",
    "send_a_new_offer": "Neues Angebot senden",
    "your_new_offer": "Dein neues Angebot",
    "your_project_dashboard": "Ihr {project} Dashboard",
    "add_my_vehicle": "Mein Fahrzeug hinzufügen",
    "no_reply_yet": "Noch keine Antwort",
    "employee_replied": "Mitarbeiter hat geantwortet",
    "price_negotiation_details": "Preisvorschlagsdetails",
    "current_offer_is_amount_you_can_now_proceed_to_checkout": "Ihr aktuelles Angebot ist {amount}. Sie können mit Ihrem ausgehandelten Preis zur Kasse gehen.",
    "show_timeline": "Verlauf anzeigen",
    "communities": "Communities",
    "gain_coverage_with_community": "Erhöhen Sie Ihre Reichweite mit neuen Plattformen. Neue Kunden mit nur einem Klick gewinnen",
    "community": "Community",
    "is_community": "Community aktiv",
    "join_community": "Community Beitreten",
    "leave_community": "Community Verlassen",
    "join_jahreswagen_community_and_profit_from_additional_sales_channel": "<div><p>Eine Sache, die in Creacheck völlig neu ist, sind Communities. Dank der Creacheck-Software ist es möglich, Plattformen für den Beitritt mehrerer Unternehmen zu erstellen.</p><p>Eine solche Plattform ist Jahreswagen.de. Sie können einen Blick auf die Angebote von Jahreswagen werfen. Wenn Sie Mitglied werden möchten, rufen Sie die Community-Einstellungen auf und treten Sie der Jahreswagen-Community bei.</p><p>Die Mitgliedschaft ist kostenlos. Es fallen jedoch Transaktionsgebühren an.</p><p className='m-t-20 alert alert-info'>Wenn Sie eine eigene Idee für eine Community haben, wenden Sie sich an uns. Wir finden dann eine Lösung für Sie.</p></div>",
    "car_description": "Fahrzeugbeschreibung",
    "marketplace_terms": "Nutzungsbedingungen des Marktplatzes",
    "online_appointment_booking": "Onlineterminbuchung",
    "my_online_appointments": "Meine Onlinetermine",
    "employee": "Mitarbeiter",
    "choice_of_date": "Terminwahl",
    "works": "Arbeiten",
    "make_selection": "Auswählen",
    "remove_selection": "Entfernen",
    "tasks": "Aufgaben",
    "task": "Aufgabe",
    "car_service": "Dienstleistung",
    "car_services": "Dienstleistungen",
    "car_service_station": "Arbeitsstation",
    "car_service_stations": "Arbeitsstationen",
    "services_offered_at_your_locations": "Dienstleistungen an Ihren Standorten definieren.",
    "service_stations_where_your_employees_work": "Definieren Sie Arbeitsstationen, damit Sie die Auslastung verfolgen und belegte und freie Ressourcen sehen können.",
    "team_members": "Teammitglieder",
    "service_details": "Service Details",
    "service_location": "Ihr gewählter Servicestandort",
    "service_drop_off_time": "Ihre gewählte Servicezeit",
    "car_service_appointments": "Werkstatt Termine",
    "car_service_booked_at": "Termin gebucht",
    "show_booking_details": "Buchungsdetails anzeigen",
    "saving_draft": "Entwurf wird gespeichert",
    "draft_saved": "Entwurf gespeichert",
    "define_custom_page_templates_to_meet_your_cd": "Wir liefern das Creacheck-System mit integrierten Vorlagen. Hier können Sie Ihre eigenen Vorlagen erstellen, um Ihr Corporate Design besser abzudecken.",
    "edit_description": "Beschreibung bearbeiten",
    "edit_language": "Sprache bearbeiten",
    "create_blank_page": "Leere Seite erstellen",
    "create_from_template": "Aus Vorlage erstellen",
    "use_template": "Benutze die Vorlage",
    "time_left": "Restzeit",
    "animated_header": "Animierter Header",
    "header_size": "Headergröße",
    "options": "Optionen",
    "effects": "Effekte",
    "animation": "Animation",
    "parallax": "Parallaxe",
    "animation_repeat": "Animation wiederholen",
    "animation_intensity": "Animationsintensität",
    "animation_duration": "Animationsdauer",
    "animation_delay": "Animationsverzögerung",
    "animation_initial_opacity": "Anfangsdeckkraft der Animation",
    "animation_speed_curve": "Animationsgeschwindigkeitskurve",
    "parallax_intensity": "Parallaxenintensität",
    "animation_direction": "Parallaxenrichtung",
    "parallax_initial_opacity": "Anfangsdeckkraft der Parallaxe",
    "append_html": "HTML anhängen",
    "css_classes": "CSS-Klassen",
    "forward_url": "Weiterleiten an diese URL",
    "effect_fade": "Einblenden",
    "effect_slide": "Rutschen",
    "effect_zoom": "Zoomen",
    "effect_bounce": "Prallen",
    "effect_flip": "Flip",
    "effect_fold": "Falten",
    "effect_roll": "Rollen",
    "direction_center": "Center",
    "direction_left": "Links",
    "direction_right": "Rechts",
    "direction_top": "Oben",
    "direction_bottom": "Unten",
    "repeat_once": "Einmal",
    "repeat_loop": "Schleife",
    "selected_template": "Ausgewählte Vorlage",
    "premade_layouts": "Vorgefertigte Layouts",
    "your_existing_pages": "Ihre vorhandenen Seiten",
    "count_templates": "{count} Vorlagen",
    "background_attachment": "Hintergrundanhang",
    "background_position": "Hintergrundposition",
    "background_repeat": "Hintergrund Wiederholung",
    "background_size": "Hintergrundgröße",
    "edit_mode": "Bearbeitungsmodus",
    "effects_active": "Effekte aktiv",
    "document_templates": "Dokumentvorlagen",
    "code_document_templates_for_print_use_the_templates_for_individual_quotes_and_invoices": "Codieren Sie Ihre eigenen Dokumentvorlagen, um sie für Ihre Kunden zu drucken. Sie können auch Ihre eigenen Versionen von Angeboten und Rechnungen entwerfen.",
    "currencies": "Währungen",
    "document_template": "Dokumentvorlage",
    "document_content": "Dokumentinhalt",
    "number_prefix": "Nummernpräfix",
    "sales_documents": "Verkaufsunterlagen",
    "theme": "Theme",
    "choose_template": "Dokumentvorlage wählen",
    "default": "Standard",
    "favorites": "Favoriten",
    "favorites_list": "Favoritenliste",
    "require_client_address": "Adresse des Kunden anfordern",
    "require_client_email": "E-Mail-Adresse des Kunden anfordern",
    "require_client_phone": "Telefonnummer des Kunden anfordern",
    "require_message": "Nachricht anfordern",
    "subscription": "Abonnement",
    "subscription_plan": "Abo-Plan",
    "main_plugin": "Haupt-Plugin",
    "target_organisation": "Zielorganisation",
    "reference_number": "Referenznummer",
    "show_invoice": "Rechnung anzeigen",
    "delivery_date": "Lieferdatum",
    "test_drive": "Probefahrt",
    "find_your_perfect_car": "Finden Sie das perfekte Fahrzeug",
    "select_car_design": "Bauform auswählen",
    "multiple_selection_possible": "Mehrfachauswahl möglich",
    "fuel_type": "Kraftstoff",
    "select_equipment_level": "Ausstattungslevel wählen",
    "engine": "Motorisierung",
    "like_budget": "Wie Budget",
    "like_family": "Wie Family",
    "like_comfort": "Wie Komfort",
    "we_did_not_find_any_results_for_your_search": "Ihre Suche ergab leider keinen Treffer",
    "we_did_not_find_any_cars_at_this_location_for_the_selected_period": "Für den ausgewählten Zeitraum haben wir an diesem Standort keine Autos gefunden",
    "we_did_not_find_any_cars_for_your_seaech": "Leider haben wir keine Autos für Ihre Suchanfrage gefunden",
    "all_cars": "Alle Autos zeigen",
    "search_for_cars": "Nach Autos suchen",
    "used_car_search": "Gebrauchtwagensuche",
    "dropdown": "Dropdown-Liste",
    "checkboxes": "Kontrollkästchen",
    "multiple_choice": "Mehrfachauswahl",
    "text_input": "Text Eingabe",
    "number_input": "Zahleneingabe",
    "multiline_input": "Mehrzeiliger Text Eingabe",
    "paragraph": "Abschnitt",
    "change_questionnaire": "Fragebogen ändern",
    "answers_to_a_questionnaire": "Antworten auf einen Fragebogen",
    "request_filling_out_questionnaire": "Senden Sie Ihren Kunden einen Fragebogen",
    "send_questionnaire_as_message": "Den Fragebogen als Nachricht senden",
    "ingoing": "Eingehende",
    "outgoing": "Ausgehende",
    "vat_short": "MwSt.",
    "vat": "Mehrwertsteuer",
    "price_net": "{price} netto",
    "environment": "Umwelt",
    "inquire": "Anfragen",
    "continue_to_product_name": "Weiter zu {productName}",
    "you_can_find_this_and_other_vehicles_at_company": "Diesen und weitere Fahrzeuge finden Sie bei {company}",
    "more_vehicles_based_on_your_search": "Weitere Fahrzeuge basierend auf Ihre Suche",
    "add_to_favorites": "Als Favorit speichern",
    "remove_from_favorites": "Aus Favoriten entfernen",
    "share_the_offer": "Angebot teilen",
    "any_question_the_company_team_is_at_your_disposal": "Sie haben Fragen? Das Team der {company} steht Ihnen zur verfügung.",
    "you_want_this_vehicle_but_the_price_isnt_right_submit_price_proposal": "Sie wollen dieses Fahrzeug doch der Preis stimmt noch nicht ganz? Geben Sie Ihren Preisvorschlag ab.",
    "you_want_to_rent_this_vehicle_at_project_name_you_can": "Sie möchten dieses Fahrzeug für einen längeren Zeitraum fahren, bei {projectName} haben Sie die Möglichkeit Ihr Traumfahrzeug zu mieten.",
    "uploaded_video": "Video hochladen",
    "lead_autoresponse": "Lead Autoresponse",
    "automatically_respond_to_incoming_leads": "Reagiert automatisch auf eingehende Leads. Definieren Sie Wochentage und Stunden, an denen der Autoresponder aktiv ist.",
    "client_gave_count_answers_to_the_name_questionnaire": "Der Kunde gab {count} Antworten auf den Fragebogen \"{name}\"",
    "new_lead_from_comms_center": "Neuer Lead nach geschlossener Kommunikation",
    "customer_chat_ended": "Chat mit einem Kunden beendet",
    "chatter_welcome_message": "Herzlich willkommen. Ich danke Ihnen, dass Sie unseren Chat ausprobiert haben. Wie kann ich Ihnen heute helfen?",
    "please_rate_us": "Bitte bewerten Sie uns",
    "reservation_fee": "Reservierungsgebühr",
    "item_name": "Artikelname",
    "deposit": "Anzahlung",
    "balance_due": "Restbetrag fällig",
    "authorize": "Genehmigen",
    "authorized_by_name_on_date": "Genehmigt von {name} am {date}",
    "auto_generated": "Automatisch generiert",
    "not_authorized": "Nicht genehmigt",
    "authorized": "Genehmigt",
    "settings_instructions": "Anweisungen zu Einstellungen",
    "payment_config": "Zahlungskonfiguration",
    "projects": "Projekte",
    "invoice_address": "Rechnungsadresse",
    "deliver_adress": "Lieferadresse",
    "your_contract_partner": "Ihr Vertragspartner",
    "remaining_balance": "Restbetrag",
    "item_filter_is_active": "Online sichtbar",
    "item_filter_is_resource": "Ist Resource",
    "item_filter_is_subscription": "Finanzierung",
    "cancelled_by_name_on_date": "Storniert von {name} am {date}",
    "add_credit_card_data_in_next_step": "Im nächsten Schritt können Sie Kreditkartendaten hinzufügen",
    "upon_collection": "Bei Abholung",
    "leads_manually_set_to_high_priority": "Leads manuell auf hohe Priorität gesetzt",
    "leads_with_no_reaction_for_2_hours_since_creation": "Leads ohne Reaktion für 2 Stunden seit Schöpfung",
    "leads_that_were_not_updated_for_2_days": "Leads, die 2 Tage lang nicht aktualisiert wurden",
    "use_this_element_as_reference": "Dieses Element als Referenz verwenden",
    "you_can_reuse_this_element_on_multiple_pages": "Auf Inhalte, die auf mehreren Websites wiederverwendet werden, kann referenziert werden. Elemente für den Express Page Builder können nicht als Referenz definiert werden. Solche Elemente werden immer kopiert",
    "company_data": "Unternehmensdaten",
    "company_singup_company_data_hint": "Damit wir Ihre Firma anlegen und Ihnen eine Domain zuweisen können, benötigen wir folgende Informationen von Ihnen.",
    "company_singup_personal_data_hint": "<p>Damit Sie ihren Zugang mit eigenen Benutzerdaten erhalten, benötigen wir folgende Informationen.<br/>Im Anschluss erhalten Sie eine E-Mail mit Ihren persönlichen Zugangsdaten.</p>",
    "your_test_domaine": "Ihr Testdomainname",
    "your_test_domaine_hint": "<p>Bitte erstellen Sie einen Domainnamen, in dem Ihr Projekt gehostet wird. Die Domain ist direkt nach der Registrierung verfügbar.<br/>Denken Sie daran, dass wir Projekte auch unter einem von Ihnen angegebene Domainnamen hosten können. Kontaktieren Sie uns einfach später.</p>",
    "features": "Merkmale",
    "forms": "Formulare",
    "pricings": "Preise",
    "testimonials": "Referenzen",
    "show_your_page_structure_and_responsive_visibility": "Zeigt Ihre Seitenstruktur mit Beschriftungen und responsive Sichtbarkeit an",
    "toggle_edit_mode_for_page_preview": "Schalten Sie den Bearbeitungsmodus für eine Seitenvorschau um",
    "toggle_animation_and_parallax_effects": "Aktivieren und deaktivieren Sie Animations- und Parallaxeneffekte",
    "use_quick_page_builder_to_create_content_faster": "Verwenden Sie den Quick Page Builder, um Inhalte viel schneller zu erstellen",
    "cancel_invoice": "Rechnung stornieren",
    "refunded": "Zurückerstattet",
    "invoice_mark_refunded": "Stellen Sie die Rechnung zurückerstattet",
    "denied_to_pay": "Zahlungsverweigerung",
    "invoice_mark_denied_to_pay": "Wird nicht zahlen",
    "registration_success": "Erfolgreich registriert",
    "no_images_yet": "Noch keine Bilder.",
    "sortingPrice": "Sortierungspreis",
    "main_menu_version": "Hauptmenüversion",
    "main_menu_versions": "Hauptmenüversionen",
    "administration_of_main_menu_versions": "Verwaltung der Hauptmenüversionen",
    "featured": "Hervorgehoben",
    "list_element_class": "Listenelement (li) CSS-Klasse",
    "link_class": "Link CSS-Klasse",
    "width": "Breite",
    "primary_menu_elements": "Elemente des Hauptmenüs",
    "secondary_menu_elements": "Elemente des sekundären Menüs",
    "has_secondary_elements": "Hat sekundäre Elemente",
    "active": "aktiv",
    "company_singup_wizard_step_subscription_plan": "Pakete auswählen",
    "company_singup_wizard_step_subscription_plan_headline": "Wählen Sie das Paket aus, welches Sie testen wollen.",
    "company_singup_wizard_step_additional_plugins": "Zusätzliche Pakete",
    "company_singup_wizard_step_subscription_plan_main_plugin_headline": "Ich möchte folgende Features testen:",
    "company_singup_wizard_step_company_data": "Kontaktdaten von Ihrem Unternehmen",
    "company_singup_wizard_step_company_data_headline": "Name und Kontaktdaten von Ihrem Unternehmen",
    "company_singup_wizard_step_personal_data": "Ihr Mitarbeiterprofil",
    "company_singup_wizard_step_personal_data_headline": "Ihr Mitarbeiterprofil",
    "company_singup_wizard_step_personal_data_hint": "Um ein Mitarbeiterprofil für Sie erstellen zu können, benötigen wir Angaben zu Ihrer Person. Mit diesem Account erhalten Sie Administrationsrechte für Ihr %platformName% Projekt.",
    "company_singup_wizard_step_personal_data_domain_hint": "<p>Bitte erstellen Sie einen Domainnamen, in dem Ihr Projekt gehostet wird. Die Domain ist direkt nach der Registrierung verfügbar.<br/>Denken Sie daran, dass wir Projekte auch unter einem von Ihnen angegebenen Domainnamen hosten können. Kontaktieren Sie uns einfach später.</p>",
    "company_singup_wizard_free_trial_hint": "Testen Sie unsere Angebote einen Monat kostenlos und unverbindlich.",
    "company_signup_wizard_free_trial_plugin_hint": "Einen Monat kostenlos testen, anschließend:",
    "included_in_subscription_plan": "Im Abo enthalten, ansonsten",
    "mostly_asked": "Meist gefragt",
    "test_package": "Paket testen",
    "active_since": "aktiv seit",
    "test_period_until": "Testphase bis",
    "navigate_to_plugins_and_configure": "Zur Plugins-Übersicht und Einstellungen",
    "termination_date": "Datum der Beendigung",
    "lead_taggers": "Lead Taggers",
    "lead_tagger": "Lead Tagger",
    "share_with": "Teilen mit",
    "password_weak": "Schwach",
    "password_medium": "Mittel",
    "password_strong": "Stark",
    "plugin_already_added_in_whitelabel_project": "Das Pluggin ist dem Whitelabel Projekt bereits zugeordnet.",
    "lead_channel": "Kanal",
    "lead_channel_web": "Web",
    "lead_channel_mobile": "App",
    "lead_channel_phone": "Telefon",
    "lead_channel_email": "E-mail",
    "lead_channel_personally": "Persönlich",
    "allow_others_to_share": "Anderen erlauben, mit mehr Menschen zu teilen",
    "additional_imprint_information": "Zusätzliche Impressumsangeben",
    "share_with_the_company_i_work_for": "Mit der Firma, für die ich arbeite, teilen",
    "share_with_all_companies_in_my_holding_structure": "Mit allen Unternehmen in meiner Holding-Struktur, teilen",
    "latest_lead": "Neuester Lead",
    "latest_edit": "Letzte Bearbeitung",
    "reaction_time": "Reaktionszeit",
    "no_reaction_time": "keine Reaktion",
    "gt_2_days": "> 2 Tage",
    "lt_5_minutes": "< 5 Minuten",
    "lt_1_hour": "< 1 Stunde",
    "lt_2_hours": "< 2 Stunden",
    "lt_6_hours": "< 6 Stunden",
    "lt_1_day": "< 1 Tag",
    "lt_2_days": "< 2 Tage",
    "by_employee": "Nach Mitarbeiter",
    "by_company": "Nach Unternehmen",
    "by_location": "Nach Standort",
    "by_source": "Nach Quelle",
    "client_data": "Kundendaten",
    "show_in_lead_description": "In der Lead-Beschreibung anzeigen",
    "this_answer_will_be_shown_in_lead_description": "Diese Antwort wird in der Lead-Beschreibung angezeigt",
    "this_answer_will_not_be_shown_in_lead_description": "Diese Antwort wird in der Lead-Beschreibung nicht angezeigt",
    "lead_assignment_strategy": "Lead-Zuweisungsstrategie",
    "lead_assignment_strategies": "Lead-Zuweisungsstrategien",
    "change_the_lead_assignment_strategies_for_companies_and_pipelines": "Ändern Sie die Art und Weise, wie Ihre Mitarbeiter Leads zugewiesen werden, basierend auf ihren Unternehmen und Pipelines.",
    "strategy": "Strategie",
    "deleted": "Gelöscht",
    "assign_to_me": "Mir zuweisen",
    "responsibilities": "Verantwortlichkeiten",
    "escalation_rules": "Eskalationsregeln",
    "escalation_rule": "Eskalationsregel",
    "define_rules_when_you_would_like_to_inform_users_about_overdue_leads": "Definiert Regeln, mit denen Ihre Teams Leads identifizieren können, die aufgegeben wurden oder auf die die Teammitglieder nicht reagieren.",
    "category_images": "Kategoriebilder",
    "use_document_template": "Dokumentenvorlage benutzen",
    "escalation_level_one": "Stufe eins - Mitarbeiter informieren",
    "escalation_level_two": "Stufe zwei - Teamleiter informieren",
    "automatically_terminate_the_lead": "Lead automatisch beenden (markiere Lead als verloren)",
    "no_reaction": "Keine Reaktion",
    "no_reaction_for": "Keine Reaktion für",
    "no_update": "Kein Update",
    "no_update_for": "Kein Update für",
    "escalation_level": "Eskalationsstufe",
    "time_settings": "Zeit Einstellungen",
    "pending": "Steht aus",
    "skipped": "Übersprungen",
    "holding_structure": "Holding-Struktur",
    "show_sibling_companies": "Geschwisterfirmen zeigen",
    "show_descendant_companies": "Untergeordnete Firmen zeigen",
    "subscription_type": "Finanzierungsmodell",
    "no_subscription_type_selected": "Es muss mindestens ein Finanzierungsmodell gewählt werden.",
    "financing": "Finanzierung",
    "monthly_payment": "Monatliche Rate",
    "final_payment": "Schlussrate",
    "interest_rate": "Zinsatz",
    "availability": "Verfügbarkeit",
    "delivery_time_month": "Lieferzeit (in Monaten)",
    "delivery_time": "Lieferzeit",
    "cancelation_period_month": "Kündigungsfrist (in Monaten)",
    "enter_financing_data": "Finanzierungsdaten eingeben",
    "marketing_agency": "Marketingagentur",
    "edit_company_read_and_write_rights": "Lese- und Schreibrechte des Unternehmens bearbeiten",
    "grant_data_access": "Datenzugriff gewähren",
    "deny_data_access": "Datenzugriff verweigern",
    "allow_data_access_for_a_single_company": "Datenzugriff für eine einzelne Firma ermöglichen",
    "allow_data_access_for_a_whole_level": "Datenzugriff für eine ganze Ebene ermöglichen",
    "deny_data_access_for_a_single_company": "Datenzugriff für eine einzelne Firma verweigern",
    "deny_data_access_for_a_whole_level": "Datenzugriff für eine ganze Ebene verweigern",
    "closing_costs": "Abschlussgebühr",
    "nominal_interest_rate": "Soll-Zinsatz",
    "has_rate_insurance": "Ratenabsicherung",
    "is_user_based": "Ist Benutzer-basiert",
    "min_user_count": "Min Benutzer Anzahl",
    "max_user_count": "Max Benutzer Anzahl",
    "price_per_user": "Preis pro Benutzer",
    "monthly": "Monatlich",
    "instantly": "Sofort",
    "quarterly": "Quartalsweise",
    "annual": "Jährlich",
    "monthly_fee": "Monatliche Gebühr",
    "starting_fee": "Startgebühr",
    "included_templates_count": "Anzahl enthaltener Vorlagen",
    "simple_templates_count": "Anzahl enthaltener Vorlagen (Einfach)",
    "middle_templates_count": "Anzahl enthaltener Vorlagen (Mittel)",
    "complex_templates_count": "Anzahl enthaltener Vorlagen (Complex)",
    "surcharge": "Zuschlag",
    "base_surcharge": "Grundzuschlag",
    "max_surcharge": "Maximaler Aufpreis",
    "dataproof_surcharge": "Dataproof Zuschlag",
    "aws_domain_hosting": "Domain-Hosting via AWS",
    "domain_hosting": "Website-Hosting inkl. Domain",
    "desired_domain": "Wunschdomäne",
    "redirect_uri": "Weiterleitung",
    "check_availability": "Verfügbarkeit prüfen",
    "cart_edition_title": "Bezeichnung:",
    "cart_recent_edition": "Zuletzt bearbeitet:",
    "cart_campaign_title": "Kampagne:",
    "cart_last_saved": "Zuletzt gespeichert:",
    "remove_item": "Produkt entfernen",
    "config": "Konfigurieren",
    "submit_config": "Konfiguration bestätigen",
    "complete_order": "Bestellung abschliessen",
    "payment_method_description": "Bitte wählen Sie die gewünschte Zahlungsart",
    "cart_hint": "Hinweis:",
    "cart_hint_description_part_1": "Hier bitte nur allgemeine Bemerkungen eintragen. Bemerkungen zu Produkten können nur berücksichtig werden, wenn Sie die Option ",
    "cart_hint_description_part_2": "Manuelle Datenprüfung",
    "cart_hint_description_part_3": "angeklickt haben und die Bemerkung beim jeweiligen Produkt eingetragen haben.",
    "your_message": "Ihre Nachricht an uns",
    "hint_dataproof_1": "Sie haben bei einem oder mehreren Artikeln auf die manuelle Datenprüfung durch unseren Experten verzichtet.",
    "hint_dataproof_2": "Wir weisen darauf hin, dass Sie in diesem Fall die volle Verantwortung für Ihre Gestaltung selbst tragen. Im Falle von Datenfehlern, fehlerhaften Farben in Fotos usw. ist eine Reklamation ausgeschlossen. Es gelten unsere AGBs.",
    "hint_dataproof_3": "Falls Sie sich für die manuelle Datenprüfung entscheiden, wird Ihre Datei von einem Experten auf eventuelle Fehler geprüft (technische Prüfung, keine Rechtsschreibung). Im Falle, dass wir einen Fehler übersehen, übernehmen wir die Verantwortung. Kein Risiko für Sie!",
    "hint_missing_order_quantity": "Bestellmenge fehlt!",
    "missing_order_quantity": "Sie haben bei einem oder mehreren Artikeln keine Bestellmenge ausgewählt.",
    "missing_invoice_shipping_address": "Die Rechnungs- oder Lieferadresse fehlt. Bitte wählen Sie eine Adresse aus.",
    "billing_address_form_is_not_submitted": "Die Rechnungsadresse wurde nicht übermittelt. Bitte füllen Sie das Formular aus.",
    "shipping_address_form_is_not_submitted": "Die Lieferadresse wurde nicht übermittelt. Bitte füllen Sie das Formular aus.",
    "no_invoice_address": "Keine Rechnungsadresse erstellt",
    "no_delivery_address": "Keine Lieferadresse erstellt",
    "use_invoice_contact": "Rechnungskontakt verwenden",
    "order_item": "Artikel",
    "order_price": "Preis",
    "order_copies": "Auflage",
    "order_totals": "Auftragssummen",
    "subtotal": "Zwischensumme",
    "invoice_amount": "Rechnungsbetrag",
    "remainder_available": "Nicht verteilte Restmenge vorhanden",
    "reduce_edition": "Um eine neue Adresse hinzuzufügen, müssen Sie zunächst die Auflage der bisherigen Adresse verringern.",
    "split_delivery_item_hint": "Die Aufteilung der Gesamtmenge ist noch nicht abgeschlossen. Die Mengenangabe muss ein Wert zwischen 1 und der noch zu verteilenden Restmenge bzw. der Auflage sein; aktuell ein Wert von höchstens ",
    "net_amount": "Nettobetrag",
    "gross_amount": "Bruttobetrag",
    "is_debtor": "Kreditor",
    "is_debtor_number_provider": "Schuldnernummer Anbieter",
    "is_never_remind": "keine Erinnerungen senden",
    "is_demo": "Demo-Modus",
    "is_vendor": "Lieferant",
    "should_user_pay": "Der Benutzer soll zahlen",
    "receive_payment_from_users": "Zahlung von Benutzern erhalten",
    "use_payment_receiver": "Zahlungsempfängeroption verwenden",
    "payment_receiver": "Zahlungsempfänger",
    "use_summary_invoice": "Sammelrechnung verwenden",
    "credit_limit": "Kreditlimit",
    "commission_receiver": "Provisionsempfänger",
    "budget_value": "Budgetwert",
    "budget_value_used": "Genutzt",
    "budget_value_reserved": "Reserviert",
    "budget_value_residual": "Restwert",
    "billing_value": "Abrechnungswert",
    "coupon_billing": "Abrechnungswert für gewählten Abrechnungszeitraum",
    "coupon_billing_period": "Abrechnungszeitraum",
    "coupon_recharge_period": "Gutschein aufladen",
    "coupon_print_type": "Gutschein einschränken",
    "coupon_never": "Nie",
    "coupon_unset": "Keine Einschränkung",
    "coupon_print_pdf": "Nur Druck-PDF",
    "recurring": "Wiederkehrend",
    "period": "Periode",
    "without_end_date": "Ohne Enddatum",
    "budget_settings": "Budgeteinstellungen",
    "no_budget_set": "Kein Budget eingestellt",
    "current_period_budget_usage": "Budgetverwendung in der aktuellen Periode",
    "user_entered_no_message": "Benutzer hat keine Nachricht eingegeben",
    "coupons": "Gutscheine",
    "coupon": "Gutschein",
    "create_discounts_and_subsidy_products": "Rabattcodes erstellen und Produkte für Ihre Partner subventionieren",
    "authorization_status": "Autorisierungsstatus",
    "user_budget": "User-Budget",
    "manual_vat_check": "Reduzierte Mehrwertsteuersatzprüfung",
    "manual_data_check": "Manuelle Datenprüfung",
    "completed": "Abgeschlossen",
    "changes_requested": "Änderungen angefordert",
    "rejected": "Abgelehnt",
    "request_changes": "Änderungen anfordern",
    "reply": "Antworten",
    "coupon_monetary": "Fester Rabatt",
    "coupon_percentage": "Prozent Rabatt",
    "calculations": "Kalkulationen",
    "percent_discount_value": "Prozent Rabatt",
    "monetary_discount_value": "Geldrabatt",
    "company_budget": "Firmenbudget",
    "enter_0_to_ignore_this_setting": "0 eingeben, um diese Einstellung zu ignorieren",
    "min_order_value": "Mindestbestellwert",
    "max_order_discount": "Maximaler Rabatt pro Bestellung",
    "coupon_budget_exceeded": "Gutscheinbudget überschritten",
    "billing_target": "Abrechnungsziel",
    "billing_settings": "Abrechnungseinstellungen",
    "dataproof_textarea": "Müssen wir etwas beachten?",
    "edit": "Bearbeiten",
    "edit_client": "Kundendaten bearbeiten",
    "edit_gallery": "Galeriedetails bearbeiten",
    "edit_job": "Jobdetails bearbeiten",
    "edit_position": "Position bearbeiten",
    "edit_product": "Produkt bearbeiten",
    "edit_producttemplate": "Template bearbeiten",
    "edit_question": "Frage bearbeiten",
    "edit_template": "Vorlage bearbeiten",
    "edit_step": "Pipeline-Stufe bearbeiten",
    "edit_payment": "Zahlung bearbeiten",
    "edit_team": "Team bearbeiten",
    "edit_document_type": "Dokumenttyp bearbeiten",
    "edit_brand": "Marke bearbeiten",
    "edit_model": "Modell bearbeiten",
    "edit_category": "Kategorie bearbeiten",
    "edit_attribute": "Attribut bearbeiten",
    "edit_data_push": "Daten-Push bearbeiten",
    "edit_lead_catcher": "Lead Catcher bearbeiten",
    "edit_external_account": "Externe Konto bearbeiten",
    "edit_comment": "Kommentar bearbeiten",
    "edit_delivery_date": "Lieferdatum bearbeiten",
    "edit_lead_tagger": "Lead Tagger bearbeiten",
    "edit_commission_receiver": "Provisionsempfänger bearbeiten",
    "edit_payment_receiver": "Zahlungsempfänger bearbeiten",
    "edit_billing_target": "Abrechnungsziel bearbeiten",
    "create_client": "Neue Firma anlegen",
    "create_gallery": "Neue Galerie erstellen",
    "create_job": "Neuen Job erstellen",
    "create_product": "Neues Produkt erstellen",
    "create_producttemplate": "Neues Template erstellen",
    "create_questionnaire": "Neuen Fragebogen erstellen",
    "create_question": "Neue Frage erstellen",
    "create_template": "Neue Email-Vorlage erstellen",
    "create_cms_template": "Neue CMS Vorlage erstellen",
    "create_pipeline": "Neuen Pipeline erstellen",
    "create_step": "Neue Pipeline-Stufe erstellen",
    "create_payment_schedule": "Neuen Zahlungsplan erstellen",
    "create_payment": "Neue Zahlung anlegen",
    "create_message": "Eine Nachricht schreiben",
    "create_text_message": "Eine Textnachricht schreiben (SMS)",
    "create_note": "Eine Notiz schreiben",
    "create_user": "Neuen Benutzer anlegen",
    "create_users": "Benutzer anlegen",
    "create_doc_type": "Neuen Dokumenttyp erstellen",
    "create_team": "Team erstellen",
    "create_brand": "Marke erstellen",
    "create_model": "Modell erstellen",
    "create_category": "Kategorie erstellen",
    "create_attribute": "Attribut erstellen",
    "create_attribute_group": "Attributgruppe erstellen",
    "create_car": "Fahrzeug erstellen",
    "create_item": "Produkt erstellen",
    "create_lead": "Lead erstellen",
    "assign_lead": "Lead zuordnen",
    "create_page": "Seite erstellen",
    "create_data_push": "Neuen Daten-Push erstellen",
    "create_white_label_project": "Neues White-Label-Projekt erstellen",
    "create_cms_element": "Neues CMS Element erstellen",
    "create_lead_catcher": "Neuen Lead Catcher estellen",
    "create_white_label_plugin": "Neues White-Label Plugin erstellen",
    "create_dynamic_data_definition": "Erstellen Sie eine neue dynamische Datendefinition",
    "add_account": "Konto hinzufügen",
    "create_external_account": "Neue externe Konto hinzufügen",
    "create_model_group": "Neue Modellgruppe erstellen",
    "create_model_asset": "Neues Modellbild erstellen",
    "create_lead_per_mail_rule": "Neuen Lead per E-Mail Regel erstellen",
    "duplicate_page": "Seite duplizieren",
    "duplicate_cms_element": "CMS Element duplizieren",
    "duplicate_cms_template": "CMS Vorlage duplizieren",
    "create_task": "Aufgabe erstellen",
    "create_car_service": "Dienstleistung erstellen",
    "create_car_service_station": "Arbeitsstation erstellen",
    "create_doc_template": "Neue Dokumentvorlage erstellen",
    "create_lead_autoresponse": "Neuen Lead Autoresponse erstellen",
    "create_main_menu_version": "Hauptmenüversion erstellen",
    "create_lead_tagger": "Lead Tagger erstellen",
    "create_lead_assignment_strategy": "Lead-Zuweisungsstrategie erstellen",
    "create_escalation_rule": "Eskalationsregel erstellen",
    "create_coupon": "Gutschein erstellen",
    "client_data_saved": "Kundendaten gespeichert",
    "user_data_saved": "Benutzerdaten gespeichert",
    "job_data_saved": "Jobdaten gespeichert",
    "product_data_saved": "Produktdaten gespeichert",
    "template_data_saved": "Vorlage Daten gespeichert",
    "company_logo_saved": "Logo wurde gespeichert",
    "payment_schedule_data_saved": "Zahlungsplan wurde gespeichert",
    "questionnaire_filled_out": "Fragebogen ausgefüllt",
    "your_message_has_been_sent": "Ihre Nachricht wurde gesendet",
    "email_with_password_reset_instructions_sent": "E-Mail mit Passwort zurücksetzen Anweisungen wurde gesendet",
    "password_changed_after_reset": "Ihr Passwort wurde geändert. Willkommen zurück",
    "quote_accepted_saved": "Angebot wurde akzeptiert",
    "quote_rejected_saved": "Angebot wurde abgelehnt",
    "quote_saved": "Angebot gespeichert",
    "message_sent": "Nachricht wurde gesendet",
    "note_saved": "Notiz wurde gespeichert",
    "holding_info_saved": "Holding-Informationen gespeichert",
    "new_password_set": "Es wurde ein neues Passwort gesetzt",
    "questionnaire_data_saved": "Fragebogen gespeichert",
    "doc_type_saved": "Dokumenttyp gespeichert",
    "team_saved": "Team gespeichert",
    "document_saved": "Dokument gespeichert",
    "brand_saved": "Marke gespeichert",
    "model_saved": "Modell gespeichert",
    "category_saved": "Kategorie gespeichert",
    "attribute_saved": "Attribut gespeichert",
    "item_data_saved": "Produktdaten gespeichert",
    "car_data_saved": "Fahrzeugdaten gespeichert",
    "variant_saved": "Variant gespeichert",
    "pipeline_saved": "Pipeline gespeichert",
    "comment_saved": "Kommentar gespeichert",
    "lead_saved": "Lead gespeichert",
    "order_saved": "Bestellung gespeichert",
    "document_reviewed": "Dokument überprüft",
    "locations_saved": "Standorte gespeichert",
    "email_confirmed": "E-Mail bestätigt",
    "page_saved": "Seite gespeichert",
    "template_saved": "Vorlage gespeichert",
    "data_push_saved": "Daten-Push gespeichert",
    "white_label_saved": "White-Label Projekt gespeichert",
    "attribute_group_saved": "Attributgruppe gespeichert",
    "cms_element_saved": "CMS Element gespeichert",
    "lead_catcher_saved": "Lead catcher gespeichert",
    "image_data_saved": "Bilddaten gespeichert",
    "white_label_plugin_saved": "White-Label Plugin gespeichert",
    "dynamic_data_definition_saved": "Dynamische Datendefinition gespeichert",
    "company_data_saved": "Firmendaten gespeichert",
    "contact_data_saved": "Kontaktdaten gespeichert",
    "no_changes_made": "Keine Änderungen vorgenommen",
    "car_order_approved": "Autoverkauf genehmigt",
    "vin_number_updated": "Fahrgestellnummer des Fahrzeugs aktualisiert",
    "ein_number_updated": "Elektronische Versicherungsbestätigung aktualisiert",
    "order_marked_as_paid": "Bestellung als Bezahlt markiert",
    "payment_instructions_sent": "Zahlungsanweisungen gesendet",
    "order_cancelled": "Bestellung storniert",
    "sales_contract_uploaded": "Verkaufsvertrag hochgeladen",
    "bank_data_updated": "Bankdaten aktualisiert",
    "pickup_invite_sent": "Abholung Einladung gesendet",
    "order_picked_up": "Bestellung abgeholt",
    "order_assignee_kroschke_documents_sent": "Der Autohändler hat die für die Registrierung des Fahrzeugs erforderlichen Unterlagen gesendet",
    "order_client_kroschke_documents_sent": "Der Kunde hat die für die Registrierung seines Fahrzeugs erforderlichen Unterlagen gesendet",
    "plugin_partial_activated": "Plugin teilweise aktiviert",
    "plugin_request_activate_sent": "Plugin-Aktivierungsanfrage gesendet",
    "plugin_activated": "Plugin aktiviert",
    "plugin_terminated": "Plugin beendet",
    "changes_saved": "Änderungen gespeichert",
    "app_secret_saved": "App secret gespeichert",
    "page_access_token_saved": "Page Access Token gespeichert",
    "text_message_sent": "SMS gesendet",
    "account_saved": "Konto gespeichert",
    "model_group_saved": "Modellgruppe gespeichert",
    "model_asset_saved": "Modellbild gespeichert",
    "sepa_agreement_saved": "SEPA-Mandat gespeichert",
    "document_uploaded": "Ein Dokument wurde hochgeladen",
    "customer_sent_message": "Kunde hat eine Nachricht gesendet",
    "lead_created": "Lead erstellt",
    "lead_watching": "Begann mit der Beobachtung der Leads",
    "lead_unwatching": "Beendet die Beobachtung der Leads",
    "lead_per_mail_rule_saved": "Lead per E-Mail Regel gespeichert",
    "drivers_license_uploaded": "Führerschein hochgeladen",
    "plugin_saved": "Plugin gespeichert",
    "settings_saved": "Einstellungen gespeichert",
    "page_duplicated": "Seite dupliziert",
    "cms_template_duplicated": "CMS Vorlage dupliziert",
    "cms_element_duplicated": "CMS Element dupliziert",
    "price_negotiation_started": "Preisverhandlung gestartet",
    "new_price_negotiation_message_offer_sent": "Neues Preisverhandlungsangebot gesendet",
    "you_joined_community": "Du bist der Community beigetreten",
    "you_left_community": "Du hast die Community verlassen",
    "car_service_booked": "Onlinetermin gebucht",
    "car_service_saved": "Dienstleistung gespeichert",
    "car_service_station_saved": "Arbeitsstation gespeichert",
    "team_member_added": "Teammitglied hinzugefügt",
    "team_member_removed": "Teammitglied entfernt",
    "doc_template_saved": "Dokumentvorlage gespeichert",
    "invoice_issued": "Rechnung ausgestellt",
    "request_to_fill_out_questionnaire_saved": "Anfrage zum Ausfüllen eines Fragebogens gespeichert",
    "request_to_fill_out_questionnaire_name_saved": "Anfrage zum Ausfüllen eines Fragebogens \"{name}\" gespeichert",
    "lead_autoresponse_saved": "Lead Autoresponse gespeichert",
    "main_menu_version_saved": "Hauptmenüversion gespeichert",
    "lead_tagger_saved": "Lead Tagger gespeichert",
    "share_settings_updated": "Freigabeeinstellungen aktualisiert",
    "lead_assignment_strategy_saved": "Lead-Zuweisungsstrategie gespeichert",
    "escalation_rule_saved": "Eskalationsregel gespeichert",
    "value_type": "Typ",
    "value_precision": "Genauigkeit",
    "category_already_in_attribute": "Die Kategorie ist dem Attribut bereits zugewiesen.",
    "no_attributes_for_variants": "Sie müssen Attribute definieren, um Varianten zu erstellen.",
    "budget_saved": "Budget gespeichert",
    "budget_removed": "Budget entfernt",
    "coupon_saved": "Gutschein gespeichert",
    "authorization_data_saved": "Autorisierungsdaten gespeichert",
    "template_data": "Allgemeines",
    "template_pages": "Seiteneinstellungen",
    "template_editor": "Editor & PDF Einstellungen",
    "template_timeline": "Timeline",
    "template_blocks": "Inhaltsblöcke",
    "template_import": "Importieren",
    "template_export": "Exportieren",
    "template_generate": "Generator (TEST)",
    "template_print_data": "Druckdaten",
    "template_import_select_template": "Template Ordner auswählen",
    "template_import_select_print_data_document": "Druckdaten Dokument auswählen",
    "template_upload_print_data_title": "Druckdaten in das Vorlagenverzeichnis hochladen",
    "template_upload_print_data_actual_file_title": "Hochgeladene Druckdaten",
    "template_upload_print_data_info": "Fügt dem S3-Vorlagenverzeichnis eine Datei hinzu. Diese Datei wird für Produkte verwendet, wenn sie erstellt werden, und es werden keine Druckdaten erzeugt.",
    "template_blocks_template": "HTML Block",
    "template_category_thumb": "Thumbnail Icon Klasse (Bsp.: fad fa-circle)",
    "add_block_category": "Neue Kategorie",
    "edit_block_category": "Kategorie bearbeiten",
    "add_block_variant": "Neue Variante",
    "edit_block_variant": "Variante bearbeiten",
    "add_block": "Neuer Block",
    "copy_block": "Kopiere Block",
    "edit_block": "Block bearbeiten",
    "edit_takeit": "Übernehmen",
    "edit_import": "Importieren",
    "edit_import_warning": "Sicher? Dies überschreibt alle nicht gespeicherten Einträge!",
    "template_has_content_blocks": "Variabler Inhalt",
    "template_in_test": "Testmodus für Bestellungen",
    "placeholder": "Platzhalter für Serienbrief",
    "select_customer": "Kunden auswählen",
    "company_added": "Firma hinzugefügt",
    "company_removed": "Firma entfernt",
    "item_added": "Produkt hinzugefügt",
    "item_removed": "Produkt entfernt",
    "category_added": "Kategorie hinzugefügt",
    "category_removed": "Kategorie entfernt",
    "select_user_set": "User Berechtigung auswählen",
    "sent_reminders": "Erinnerungen gesendet",
    "number_of_sent_reminders": "Anzahl gesendeter Erinnerungen",
    "set_number": "Nummer setzen",
    "email_to_the_customer_on_save": "Beim Speichern per E-Mail an den Kunden senden",
    "email_on_save": "E-Mail beim Speichern",
    "shown_name": "Angezeiger Name",
    "filename_thumbnail": "Dateiname für Thumbnail",
    "name_of_imagefile": "Name der Bilddatei, ohne Verzeichnis. Bsp.: thumb1.jpg",
    "block_position": "Position des Blocks in Rasterzeilen, 0 für ganz oben, macht den Block absolut",
    "defined_raster_space": "vordefinierter Rasterplatz",
    "needed_raster_space": "benötigter Rasterplatz",
    "size_group_info_text": "Alle Blöcke mit der selben SizeGroup können im Editor in der Größe durchgeschaltet werden",
    "size_group": "Size Group",
    "group_index_info_text": "Alle Blöcke mit der selben SizeGroup und der selben Blockgröße können mit dem Index durchgeschaltet werden. Ab Index 1.",
    "group_index": "Group Index",
    "bottom_info_text": "ACHTUNG: bottom positioniert den Block absolut an den unteren Seitenrand, mit dem eingestellten Abstand in PX",
    "bottom_label": "Block absolut unten positionieren",
    "fixed_block_label": "Fixed Block",
    "fixed_block_info_text": " Block kann nicht verschoben werden",
    "once_per_document_label": "Once per Document",
    "once_per_document_info_text": "nur einmal im gesamten Dokument erlaubt",
    "no_copies_label": "Block nicht kopierbar",
    "no_copies_info_text": "Block kann nicht kopiert werden",
    "no_options_label": "Keine Optionen anzeigen",
    "no_options_info_text": "Block hat keine Optionen im Editor, wenn eingefügt.",
    "once_per_page_label": "Once per Page",
    "once_per_page_info_text": "nur einmal auf einer Seite erlaubt",
    "once_per_variant_label": "Once per Variant",
    "once_per_variant_info_text": "nur einmal pro Variante erlaubt",
    "once_per_category_label": "Once per Category",
    "once_per_category_info_text": "nur eins pro Kategorie erlaubt",
    "only_whitelabel_admin_can_modify_label": "nur Whitelabel-Administrator",
    "only_whitelabel_admin_can_modify_info_text": "Dieses Feld kann nur vom Whitelabel-Administrator geändert werden. Es sollte verwendet werden, wenn der Whitelabel-Administrator eine Vorlage erstellt.",
    "restricted_to_page_label": "Auf Seite erlaubt",
    "restricted_to_page_info_text": "Nur auf dieser Seite verfügbar, -1 = überall erlaubt",
    "exclude_pages_label": "Seiten ausschließen (Bsp: 1,2,3...)",
    "exclude_pages_info_text": "Auf diesen Seiten nicht erlaubt, trennen mit Komma",
    "only_last_label": "Nur auf letzte Seite",
    "only_last_info_text": "Block kann nur auf letzter Seite eingefügt werden.",
    "container_block_label": "Container Block",
    "container_block_info_text": "Dieser Block enthält einen Container für weitere Blöcke",
    "inline_block_label": "Inline Block",
    "inline_block_info_text": "Block wird als display:inline-block behandelt",
    "inner_label": "In Block Container einfügbar",
    "inner_info_text": "Block kann in Block Container eingefügt werden",
    "container_class_label": "Begrenzen auf Container Block",
    "container_class_info_text": "Klassenname des Containers in dem der Block eingefügt werden kann.",
    "only_inside_block_label": "Nur in Block Container einfügbar",
    "only_inside_block_info_text": "Block kann ausschließlich in Block Container eingefügt werden",
    "container_space_label": "Rasterplätze im Container Block",
    "container_space_info_text": "verfügbare Rasterzeilen innerhalb des Blocks (selbes Raster wie Page!)",
    "container_cols_label": "Spalten im Container Block",
    "container_cols_info_text": "verfügbare Rasterzeilen werden mit diesem Wert multipliziert",
    "website_only": "Nur für Webseiten",
    "navName": "Name für Navigation",
    "nav_link_label": "Link für Navigation",
    "nav_link_info_text": "Anchor Link zur ID vom Block",
    "nav_options": "Options für Navigation",
    "combi_parent_label": "Kombi-Element (Parent)",
    "combi_parent_info_text": "Elternelement, kann jederzeit eingefügt werden",
    "combi_child_label": "Kombi-Element (Child)",
    "combi_child_info_text": "Kindelemente können nur in Kombination mit dem Elternelement gesetzt werden",
    "disable_group_label": "Gruppenoptionen",
    "disable_group_info_text": "Hier können Sie Gruppen definieren, die sich gegenseitig ausschließen. z. B. P#Gruppe1,Gruppe2 und D#Gruppe2,Gruppe1. Definitionsparamter - P = gültig für die Seite, D = gültig für das Dokument.",
    "events": {
      "TimelineMailPasswordReset": "Kunde hat die Passwortänderung angefordert",
      "TimelineMailRegistration": "Kunde hat sich bei Creacheck.com registriert",
      "TimelineMailOauthRegistration": "Kunde hat sic bei Creacheck.com mit Social Media Login registriert",
      "TimelineMailInvitation": "Der Kunde wurde eingeladen, Creacheck.com zu benutzen",
      "TimelineMailContactForm": "Kunde hat eine Nachricht über das Kontaktformular gesendet",
      "TimelineMailInvitationAccepted": "Der Kunde hat die Einladung angenommen",
      "TimelineMailNewInvoice": "Sie haben dem Kunden eine Rechnung erteilt",
      "TimelineMailNewQuote": "Sie haben ein Angebot an den Kunden gesendet",
      "TimelineMailNewMessage": "Sie haben eine Nachricht an den Kunden gesendet",
      "TimelineQuoteAccepted": "Der Kunde hat Ihr Angebot akzeptiert",
      "TimelineQuoteRejected": "Leider hat der Kunde Ihr Angebot abgelehnt",
      "TimelineOrderCreated": "Neue Bestellung",
      "TimelineMailNewCustomerMessage": "E-Mail-Nachricht vom Kunden",
      "TimelineMonitoringPaymentReceipt": "Zahlungseingang überwachen",
      "TimelineClosingManualDataCheck": "Manuelle Datenprüfung geschlossen",
      "TimelineUpdatedByForeignStatus": "Auftragsstatus aktualisiert",
      "TimelineMailedForeignOrderStatusOrderCanceled": "storniert von Lieferant",
      "TimelineMailedForeignOrderStatusInDelivery": "in Zustellung",
      "TimelineMailedForeignOrderStatusInProduction": "in Produktion",
      "TimelineMailedForeignOrderStatusInReview": "in Rückfrage",
      "TimelineMailedForeignOrderStatusInOrder": "in Bestellung",
      "TimelineMailedOrderedWebAndDownloadPDF": "Web-fähige und Download-PDF an E-Mail Empfänger versandt.",
      "TimelineMailedOrderedDownloadPDF": "Download-PDF an E-Mail Empfänger versandt.",
      "TimelineMailedOrderedWebPDF": "Web-fähige an E-Mail Empfänger versandt.",
      "TimelineDomainHostingHasBeenAuthorized": "Domain-Hosting wurde autorisiert",
      "TimelineDomainNotAvailable": "Domäne ist nicht länger verfügbar",
      "TimelineDomainHostingDomainValidated": "Domäne wurde validiert",
      "TimelineExceptionCheckDomainAvailability": "Fehler bei Prüfung auf Domänenverfügbarkeit von {0}. Fehler: {2}",
      "TimelineDomainFailRegistering": "Registrierung der Domäne {0} fehlgeschlagen",
      "TimelineExceptionRegisterDomain": "Registrierung der Domäne {0} fehlgeschlagen mit Fehler: {2}",
      "TimelineDomainSuccessfullyRegistered": "Domäne {0} wurde erfolgreich registiert.",
      "TimelineDomainSuccessfullyRedirected": "Domäne erfolgreich umgeleitet",
      "TimelineDomainCreatingCertificate": "Zertifikat für Domäne {0} wird erstellt mit Fehler: {1}",
      "TimelineDomainCreatingDistributionFailed": "Verteilung der Domäne {0} fehlgeschlagen mit Fehler: {1}",
      "TimelineDomainCreatingSuccessfullyHosted": "Domäne {0} wurde erfolgreich gehostet",
      "TimelineDomainCreatingFailedHosting": "Domäne {0} konnte nicht gehostet werden mit Fehler: {1}",
      "TimelineDomainHostingCompleted": "Abgeschlossen",
      "TimelineMissingClientDataFirstAndLastName": "Fehlender Vor- und Nachname des Kunden!",
      "TimelineMissingClientDataPhoneNumber": "Fehlende Telefonnummer des Kunden!",
      "TimelineDomainCreatingSuccessfullyFinished": "Domäne {0} wurde erfolgreich abgeschlossen",
    },
    "attributeName": {
      "select": "Auswahlfeld",
      "html": "HTML-Feld",
      "checkbox": "Checkbox",
      "text": "Textfeld",
      "colors": "Farbenauswahl",
      "number": "Nummerfeld",
      "boolean": "Ja/Nein",
      "date": "Datum"
    },
    "attributeType": {
      "select": "Sie können mehrere Optionen zum auszuwählen definieren. Der Benutzer kann dann eine davon auswählen",
      "html": "Benutzer kann einen längeren Text mit Rich Content Editor schreiben",
      "checkbox": "Sie können mehrere Optionen zum auszuwählen definieren. Der Benutzer kann dann mehrere davon auswählen",
      "text": "Benutzer kann einen einfachen Text eingeben",
      "colors": "Benutzer kann eine aus hier definierten Farben wählen",
      "number": "Der Benutzer kann eine Zahl eingeben",
      "boolean": "Benutzer darf zwischen ja und nein wählen",
      "date": "Der Benutzer kann ein Datum wählen"
    },
    "attributeValueType": {
      "string": "Text",
      "number": "Zahl",
      "date": "Datum"
    },
    "attributePrecision": {
      "year": "Jahr",
      "month": "Monat",
      "day": "Tag",
      "time": "Uhrzeit"
    },
    "categoryDisplay": {
      "select": "Ja/nein",
      "number_range": "Zahlenbereich",
      "date_range": "Datumsbereich",
      "list": "Listenauswahl"
    },
    "invoice_actions": {
      "tab_title": "Aktionen auf der Rechnung",
      "refunded": {
        "title": "Manuell erstattet",
        "label": "Als erstattet markieren",
        "info_text": "Manuelle Kennzeichnung der Rechnung als bereits erstattet, ohne eine Stornorechnung zu erstellen"
      },
      "denied_to_pay": {
        "title": "Zahlung verweigert",
        "label": "Als verweigert markieren",
        "info_text": "Markieren Sie, dass die Rechnung nie bezahlt werden wird"
      },
      "amount_paid": {
        "title": "Gezahlten Betrag eingeben",
        "placeholder": "Gezahlten Betrag eingeben",
        "button": "Gezahlten Betrag speichern"
      },
      "sent_reminders": {
        "title": "Gesendete Mahnungen",
        "placeholder": "Anzahl der gesendeten Mahnungen",
        "button": "Nummer speichern"
      }
    },
    "mail": {
      "registration": {
        "subject": "Vielen Dank, für die Registrierung bei %company%",
        "subject_robethood": "Robethood Creatives Portal: Anmeldung erfolgreich.",
        "explanation": "vielen Dank für Ihre Registrierung. Bitte bestätigen Sie innerhalb von 24 Stunden Ihre E-Mail-Adresse. Nur mit einer bestätigten E-Mail Adresse können Sie ein neues Internet-Passwort oder Infos über Änderungen bekommen.",
        "explanation_first_name_form": "<p>vielen Dank für deine Registrierung. Bitte bestätige innerhalb von 24 Stunden deine E-Mail-Adresse.</p><p>Nur mit einer bestätigten E-Mail Adresse kannst du ein neues Passwort oder Infos über Änderungen erhalten.</p>",
        "possible_actions": "Nachdem Ihre E-Mail-Adresse bestätigt wurde, erhalten Sie sofort Zugriff auf alle Funktionen, die wir für Sie vorbereitet haben.",
        "possible_actions_first_name_form": "Nachdem deine E-Mail-Adresse bestätigt wurde, erhältst du sofort Zugriff auf alle Funktionen, die wir für dich vorbereitet haben.",
        "call_to_action": "Bestätigen"
      },
      "ordered_web_pdf": {
        "subject": "Web-fähige PDF zur Bestellung %orderId% von %company%",
        "explanation": "vielen Dank für Ihre Bestellung. Sie können die Web-fähige PDF mit einem Klick auf nachfolgenden Button öffnen und herunterladen.",
        "explanation_first_name_form": "vielen Dank für deine Bestellung. Du kannst die Web-fähige PDF mit einem Klick auf nachfolgenden Button öffnen und herunterladen.",
        "possible_actions": "Sie können sich jederzeit im Onlineportal unter %domain% einloggen und Ihre Bestellung anzeigen.",
        "possible_actions_first_name_form": "Du kannst dich jederzeit im Onlineportal unter %domain% einloggen und deine Bestellung anzeigen.",
        "call_to_action": "Bestellung anzeigen",
        "download": "Web-fähige PDF herunterladen"
      },
      "ordered_download_pdf": {
        "subject": "PDF zur Bestellung %orderId% von %company%",
        "explanation": "vielen Dank für Ihre Bestellung. Sie können die PDF mit einem Klick auf nachfolgenden Button öffnen und herunterladen.",
        "explanation_first_name_form": "vielen Dank für deine Bestellung. Du kannst die PDF mit einem Klick auf nachfolgenden Button öffnen und herunterladen.",
        "possible_actions": "Sie können sich jederzeit im Onlineportal unter %domain% einloggen und Ihre Bestellung anzeigen.",
        "possible_actions_first_name_form": "Du kannst dich jederzeit im Onlineportal unter %domain% einloggen und deine Bestellung anzeigen.",
        "call_to_action": "Bestellung anzeigen",
        "download": "PDF herunterladen"
      },
      "order_vat_data_check_done": {
        "subject": "Ihre Beauftragung der manuellen Datenprüfung zur Bestellung %orderId% von %company%",
        "explanation": "vielen Dank für Ihre Bestellung und die Beauftragung der manuellen Datenprüfung.",
        "possible_actions": "Sie können sich jederzeit im Onlineportal unter %domain% einloggen und Ihre Bestellung anzeigen.",
        "call_to_action": "Status anzeigen"
      },
      "order_reduced_vat_authorized": {
        "subject": "Ihr Antrag auf ermäßigten Mehrwertsteuersatz für die Bestellung %orderId% wurde genehmigt",
        "explanation": "vielen Dank für Ihre Bestellung. Wir haben die Bestellung für den ermäßigten Mehrwertsteuersatz freigegeben.",
        "possible_actions": "Sie können sich jederzeit im Onlineportal unter %domain% einloggen und Ihre Bestellung anzeigen.",
        "call_to_action": "Status anzeigen"
      },
      "order_reduced_vat_rejected": {
        "subject": "Ihr Antrag auf einen reduzierten Mehrwertsteuersatz für die Bestellung %orderId% wurde nicht genehmigt",
        "explanation": "vielen Dank für Ihre Bestellung. Wir haben Ihre Bestellung überprüft und festgestellt, dass sie nicht für den ermäßigten Mehrwertsteuersatz in Frage kommt. Es wird der normale Mehrwertsteuersatz angewendet.",
        "possible_actions": "Sie können sich jederzeit im Onlineportal unter %domain% einloggen und Ihre Bestellung anzeigen.",
        "call_to_action": "Status anzeigen"
      },
      "chedri_registration": {
        "subject": "Ihre Registrierung im %company%",
        "subject_fiendly": "Deine Registrierung bei %company%",
        "explanation": "<p>Hallo %firstName% %lastName%,</p><p>Vielen Dank für Ihre Registrierung.</p><p>Wir haben erfolgreich ein Testkonto für Sie und Ihr Unternehmen erstellt.</p>",
        "possible_actions": "Nachdem Ihre E-Mail-Adresse bestätigt wurde, können Sie sich mit <strong>%username%</strong> als Benutzername und dem bei der Registrierung ausgewählten Passwort einloggen.",
        "call_to_action": "Bestätigen"
      },
      "invitation": {
        "subject": "Einladung von %company%",
        "explanation": "wir freuen uns sehr, Sie im Namen von %company% zu unserem Online-Design-Portal einzuladen.",
        "explanation_first_name_form": "wir freuen uns sehr, dich im Namen von %company% zu unserem Online-Design-Portal einzuladen.",
        "possible_actions": "",
        "login_data": "Wenn Sie die Einladung annehmen, verwenden Sie folgende Daten, um sich anzumelden: <strong>%username%</strong> als Ihr Benutzername und <strong>%password%</strong> als Ihr Passwort. Bitte achten Sie darauf, Ihr Passwort nach dem Login zu ändern.",
        "login_data_first_name_form": "Wenn du die Einladung annimmst, verwende folgende Daten, um dich anzumelden: <strong>%username%</strong> als dein Benutzername und <strong>%password%</strong> als dein Passwort. Bitte achte darauf, dein Passwort nach dem Login zu ändern.",
        "call_to_action": "Einladung akzeptieren"
      },
      "new_quote": {
        "subject": "Neues Angebot von %company%",
        "explanation": "%company% sendet Ihnen ein Angebot für einen von Ihnen angeforderten Service.",
        "possible_actions": "Sie haben jetzt die Möglichkeit, das Angebot zu prüfen und anzunehmen. Sie können das Angebot auch ablehnen und eine Nachricht hinterlassen, warum.",
        "call_to_action": "Angebot überprüfen"
      },
      "new_invoice": {
        "subject": "Neue Rechnung von %company%",
        "explanation": "%company% sendet Ihnen eine Rechnung für einen von Ihnen angefragten Service. Die Rechnung finden Sie in unserem Onlineportal. Wir haben die Rechnung auch an diese Nachricht angehängt.",
        "explanation_first_name_form": "%company% sendet dir eine Rechnung für einen von dir angefragten Service. Die Rechnung findest du in unserem Onlineportal. Wir haben die Rechnung auch an diese Nachricht angehängt.",
        "possible_actions": "Sie können sich jederzeit im Onlineportal unter %domain% einloggen und Ihre Rechnungen einsehen.",
        "possible_actions_first_name_form": "Du kannst dich jederzeit im Onlineportal unter %domain% einloggen und deine Rechnungen einsehen.",
        "call_to_action": "Rechnung anzeigen"
      },
      "new_sales_commission": {
        "subject": "Neue Provision von %company%.",
        "explanation": "%company% sendet Ihnen eine Provision für einen von Ihnen angefragten Service. Die Provision finden Sie in unserem Onlineportal. Wir haben die Provision auch an diese Nachricht angehängt.",
        "possible_actions": "Sie können sich jederzeit im Onlineportal unter %domain% einloggen und Ihre Provision online anzeigen.",
        "call_to_action": "Provision anzeigen"
      },
      "new_questionnaire": {
        "subject": "%company% möchte, dass Sie einen Fragebogen ausfüllen",
        "explanation": "<p>Hallo %firstName% %lastName%,</p><p>%company% sendet Ihnen einen neuen Fragebogen.</p>",
        "possible_actions": "Sie haben die Möglichkeit, den Fragebogen online einzusehen und auszufüllen.",
        "call_to_action": "Fragebogen ansehen"
      },
      "questionnaire_filled_out": {
        "subject": "Der Fragebogen wurde gerade ausgefüllt",
        "explanation": "<p>Hallo %firstName% %lastName%,</p><p>Der von %targetFirstName% angeforderte Fragebogen wurde gerade ausgefüllt.</p>",
        "possible_actions": "Sie können sich die Antworten in der Lead-Timeline anschauen.",
        "call_to_action": "Lead öffnen"
      },
      "contact_form_cms": {
        "subject": "Neuer Kontakt von %name%",
        "info": "Benachrichtigung über die Einreichung eines Kontaktformulars. Bitte bearbeiten Sie dies umgehend und stellen Sie sicher, dass der Kunde die bestmögliche Unterstützung erhält."
      },
      "contact_form_edition": {
        "subject": "Neuer Kontakt",
        "info": "Benachrichtigung über die Einreichung eines Kontaktformulars. Bitte bearbeiten Sie dies umgehend und stellen Sie sicher, dass der Kunde die bestmögliche Unterstützung erhält.",
        "form_fields": "Ausgefüllte Formularfelder"
      },
      "password_renew": {
        "subject": "Passwort erneuern",
        "explanation": "<p>Hallo %email%,<br><br>Sie haben versucht sich bei %platformName% anzumelden. Ihr Passwort ist abgelaufen. Sie m&uuml;ssen ein neues Passwort vergeben um sich zuk&uuml;nftig anmelden zu können. Klicken Sie auf nachstehenden Link um ein neues Passwort einzugeben.</p>",
        "explanation_first_name_form": "<p>Hallo %email%,<br><br>du hast versucht dich bei %platformName% anzumelden. Dein Passwort ist abgelaufen. Du musst ein neues Passwort vergeben um dich zuk&uuml;nftig anmelden zu können. Klicke auf nachstehenden Link um ein neues Passwort einzugeben.</p>",
        "possible_actions": "Sie haben die Möglichkeit ein neues Passwort festzulegen. Klicken Sie einfach auf die Schaltfläche.",
        "possible_actions_first_name_form": "Du hast die Möglichkeit ein neues Passwort festzulegen. Klicke einfach auf die Schaltfläche.",
        "call_to_action": "Neues Passwort festlegen"
      },
      "password_reset": {
        "subject": "Passwort zurücksetzen",
        "explanation": "<p>Guten Tag %email%,</p><br /><p>über unsere Plattform wurde soeben ein neues Passwort bei %platformName% angefordert. Sofern Sie diese Anfrage gestellt haben, klicken Sie auf den unten stehenden Link.</p>",
        "explanation_first_name_form": "<p>Hallo %email%,</p><br /><p>über unsere Plattform wurde soeben ein neues Passwort bei %platformName% angefordert. Sofern du diese Anfrage gestellt hast, klicke auf den unten stehenden Link.</p>",
        "possible_actions": "Sie haben die Möglichkeit ein neues Passwort festzulegen. Klicken Sie einfach auf die Schaltfläche.",
        "possible_actions_first_name_form": "Du hast die Möglichkeit ein neues Passwort festzulegen. Klicke einfach auf die Schaltfläche.",
        "call_to_action": "Neues Passwort festlegen"
      },
      "message": {
        "explanation": "%company% hat Ihnen eine Nachricht gesendet",
        "possible_actions": "Sie können auf diese Nachricht antworten, indem Sie auf diese Schaltfläche klicken:",
        "call_to_action": "Antworten"
      },
      "customerMessage": {
        "explanation": "Kunde hat Ihnen eine Nachricht geschickt"
      },
      "lead_comment_mention": {
        "subject": "Sie wurden in der %title% Lead erwähnt",
        "explanation": "%creator% hat Sie in einem Kommentar erwähnt.",
        "call_to_action": "Lead öffnen"
      },
      "lead_assignee_changed": {
        "subject_assigned": "Sie sind nun dem Lead '%title%' zugewiesen",
        "subject_unassigned": "Sie sind nicht mehr dem Lead '%title%' zugewiesen",
        "explanation_assigned": "Sie sind nun dem Lead '%title%' zugewiesen. Vorheriger Bearbeiter war %oldAssignee%.",
        "explanation_unassigned": "Sie sind nicht mehr dem Lead '%title%' zugewiesen. Neuer Bearbeiter ist %newAssignee%.",
        "call_to_action": "Lead öffnen"
      },
      "bank_data_reminder": {
        "subject": "Wir benötigen Ihre Bankdaten für Ihre Bestellung",
        "explanation": "Sie haben Leistungen gebucht, für die Bankdaten benötigt werden.",
        "possible_actions": "Bitte gehen Sie zur Bestellung und geben Sie die fehlenden Daten ein.",
        "call_to_action": "Bankdaten eingeben"
      },
      "order_approved": {
        "subject": "Ihre Bestellung bei %company% wurde gerade genehmigt",
        "explanation": "wir freuen uns, Ihnen mitteilen zu können, dass Ihre Bestellung %orderId% genehmigt wurde.",
        "possible_actions": "Möglicherweise möchten Sie zu Ihrer Bestellung gehen, um die Details anzuzeigen.",
        "call_to_action": "Bestellung anzeigen"
      },
      "sales_contract_uploaded": {
        "subject": "Der Kaufvertrag für Ihre Bestellung ist jetzt verfügbar"
      },
      "order_cancelled": {
        "subject": "Ihre Bestellung bei %company% wurde storniert",
        "explanation": "es tut uns leid, aber wir mussten Ihre Bestellung %orderId% stornieren.",
        "possible_actions": "Bitte gehen Sie zu Ihrer Bestellung, um die Details anzuzeigen.",
        "call_to_action": "Bestellung anzeigen"
      },
      "payment_instructions": {
        "subject": "Zahlungsinformationen für Ihre Bestellung bei %company%",
        "explanation": "Im Folgenden erhalten Sie die Zahlungsinformationen für Ihre Bestellung %orderId%:",
        "possible_actions": "Bitte gehen Sie zu Ihrer Bestellung, um die Details anzuzeigen.",
        "call_to_action": "Bestellung anzeigen"
      },
      "pickup_invite": {
        "subject": "Ihre Bestellung ist zur Abholung bereit",
        "explanation": "Ihre Bestellung mit der %orderId% ist zur Abholung bereit.",
        "possible_actions": "Bitte gehen Sie zu Ihrer Bestellung, um die Details anzuzeigen.",
        "call_to_action": "Bestellung anzeigen"
      },
      "order_picked_up": {
        "subject": "Danke für Ihre Bestellung"
      },
      "request_plugin_activation": {
        "subject": "Plugin-Aktivierung angefordert",
        "explanation": "<p>Hallo %firstName% %lastName%,</p><p>%creatorFirstName% %creatorLastName% möchte das Plugin <strong>%pluginName%</strong> für das Projekt %platformName% aktivieren.</p>",
        "possible_actions": "Sie können sich einloggen und das Aktivierungsformular verwenden, indem Sie den folgenden Button drücken.",
        "call_to_action": "Aktiviere das Plugin"
      },
      "plugin_activated": {
        "subject": "Plugin aktiviert",
        "explanation": "<p>Hallo %firstName% %lastName%,</p><p>Wir möchten Sie darüber informieren, dass der %pluginName% für das %platformName% Projekt aktiviert wurde.</p>",
        "call_to_action": "Sehen Sie es in Aktion"
      },
      "lead_data": {
        "subject": "Neuer Lead: %title%",
        "explanation": "",
        "call_to_action": "Lead anzeigen"
      },
      "order": {
        "item_sales": {
          "title": "Ihre Bestellung",
          "explanation": "nachfolgend sind die Daten zu Ihrer Bestellung nochmals aufgelistet.",
          "subject": "Ihre Bestellung bei %company%",
          "possible_actions": "Sie finden alle Informationen zu Ihrer Bestellung auch in Ihrem Kundenkonto.",
          "call_to_action": "Im Kundenkonto ansehen"
        }
      },
      "rent_sync_vamoso_error": {
        "subject": "Bestellung #%orderId%: Fehler in Rent Sync for Vamoso Plugin: %errorType%",
        "explanation": "Leider konnte unser Plugin keine Aufgabe in Ihrem Vamoso-System ausführen. Bitte überprüfen Sie den Buchungsstatus manuell. <br/>Fehlertyp: %errorType% <br/>Fehlermeldung: %errorText%"
      },
      "lead_deadline_reminder": {
        "subject": "Sie sind Leads zugeordnet, die morgen fällig sind",
        "explanation": "Hier finden Sie eine Liste der Ihnen zugewiesenen Leads. Diese Leads sind noch offen und morgen fällig.",
        "explanation_first_name_form": "Hier findest du eine Liste der dir zugewiesenen Leads. Diese Leads sind noch offen und morgen fällig."
      },
      "chat_history": {
        "subject": "Dein Chat-Verlauf",
        "explanation": "Danke, dass Sie uns kontaktiert haben. Im Anhang finden Sie Ihren Chat-Verlauf mit %firstName% %lastName%. Ich hoffe, wir konnten Ihnen bei all Ihren Fragen oder Problemen helfen. Bei weiteren Fragen können Sie sich jederzeit gerne wieder an uns wenden.",
        "greatingchat_history": "Chat-Verlauf",
        "greating": "Danke, dass Sie den Chat von %platformName% genutzt haben. Ihr Chat-Partner war %firstName% %lastName% Im Folgenden sehen Sie den Chat-Verlauf vom %date%.",
        "adaption": "Ich hoffe, wir konnten Ihnen bei all Ihren Fragen oder Problemen helfen. Bei weiteren Fragen können Sie sich jederzeit gerne wieder an uns wenden.",
        "privacy_policy": "Unsere Datenschutzerklärung finden Sie unter <a href=\"%link%\" target=\"_blank\">'%link%</a>",
        "call_to_action": "Besuchen Sie uns wieder"
      },
      "lead_pool_notification": {
        "subject": "Neuer Lead im Pool: %title%",
        "explanation": "Ein neuer Lead ist im System verfügbar. Wir haben noch keinen Mitarbeiter zugewiesen. Klicke auf die Schaltfläche unten, um Details anzuzeigen.",
        "call_to_action": "Lead öffnen"
      },
      "lead_escalation_level1": {
        "subject": "[LEVEL1] %name%",
        "explanation": "Sie erhalten diese E-Mail, weil einer oder mehrere Leads die in der Regel %name% festgelegte erste Eskalationsstufe erreicht haben. Bitte überprüfen Sie die Leads, um festzustellen, was zu tun ist."
      },
      "lead_escalation_level2": {
        "subject": "[LEVEL2] %name%",
        "explanation": "Sie erhalten diese E-Mail, weil einer oder mehrere Leads die in der Regel %name% festgelegte zweite Eskalationsstufe erreicht haben. Bitte überprüfen Sie die Leads und versuchen Sie, Ihre Teams zu motivieren, sich mehr anzustrengen."
      },
      "campaign_budget_auth_request": {
        "subject": "Warnung: Kampagnenbudget überschritten.",
        "explanation": "das Kampagnenbudget wurde überschritten.",
        "call_to_action": "Bestellung anzeigen"
      },
      "campaign_budget_auth_request_unsuccessful": {
        "subject": "Warnung: Problem mit der Autorisierung des Kampagnenbudgets.",
        "explanation": "es wurde ein Problem mit der Autorisierung des Kampagnenbudgets festgestellt.",
        "call_to_action": "Bestellung anzeigen"
      },
      "user_budget_auth_request": {
        "subject": "Warnung: Benutzerbudget überschritten.",
        "explanation": "das Benutzerbudget wurde überschritten.",
        "call_to_action": "Bestellung anzeigen",
      },
      "user_budget_auth_request_unsuccessful": {
        "subject": "Warnung: Problem mit der Autorisierung des Benutzerbudgets",
        "explanation": "es wurde ein Problem mit der Autorisierung des Benutzerbudgets festgestellt.",
        "call_to_action": "Bestellung anzeigen"
      },
      "order_auth_changes_requested": {
        "subject": "Info: Änderungen angefordert",
        "explanation": "",
        "call_to_action": "Bestellung anzeigen"
      },
      "order_auth_changes_implemented": {
        "subject": "Info: Änderungen vorgenommen",
        "explanation": "",
        "call_to_action": "Bestellung anzeigen"
      },
      "manage_notification": "Email-Benachrichtigung verwalten",
      "unsubscribe": "Abbestellen",
      "confidentiality_notice": "Vertraulichkeitshinweis: Diese E-Mail ist ausschließlich für den angegebenen Empfänger bestimmt und könnte vertrauliche und/oder rechtlich geschützte Informationen enthalten. Sollten Sie nicht der richtige Empfänger sein oder diese E-Mail irrtümlich erhalten haben, so informieren Sie bitte sofort den Absender und vernichten Sie diese E-Mail. Das unerlaubte Kopieren sowie die unbefugte Weitergabe dieser E-Mail sind nicht gestattet.",
      "no_legal_binding_notice": "Diese Nachricht dient lediglich dem Austausch von Informationen und entfaltet keine rechtliche Bindungswirkung. Aufgrund der leichten Manipulierbarkeit von E-Mails können wir keine Haftung für den Inhalt übernehmen.",
      "invoice_due_reminder": {
        "subject": "Zahlungserinnerung",
        "explanation": "leider konnten wir bislang keinen Zahlungseingang für folgende Rechnung feststellen.",
        "explanation_first_name_form": "leider konnten wir bislang keinen Zahlungseingang für folgende Rechnung feststellen.",
        "request_payment": "Zur Vermeidung weiterer Kosten bitten wir um umgehende Zahlung des Gesamtbetrages. Bitte nutzen Sie für die Zahlung dieser Rechnung die nachfolgende Bankverbindung:",
        "request_payment_first_name_form": "Zur Vermeidung weiterer Kosten bitten wir um umgehende Zahlung des Gesamtbetrages. Bitte nutze für die Zahlung dieser Rechnung die nachfolgende Bankverbindung:",
        "contact": "Bei Fragen zu dieser Mahnung kontaktieren Sie uns bitte unter der E-Mail %email% oder telefonisch unter der Hotline %phone%.",
        "contact_first_name_form": "Bei Fragen zu dieser Mahnung kontaktiere uns bitte unter der E-Mail %email% oder telefonisch unter der Hotline %phone%.",
        "bank_account": "Bankverbindung",
        "bank_name": "Bankname",
        "account_holder": "Kontoinhaber",
        "iban": "IBAN",
        "swift_bic": "SWIFT/BIC",
        "bank_code_number": "Bankleitzahl",
        "account_number": "Kontonummer",
        "invoice_number": "Rechnungs-Nr.:",
      },
      "last_seen_reminder":
        {
          "subject": "Zuletzt gesehen Erinnerung",
          "user_not_seen": "Der Benutzer '%clientUsername%' wurde seit %daysNotSeen% Tagen nicht gesehen.",
          "contact_user": "Bitte kontaktieren Sie diesen, um sicherzustellen, dass alles in Ordnung ist.",
          "contact_user_first_name_form": "Bitte kontaktiere diesen, um sicherzustellen, dass alles in Ordnung ist.",
          "customer_profil": "Kundenprofil",
        },
      "order_foreign_status": {
        "do_contact_support": {
          "subject": "Bestellung - Bitte kontaktieren Sie den Kundensupport",
          "explanation": "vielen Dank für Ihre Bestellung. Wir haben eine Frage zu Ihrer Bestellung. Bitte kontaktieren Sie unseren Kundensupport.",
          "explanation_first_name_form": "vielen Dank für deine Bestellung. Wir haben eine Frage zu deiner Bestellung. Bitte kontaktiere unseren Kundensupport.",
          "possible_actions": "-",
          "contact": "Kontakt",
          "phone": "Telefon:",
          "email": "E-Mail:",
          "call_to_action": "-"
        },
        "in_delivery": {
          "subject": "Die Bestellung wird gerade versandt",
          "explanation": "ihre Bestellung wurde produziert und an den Kurier zur Auslieferung übergeben.",
          "explanation_first_name_form": "deine Bestellung wurde produziert und an den Kurier zur Auslieferung übergeben.",
          "possible_actions_tracking": "Sie können die Lieferung auf der Website des Kurierdienstes verfolgen.",
          "possible_actions_tracking_first_name_form": "Du kannst die Lieferung auf der Website des Kurierdienstes verfolgen.",
          "possible_actions": "Sie können sich jederzeit im Onlineportal unter %domain% einloggen und Ihre Bestellung anzeigen.",
          "possible_actions_first_name_form": "Du kannst dich jederzeit im Onlineportal unter %domain% einloggen und deine Bestellung anzeigen.",
          "call_to_action": "Bestelldetails",
          "tracking_numbers": "Verfolgungsnummern",
        },
        "in_production": {
          "subject": "Die Bestellung wird gerade produziert",
          "explanation": "vielen Dank für Ihre Bestellung. Wir haben haben Ihre Bestellung in Auftrag gegeben. Diese wird gerade produziert.",
          "explanation_first_name_form": "vielen Dank für deine Bestellung. Wir haben haben deine Bestellung in Auftrag gegeben. Diese wird gerade produziert.",
          "possible_actions": "Sie können sich jederzeit im Onlineportal unter %domain% einloggen und Ihre Bestellung anzeigen.",
          "possible_actions_first_name_form": "Du kannst dich jederzeit im Onlineportal unter %domain% einloggen und deine Bestellung anzeigen.",
          "call_to_action": "Bestelldetails"
        },
        "invalid_print_data": {
          "subject": "Bestellung - ungültige Druckdaten",
          "explanation": "Bei der Bestellung wurden ungültige Druckdaten gefunden.",
          "call_to_action": "Bestelldetails"
        },
        "missing_print_data": {
          "subject": "Bestellung - fehlende Druckdaten",
          "explanation": "Bei der Bestellung wurden fehlende Druckdaten festgestellt.",
          "call_to_action": "Bestelldetails"
        },
        "order_canceled": {
          "subject": "Die Bestellung wurde storniert",
          "explanation": "ihre Bestellung wurde erfolgreich storniert. Es tut uns leid, dass Sie Ihre Bestellung stornieren mussten und wir hoffen, dass wir Ihnen in Zukunft wieder behilflich sein können.",
          "explanation_first_name_form": "deine Bestellung wurde erfolgreich storniert. Es tut uns leid, dass du deine Bestellung stornieren musstest und wir hoffen, dass wir dir in Zukunft wieder behilflich sein können.",
          "possible_actions": "Sie können sich jederzeit im Onlineportal unter %domain% einloggen und Ihre Bestellung anzeigen.",
          "possible_actions_first_name_form": "Du kannst dich jederzeit im Onlineportal unter %domain% einloggen und deine Bestellung anzeigen.",
          "call_to_action": "Bestelldetails"
        },
        "payment_pending": {
          "subject": "Bestellung - Zahlung ausstehend",
          "explanation": "wir konnten noch keinen Zahlungseingang zu Ihrer Bestellung feststellen.",
          "explanation_first_name_form": "wir konnten noch keinen Zahlungseingang zu deiner Bestellung feststellen.",
          "possible_actions": "Sie können sich jederzeit im Onlineportal unter %domain% einloggen und Ihre Bestellung anzeigen.",
          "possible_actions_first_name_form": "Du kannst dich jederzeit im Onlineportal unter %domain% einloggen und deine Bestellung anzeigen.",
          "call_to_action": "Bestelldetails"
        }
      },
      "contact_form_cms_confirm": {
        "subject": "Bitte bestätigen Sie Ihre E-Mail",
        "explanation": "<p>Ihre E-Mail wurde in das Kontaktformular auf unserer Website eingegeben.</p><p>Wenn Sie unser Kontaktformular ausgefüllt haben, klicken Sie bitte auf die Schaltfläche Bestätigen.</p><p>Andernfalls ignorieren Sie diese Nachricht einfach.</p>",
        "call_to_action": "Bestätigen"
      },
      "contact_form_edition_confirm": {
        "subject": "Bitte bestätigen Sie Ihre E-Mail",
        "explanation": "<p>Ihre E-Mail-Adresse wurde in das Kontaktformular auf unserer Website eingegeben.</p><p>Wenn Sie unser Kontaktformular ausgefüllt haben, klicken Sie bitte auf die Schaltfläche Bestätigen.</p><p>Andernfalls ignorieren Sie diese Nachricht einfach.</p>",
        "call_to_action": "Bestätigen"
      },
      "order_content_check_status": {
        "subject": "die Inhaltsprüfung für den Auftrag %orderId% wurde %status%",
        "explanation": "danke für Ihre Bestellung. Wir haben die Bestellung nach der Inhaltsprüfung %status% .",
        "possible_actions": "Sie können Ihre Bestellung aufrufen, um die Details einzusehen.",
        "call_to_action": "Bestellung ansehen",
        "reason": "Grund"
      },
      "lead_follow_up_reminder": {
        "subject": "Bitte prüfen Sie die zugewiesenen Aufgaben",
        "explanation": "hier finden Sie eine Liste der Ihnen zugewiesenen Aufgaben. Diese Aufgaben sind noch offen.",
        "explanation_first_name_form": "hier findest du eine Liste der dir zugewiesenen Aufgaben. Diese Aufgaben sind noch offen."
      },
      "client_domain_authorization_code": {
        "subject": "Domain-Autorisierungscode",
        "explanation": "Nach der Kündigung des Abonnements von %itemName% für die Domain %clientDomain% stellen wir Ihnen einen Autorisierungscode zur Verfügung, mit dem Sie Ihre Domain zu einem anderen Registrar übertragen können.",
        "explanation_authorization_code": "Der Autorisierungscode lautet %authorizationCode%",
      }
    },
    "contact_form_cms_confirm": {
      "success": {
        "heading": "Ihre Anfrage wurde gesendet",
        "body": "<p>Vielen Dank, dass Sie uns kontaktiert haben!</p><p>Wir haben Ihre Anfrage erhalten und werden uns schnellstmöglich mit Ihnen in Verbindung setzen.</p>"
      },
      "already_confirmed": {
        "heading": "Dieser Antrag wurde bereits bestätigt",
        "body": "<p>Wir haben Ihre Anfrage erhalten und werden uns schnellstmöglich mit Ihnen in Verbindung setzen.</p>"
      },
      "not_found": {
        "heading": "Nicht gefunden",
        "body": "<p>Bitte vergewissern Sie sich, dass Sie auf die Schaltfläche in unserer E-Mail geklickt haben, um hierher zu gelangen.</p>"
      }
    },
    "contact_form_edition_confirm": {
      "success": {
        "heading": "Ihre Anfrage wurde gesendet",
        "body": "<p>Vielen Dank, dass Sie uns kontaktiert haben!</p><p>Wir haben Ihre Anfrage erhalten und werden uns schnellstmöglich mit Ihnen in Verbindung setzen.</p>"
      },
      "already_sent": {
        "heading": "Dieser Antrag wurde bereits bestätigt",
        "body": "<p>Wir haben Ihre Anfrage erhalten und werden uns schnellstmöglich mit Ihnen in Verbindung setzen.</p>"
      },
      "not_found": {
        "heading": "Nicht gefunden",
        "body": "<p>Bitte vergewissern Sie sich, dass Sie auf die Schaltfläche in unserer E-Mail geklickt haben, um hierher zu gelangen.</p>"
      },
      "expired": {
        "heading": "Code abgelaufen",
        "body": "<p>Der Bestätigungslink war nur 2 Tage lang gültig. Bitte füllen Sie das Kontaktformular erneut aus.</p>"
      }
    },
    "formGroup": {
      "basic_information": "Grundinformationen",
      "contact_person": "Ansprechpartner",
      "address_information": "Adresse",
      "address_settings": "Adresseinstellungen",
      "bank_account": "Bankverbindung",
      "company_information": "Firmeninformation",
      "user_roles": "Benutzerrollen",
      "subscription_roles": "Benutzerabo",
      "opening_hours": "Öffnungszeiten",
      "position_on_map": "Position auf der Karte",
      "white_label_parent": "Vorläufer",
      "user_teams": "Benutzerteams",
      "contact_data": "Kontaktdaten",
      "permissions": "Berechtigungen",
      "person_information": "Benutzerinformationen",
    },
    "hints": {
      "small_hint_for_you": "Kleiner Tipp für dich",
      "task_is_automated": "Diese Aufgabe ist automatisiert. Sie müssen warten, bis die Automatisierungsaufgabe läuft. Danach werden wir es als abgeschlossen für Sie markieren.",
      "please_complete_previous_tasks": "You can't edit this task now. Please take care of the previous tasks first.",
      "companyLogoNew": "<p>Sie haben Ihr Logo noch nicht hochgeladen. Um eines zu laden, einfach per Drag & Drop ein Bild (jpeg oder png) auf diese Seite.</p>",
      "companyLogoChange": "<p>Dies ist das Logo Sie hochgeladen haben. Um es zu ändern, einfach per Drag & Drop ein Bild (jpeg oder png) auf diese Seite.</p>)",
      "companyFinancial": "<div><p>Bitte füllen Sie die für die Rechnungsstellung benötigten Informationen aus</p><p>Die Bankdaten, die Sie hier ausfüllen, werden in footer auf Ihre Angebote und Rechnungen angezeigt</p></div>",
      "companyPaymentInfo": "<p>Diese Informationen werden auf jeder Rechnung, die Sie erstellen, angezeigt.</p>"
    },
    "vendors": {
      "association": "Assoziation mit Clientdaten",
      "vendor_api": "Servicename",
      "pricing": "Preisfindung",
      "operating_mode": "Betriebsart",
      "price_update_method": "Preisaktualisierungsmethode",
      "order_processing": "Bestellabwicklung",
      "order_method": "Bestellmethode",
      "order_email": "E-Mail Adresse für Bestellungen",
      "phone_number": "Telefonnummer",
      "web_shop_url": "Webshop URL",
      "trial": "trial",
      "live": "live",
      "prices_by_vendor": "Preise werden vom Anbieter verwaltet",
      "prices_by_employee": "Preise werden vom Mitarbeiter geprüft und gepflegt",
      "prices_by_api": "Preise werden über eine API aktualisiert",
      "notified_by_email": "Wird über eingehende Bestellungen per E-Mail benachrichtigt",
      "accepts_only_email": "Aktzeptiert nur Bestellungen per E-Mail",
      "use_webshop": "Bestellungen per Webshop",
      "order_by_api": "Bestellungen per API",
      "order_by_api_and_webshop": "Bestellungen per API oder Webshop"
    },
    "client_account": "Kundenkonto",
    "deleted_campaigns": "Gelöschte Kampagnen",
    "deleted_editions": "Gelöschte Editionen",
    "deleted_folders": "Gelöschte Ordner",
    "deleted_media": "Gelöschte Medien",
    "root": "Stammverzeichnis",
    "trash_bin": "Papierkorb",
    "delete_trash_bin": "Papierkorb leeren",
    "pls_wait": "Bitte warten. Die gewünschte Aktion wird ausgeführt.",
    "go_back": "Zurück",
    "restore": "Wiederherstellen",
    "archived_campaigns": "Archivierte Kampagnen",
    "load_all_campaigns": "Alle Kampagnen laden",
    "load_creacheck_campaigns": "Creacheck Kampagnen laden",
    "load_flyeralarm_campaigns": "Flyeralarm Kampagnen laden",
    "load_designgenie_campaigns": "DesignGenie Kampagnen laden",
    "archived_editions": "Archivierte Editionen",
    "sorting_name": "Name",
    "sorting_date_ASC": "Älteste zuerst",
    "sorting_date_DESC": "Neueste zuerst",
    "sorting_owner": "Firma",
    "sorting_creator": "Erstellt von",
    "campaign_title": "Kampagne",
    "dataproof": "Datenprüfung",
    "not_ordered": "nicht bestellt",
    "supplier_order": "Lieferantenbestellung",
    "change_of_address": "Adressänderung",
    "not_available": "nicht vorhanden",
    "selected_shipping_method": "Ausgewählte Versandart",
    "ordered_items": "Bestellte Artikel",
    "standard": "Standard",
    "express": "Express",
    "aws_iframe_hosting": "AWS Iframe Hosting",
    "aws_hosting_html_file_for_iframe_downloaded": "AWS Hosting HTML-Datei für iframe mit Titel: %title%",
    "lead_stage_change_confirm_title": "Lead-Status ändern",
    "lead_stage_change_confirm_body": "Möchten Sie den Status des Leads wirklich ändern?",
    "iframe_implementation_file": "Iframe Implementierungsdatei",
    "iframe_implementation_file_download_failed": "Iframe Implementierungsdatei konnte nicht heruntergeladen werden: %error%",
    "lead_stage_change_error_domain_hosting_in_progress": "Die Leitung kann nicht geändert werden, wenn die aktuelle Phase läuft oder wenn die Genehmigung nicht genehmigt ist.",
    "lead_stage_change_error_domain_hosting_command_to_start_pipeline_has_been_sent": "Die Leitung kann nicht geändert werden, wenn die aktuelle Phase läuft oder wenn die Genehmigung nicht genehmigt ist.",
    "allowed_domain_already_exists": "Die Domain %domain% ist bereits in der Liste der erlaubten Domains enthalten.",
    "allowed_domain_domain_name_should_not_be_empty": "Der Domain-Name darf nicht leer sein.",
    "allowed_domains_title": "Erlaubte Domains",
    "allowed_domains_description": "Hier können Sie Domains hinzufügen, die für die Implementierung von Iframes verwendet werden dürfen.",
    "allowed_domains_add": "Domain hinzufügen",
    "allowed_domains_domain_name": "Domain-Name",
    "allowed_domains_default_src": "Default-Src",
    "allowed_domains_script_src": "Script-Src",
    "allowed_domains_connect_src": "Connect-Src",
    "allowed_domains_style_src": "Style-Src",
    "allowed_domains_img_src": "Img-Src",
    "allowed_domains_font_src": "Font-Src",
    "allowed_domains_frame_src": "Frame-Src",
    "created_at": "Erstellt am",
    "created_by": "Erstellt von",
    "overnight": "Overnight",
    "create_printData": "Druckdaten erstellen",
    "created_new_printData": "Neue Druckdaten wurden erstellt",
    "uploaded_printData_successful": "Druckdaten wurden erfolgreich hochgeladen",
    "no_printData": "Keine Druckdaten",
    "no_images": "Keine Bilddateien vorhanden",
    "not_the_original_printFile": "Hinweis: Dies ist nicht die Original-Druckdatei",
    "printDataFiles": "Druckdatendatei(en)",
    "image_files": "Bilddateien",
    "customer_comment": "Kundenbemerkung",
    "actions_": " Aktionen",
    "import_success": "Ihr Import wurde ausgeführt",
    "import_rejected": "Ihr Import wurde abgebrochen",
    "import_exec": "Ihr Import wird ausgeführt",
    "import_successRE": "Ihr Import wurde zurückgesetzt",
    "import_rejectedRE": "Der Vorgang wurde abgebrochen",
    "import_execRE": "Ihr Import wird zurückgesetzt",
    "choice_csv": "Bitte wählen Sie eine CSV Datei",
    "csv_content": "CSV Inhalt",
    "warning_and_notes": "Warnungen und Hinweise",
    "start_remove": "Entfernen starten",
    "reverse_remove": "Entfernen rückgängig machen",
    "user_import_success": "Import erfolgreich",
    "user_duplicate_import": "Doppelter Eintrag gefunden",
    "start_import": "Import starten",
    "reverse_import": "Import rückgängig machen",
    "upload_local_changes": "Lokale Änderungen hochladen",
    "dataproof_master_copies_upload": "Hier kann mit [Upload] die lokal nachbearbeitet Vorlage/Durckdatendatei(en) hochgeladen werden.",
    "edit_edition": "Werbemittel bearbeiten",
    "dataproof_master_copies_recreate": "Hier kann mit [Druckdaten erstellen] basierend auf dem Original ein erneutes Erstellen der Vorlage/Druckdatendatei(en) angestossen oder die Vorlage/Druckdatendatei(en) kann mit [Bearbeiten] lokal nachbearbeitet werden.",
    "hint": "Hinweis",
    "dataproof_hint_master_copies_upload": "Damit die Änderung nach Abschluss der Nachbearbeitung wirksam wird, muss die lokal geänderte Vorlage/Druckdatendatei(en), mit [Upload] hochgeladen werden.",
    "edit_": "Bearbeiten",
    "send_invoice": "Rechnung senden",
    "sended_invoice": "Die Rechnung wurde gesendet an",
    "send_email_tracking": "Emailversand Sendungsverfolgung",
    "sended_email": "Die Email wurde gesendet!",
    "tracking": "Sendungsverfolgung",
    "send_tracking_to_customer": "Sendungsverfolgung an Kunden senden",
    "to_the_product": "Zum Produkt",
    "order_sended": "Die Bestellung wurde gesendet!",
    "failure_when_ordering": "Bei der Bestellung ist ein Fehler aufgetreten!",
    "please_create_orderhistory_email": "Die Bestellung wurde per Email gesendet! Bitte legen Sie einen Bestellverlauf an!",
    "new_status_set": "Der neue Status wurde gesetzt!",
    "change_of_status": "Statusänderung",
    "statusQuery": "Statusabfrage",
    "totalStatus": "Gesamtstatus:",
    "printData": "Druckdaten",
    "current_printdata_sended": "Die aktuellen Druckdaten wurden gesendet!",
    "orderHistory": "Bestellverlauf",
    "orderHistory_created": "Der Bestellverlauf wurde angelegt!",
    "order_created": "Die Bestellung wurde erfolgreich angelegt!",
    "enter_trackingLink": "Trackinglink eingeben",
    "query_orderStatus": "Bestellstatus abfragen",
    "foreignId": "Fremdbestellnr.",
    "orderStatus": "Bestellstatus",
    "orderDate": "Bestelldatum",
    "options_": "Optionen",
    "type_": "Art",
    "comment_": "Kommentar",
    "orderOptions": "Bestellmöglichkeiten",
    "ordering_by_webshop": "Bestellung per Webshop",
    "ordering_by_email": "Bestellung per Email",
    "info_to_the_emailOrder": "Zusatzinformationen zur Emailbestellung",
    "add_or_edit_foreignId": "Fremdbestellnummer hinzufügen oder editieren",
    "create_trackingLink": "Link zur Sendungsverfolgung anlegen",
    "campaign": {
      "copy_edition": "Duplizieren",
      "created_by": "Erstellt von:",
      "no_edition": "Sie haben noch keine Werbemittel bearbeitet!",
      "configuration": "Allgemeine Optionen",
      "select_all": "Alle Produkte",
      "budget": "Kampagnenbudget",
      "access": "Zugriffsrechte",
      "inactive": "Kampagne sperren",
      "delete": "Kampagne löschen",
      "transfer_title": "Übertragen Sie die Kampagne an einen anderen Benutzer",
      "campaign_owner": "Kampagnenbesitzer",
      "campaign_creator": "Kampagnenersteller",
      "change_owner": "Kampagnenbesitzer ändern",
      "change_creator": "Kampagnenersteller ändern",
      "order_all": "Ganze Kampagne bestellen",
      "share": "Kampagne teilen",
      "archive": "Kampagne archivieren",
      "recent_editions": "Zuletzt bearbeitet",
      "marked_editions": "Markierte Werbemittel",
      "marked_campaigns": "Markierte Kampagnen",
      "own_campaigns": "Eigene Kampagnen",
      "shared_editions": "Geteilte Werbemittel",
      "last_edited": "Bearbeitet am:",
      "generated": "Erstellt am:",
      "edition_edit": "Editor starten",
      "edition_item": "Gewähltes Produkt",
      "submission_item": "Ausgewählte Einreichung",
      "edition_configuration": "2. Einstellungen",
      "edition_order": "In den Warenkorb",
      "edition_reorder": "Nachbestellen",
      "ordered": "Bestellt!",
      "edition_delete": "Löschen",
      "edition_preview": "Vorschau",
      "edition_edit_alt": "Bearbeiten",
      "edition_error_empty": "Sie haben %s unbearbeitete Seiten",
      "submissions": "Vorlagen",
      "new": "Neue Kampagne anlegen",
      "back_to_list": "Alle Kampagnen",
      "new_edition": "Neues Werbemittel anlegen",
      "settings": "Kampagne verwalten",
      "campaign_archived": "Ihre Kampagne wurde archiviert",
      "successfully_archived": "Archivierung erfolgreich",
      "save_settings": "Speichern",
      "cancel_settings": "Abbrechen",
      "title": "Kampagnen-Name",
      "edition_title": "Bezeichnung",
      "publisher": "Angabe des Herausgebers",
      "creator": "Kampagne erstellt von:",
      "editions": "Werbemittel",
      "edition": "Werbemittel",
      "sidebar": "Kampagnen",
      "mark_edition": "Werbemittel markieren",
      "unmark_edition": "Markierung entfernen",
      "campaign_title_valid": "Bitte vergeben Sie eine Bezeichnung für Ihre Kampagne.",
      "campaign_publisher_valid": "Bitte tragen Sie Ihre Urheberangaben ein.",
      "choose_company_to_load_campaign": "Um die Kampagnen zu laden, wählen Sie bitte ein Unternehmen oder klicken Sie auf den Button 'Alle Kampagnen laden'",
      "no_product_selected": "Kein Produkt ausgewählt",
      "new_edition_title": "Neue Edition",
      "added_to_cart": "Ihre Kampagne wurde in den Warenkorb gelegt!",
      "edition_add_to_cart_title": "Produkt im Warenkorb",
      "edition_add_to_cart_button_label": "Prima",
      "edition_add_to_cart_label": "Zum Warenkorb",
      "edition_added_to_cart_message": " wurde Ihrem Warenkorb hinzugefügt.",
      "edit_disabled": "Die Bearbeitung der Edition wurde Aufgrund eines Templateupdates kurzfristig deaktiviert.",
      "export_image": "Bild exportieren ",
      "unknown": "unbekannt",
      "already_ordered": "Diese Edition wurde bereits bestellt und kann nicht mehr bearbeitet werden. Sie können die Edition jedoch duplizieren und diese bearbeiten. Siehe Menü ",
      "modal_tabs": {
        "default_edition": "Allgemeine Werbemittel",
        "edition_from_submission": "Werbemittel aus Vorlage"
      }
    },
    "uom": {
      "piece": "Stück",
      "gram": "Gramm",
      "kilogram": "Kilogramm",
      "running_meter": "Laufender Meter",
      "liter": "Liter",
      "package": "Paket",
      "kilometer": "Kilometer"
    },
    "uom_symbole": {
      "piece": "",
      "gram": "g",
      "kilogram": "kg",
      "running_meter": "lfm",
      "liter": "l",
      "package": "Pkt.",
      "kilometer": "km"
    },
    "pipelineViews": {
      "self": "Mir zugewiesen",
      "unassigned": "Ohne Zuweisung",
      "pastdeadline": "Deadline überschritten",
      "duetoday": "Heute fällig"
    },
    "leadPriority": {
      "critical": "Kritisch",
      "high": "Hoch",
      "normal": "Normal",
      "low": "Niedrig"
    },
    "orderTypes": {
      "item_sales": "Verkauf",
      "car_rent": "Autovermietung",
      "car_sales": "Autoverkauf",
      "car_service": "Werkstatttermin"
    },
    "genderOptions": {
      "male": "Männlich",
      "female": "Weiblich"
    },
    "salutationOptions": {
      "mr": "Herr",
      "mrs": "Frau"
    },
    "team_service": "Serviceteam",
    "team_dispo": "Dispositionsteam",
    "team_rent": "Vermietung team",
    "team_sales": "Verkaufsteam",
    "car_documents": "Fahrzeugdokumente",
    "nav_module_chip": "Navi-Modul / Chip",
    "safety_vest": "Warnweste",
    "parking_disk": "Parkscheibe",
    "ice_scraper": "Eiskratzer",
    "key_supplement_heating_taxis": "Schlüssel-Zusatz (Standheizung/Taxischild)",
    "shelf_blind": "Hutablage / Rollo",
    "onboard_folder": "Bordmappe",
    "first_aid_kit": "Verbandskasten",
    "warning_triangle": "Warndreieck",
    "tirefit_spare_wheel": "Tirefit / Ersatzrad",
    "handover_damage": {
      "front": "Vorne",
      "back": "Hinten",
      "left": "Links",
      "right": "Rechts",
      "top": "Oben",
      "interior": "Innenraum"
    },
    "dark": "Es ist dunkel",
    "garage": "In einer Garage",
    "snow_ice": "Schnee / Eis",
    "rain_wet": "Es regnet / das Auto ist nass",
    "countries": {
      "de": "Deutschland",
      "pl": "Polen",
      "fr": "Frankreich"
    },
    "cookie_description": {
      "PHPSESSID": "Identifiziert Ihre aktuelle Browsersitzung",
      "tempTarget": "Wir verwenden das temporäre Ziel-Cookie, um von Ihnen gesuchte Produkte und Ihre Interessen zu identifizieren",
      "chedriCookiesBoxClosed": "Wir verwenden diesen Cookie, um anzuzeigen, dass Sie unsere Cookie-Informationen gesehen und akzeptiert haben",
      "chedriCookiesPerformance": "Wird verwendet, um festzustellen, ob Sie unsere Performance Cookies akzeptieren",
      "chedriCookiesTargeting": "Wird verwendet, um festzustellen, ob Sie unsere Targeting Cookies akzeptieren",
      "ga": "Wird verwendet, um Nutzer im Google Analytics-Service zu unterscheiden",
      "gid": "Wird verwendet, um Nutzer im Google Analytics-Service zu unterscheiden",
      "act": "Dies ist ein Facebook-Cookie, das für ihre Analyse und Forschung verwendet wird",
      "c_user": "Facebook-Login für Benutzeranmeldungen, mit dem Anzeigen geschaltet werden, die für die Nutzer und die Interessen der Nutzer relevanter sind",
      "datr": "Wird von Facebook verwendet, um den Browser des Nutzers zu identifizieren",
      "fr": "Enthält einen eindeutigen Browser und eine Benutzer-ID, die für gezielte Werbung verwendet wird",
      "sb": "Wird von Facebook verwendet, um die Vorschläge von Freunden zu verbessern",
      "xs": "Ein Facebook-Cookie, das zur Verwaltung einer Sitzung verwendet wird. Es funktioniert in Verbindung mit dem Cookie c_user, um Ihre Identität bei Facebook zu authentifizieren"
    },
    "cookie_ttl": {
      "6_months": "6 Monate",
      "60_days": "60 Tage",
      "2_years": "2 Jahre",
      "1_day": "1 Tag"
    },
    "upload_and_manage_media_files": "Hier können Sie Ihre Medien hochladen und verwalten.",
    "profile_information_avatar_contact_address": "Hier können Sie Ihre Profilinformationen bearbeiten. Avatar, Kontaktdaten, Adresse für die Rechnungsstellung, Finanzinformationen.",
    "define_sales_points_address_position_map_opening_hours": "Definieren Sie Ihre Verkaufsstellen: Adresse, Position auf der Karte, Öffnungszeiten. Wichtig für die Verwaltung von Produkten und Mitarbeitern.",
    "check_incoming_and_outgoing_invoices_check_details_and_download_as_pdf": "Sie können eingehende und ausgehende Rechnungen überprüfen, die vom System oder anderen Benutzern generiert wurden. Details anzeigen und im PDF-Format herunterladen.",
    "found_api_interesting_personal_api_key_here": "Finden Sie unsere API interessant? Wollen Sie es ausprobieren? Hier finden Sie Ihren persönlichen API-Schlüssel.",
    "need_our_data_in_own_system_configure_data_push": "Brauchen Sie unsere Daten in Ihrem eigenen System? Hier können Sie Daten-Push-Dienste konfigurieren, um Datenereignisse zu abonnieren.",
    "projects_running_on_different_domains": "Projekte, die auf verschiedenen Domains ausgeführt werden",
    "administration_of_your_website_logo_menu_pages": "Verwalten Sie Ihre Website. Logo, Hauptmenü, System-Seiten",
    "create_users_manage_profile_financial_roles_teams": "Erstellen Sie Benutzer, die in Ihrem Unternehmen arbeiten, verwalten Sie ihre Profil- und Finanzinformationen, überprüfen und legen Sie Sicherheitsrollen fest und weisen Sie Teams zu.",
    "provision_service": "Gebuchte Services",
    "provision_service_description": "Hier können Sie neue Typen von Services anlegen, bearbeiten oder löschen",
    "provision_service_title": "Titel",
    "provision_service_type": "Name",
    "provision_service_description_text": "Beschreibung",
    "provision_service_primary_rate": "Provision in Prozent für prim. Eigentümer",
    "provision_service_secondary_rate": "Provision in Prozent für sek. Eigentümer",
    "provision_service_type_status": "Status",
    "add_provision_type": "Provisionstyp hinzufügen",
    "edit_provision_type": "Bearbeiten",
    "create_provision_type": "Provisionstyp erstellen",
    "service_type_onboarding": "Onboarding (Installation)",
    "service_type_subscriptions": "Subscriptions (MRR, Abos && Usergebühren)",
    "service_type_additional_services": "Zusätzliches Services (Templates)",
    "service_type_product_sales": "Produkt Verkauf",
    "sales": "Vertrieb",
    "primary_owner": "Primärer Eigentümer",
    "secondary_owner": "Sekundärer Eigentümer",
    "provision_start": "Start",
    "provision_ending": "Ende",
    "provision_actions": "Aktionen",
    "add_provision_service": "Provision erstellen",
    "edit_provision_service": "Provision bearbeiten",
    "save_provision_type": "Speichern",
    "prov_service_client_name": "Name",
    "provision_service_documents": "Provisionen",
    "commission_service_documents": "Provisionen - Dokumente",
    "assign_service_type": "Neuen Servicetyp zuordnen",
    "provision_service_documents_description": "Hier können Sie alle Provisionen einsehen",
    "provision_service_documents_download": "Dokumente  herunterladen",
    "provision_service_documents_download_start": "Download",
    "provision_service_documents_name": "Name",
    "provision_service_documents_title": "Titel",
    "provision_service_documents_description_text": "Beschreibung",
    "provision_service_documents_serviceType": "Typ",
    "provision_service_documents_provisionRate": "Provisionssatz",
    "provision_service_documents_provisionValue": "Provisionswert",
    "provision_service_documents_provisionAssignee": "Empfänger",
    "provision_service_documents_provisionPayedDate": "bezahlt am",
    "provision_service_documents_IsActive": "Status",
    "provision_service_documents_Client": "Kunde",
    "provision_service_documents_choose_month": "Wählen Sie bitte den gewünschten Monat",
    "commission_service_documents_description": "Hier können Sie alle Provisionsrechnungen und Details einsehen und downloaden",
    "commission_service_documents_download": "Dokumente  herunterladen",
    "commission_service_documents_download_start": "Download",
    "commission_client": "Kunde",
    "commission_invoice_no": "Rechnungsnummer",
    "commission_payed_data": "bezahlt am",
    "commission_service_type": "Service Type",
    "commission_amount": "Betrag",
    "commission_provision": "Provision",
    "create_update_teams_assign_system_types": "Erstellen, bearbeiten Sie Teams, die sich in Ihrem Unternehmen befinden. Ordnen Sie Ihren Teams Systemtypen zu.",
    "create_document_types_use_for_process_automation": "Erstellen Sie eigene Dokumenttypen, damit Sie sie in Integrationen oder zur Prozessautomatisierung verwenden können.",
    "write_email_templates_send_to_clients": "Erstellen Sie E-Mail-Vorlagen, die beim Senden von Nachrichten an Kunden oder zum Automatisieren einiger Arbeitsabläufe verwendet werden können.",
    "define_reusable_cms_elements": "Definieren Sie wiederverwendbare Elemente, die auf beliebigen Seite verwendet werden können.",
    "manage_brands_edit_name_description": "Verwalten Sie Produktmarken, bearbeiten Sie deren Namen und Beschreibungen in mehreren Sprachen.",
    "manage_car_models_and_aliases": "Verwalten Sie Automodelle, aus denen Benutzer auswählen können. Definieren Sie Aliase für den Import.",
    "display_and_manage_category_tree": "Zeigen Sie den Kategoriebaum an und manipulieren Sie ihn. Sie können Namen und Beschreibungen ändern.",
    "display_and_manage_product_templates": "Erstellen und verwalten Sie Templates und ermöglichen Sie Nutzern kreativ zu werden.",
    "define_product_attributes_search_and_variants": "Definieren Sie zusätzliche Attribute, die Sie in Ihren Produkten angeben können. Verwenden Sie diese Attribute, um Benutzern beim Suchen von Produkten zu helfen. Erstellen Sie Produktvarianten basierend auf Attributen.",
    "group_attributes_to_better_manage_and_inform": "Sie können Attribute gruppieren, um die Benutzererfahrung im Backend besser zu verwalten und Ihren Kunden die Artikelattribute übersichtlicher zu präsentieren.",
    "entity_fields_missing_add_new": "Wenn Sie einige Felder in Ihren Formularen vermissen, können Sie sie hier hinzufügen. Nützlich für Marketingkampagnen und Integrationen.",
    "define_manage_pipelines_and_stages": "Definieren und passen Sie Pipelines für Ihre Workflows an. Hinzufügen, Bearbeiten, Entfernen von Bühnen.",
    "create_questionnaires_for_workflows_and_campaigns": "Erstellen Sie Fragebögen, die in Workflows oder verschiedenen Marketingaktionen verwendet werden können.",
    "catch_leads_from_other_sources_with_custom_email": "Fangen Sie Leads aus mehreren Quellen mit unserem Lead Catcher. Konfigurieren Sie individuelle E-Mail-Adressen für jede gewünschte Quelle.",
    "group_car_models_into_families_for_users_to_easily_find": "Gruppieren Sie Fahrzeugmodelle zu Familien, damit die Benutzer noch leichter nach Autos suchen können.",
    "enrich_car_search_functionality_with_own_model_images": "Bereichern Sie die Autosuchfunktion mit eigenen Modellfotos. Weisen Sie Fotos Modellen oder Modellgruppen zu.",
    "tag_leads_with_custom_regular_expressions": "Funktionalität unseres Tag-Systems mit benutzerdefinierten Tags erweitern. Kenntnisse des regulären Ausdrucks sind erforderlich.",
    "unknown_error": "Unbekannter Fehler aufgetreten",
    "username_required": "Bitte geben Sie Ihren Benutzernamen ein",
    "email_required": "Gebe bitte dein Email ein",
    "email_invalid": "Email ist ungültig",
    "client_name_required": "Bitte geben Sie Ihren Namen ein",
    "password_required": "Gebe bitte dein Passwort ein",
    "password_length": "Das Passwort muss mindestens aus 8 Zeichen bestehen",
    "repeat_password_required": "Wiederhole bitte dein Passwort",
    "repeat_password_length": "Das wiederholte Passwort muss mindestens aus 8 Zeichen bestehen",
    "message_required": "Bitte geben Sie eine Nachricht ein",
    "second_password_incorrect": "Zweites Passwort ist nicht das gleiche wie das erste",
    "registration_not_confirmed": "Ihre Registrierung wurde noch nicht bestätigt. Bitte überprüfen Sie Ihr Postfach und klicken Sie auf die Schaltfläche, um Ihre E-Mail-Adresse zu bestätigen",
    "account_deleted": "Dieser Account wurde gelöscht!",
    "account_inactive": "Das Abo zu diesem Account wurde beendet!",
    "company_required": "Bitte geben Sie den Firmennamen ein",
    "first_name_required": "Bitte geben Sie den Vornamen ein",
    "last_name_required": "Bitte geben Sie den Nachnamen ein",
    "country_required": "Bitte wählen Sie ein Land",
    "phone_required": "Bitte geben Sie die Telefonnummer ein",
    "street_required": "Bitte geben Sie den Straßennamen ein",
    "house_number_required": "Bitte geben Sie die Hausnummer ein",
    "zip_code_required": "Bitte geben Sie die Postleitzahl ein",
    "zip_code_invalid": "Die Postleitzahl '{{ value }}' ist keine gültige Postleitzahl",
    "city_required": "Bitte geben Sie den Namen der Stadt ein",
    "vat_id_required": "Bitte geben Sie die Umsatzsteuer-ID ein",
    "timezone_required": "Bitte wählen Sie eine Zeitzone",
    "select_job": "Bitte wählen Sie einen Job",
    "title_required": "Bitte geben Sie den Titel ein",
    "type_required": "Bitte wählen Sie einen Typ",
    "location_required": "Bitte geben Sie den Standort ein",
    "questionnaire_required": "Bitte wählen Sie einen Fragebogen",
    "subject_required": "Bitte geben Sie den Betreff ein",
    "amount_required": "Bitte geben Sie den Betrag ein",
    "time_count_required": "Bitte geben Sie den Zeitbetrag ein",
    "name_required": "Bitte geben Sie den Namen ein",
    "price_required": "Bitte geben Sie den Preis ein",
    "description_required": "Bitte geben Sie die Beschreibung ein",
    "question_required": "Bitte geben Sie die Frage ein",
    "content_required": "Bitte geben Sie den Inhalt ein",
    "template_required": "Bitte wählen Sie eine Vorlage",
    "payment_schedule_required": "Bitte wählen Sie einen Zahlungsplan",
    "answer_required": "Bitte geben Sie die Antwort ein",
    "website_required": "Bitte geben Sie die Adresse der Website ein",
    "fax_required": "Bitte geben Sie die Faxnummer ein",
    "currency_required": "Bitte wählen Sie eine Währung",
    "vat_name_required": "Bitte geben Sie den Namen der Mehrwertsteuer ein",
    "vat_id_name_required": "Bitte geben Sie den Namen der Umsatzsteuer-ID ein",
    "vat_rates_required": "Bitte geben Sie die Mehrwertsteuersätze ein",
    "coc_number_required": "Bitte geben Sie die COC-Nummer ein",
    "registered_at_required": "Bitte geben Sie die Informationen ein, unter denen Ihr Unternehmen registriert ist",
    "bank_name_required": "Bitte geben Sie den Banknamen ein",
    "account_holder_required": "Bitte geben Sie den vollständigen Namen des Kontoinhabers ein",
    "account_number_required": "Bitte geben Sie die Kontonummer ein",
    "bank_location_required": "Bitte geben Sie den Bankstandort ein",
    "bank_code_number_required": "Bitte geben Sie die Bankleitzahl ein",
    "iban_required": "Bitte geben Sie die IBAN ein",
    "min_message": "Dieser Wert ist zu kurz. Es sollte {{ limit }} Zeichen oder mehr haben",
    "max_message": "Dieser Wert ist zu lang. Es sollte {{ limit }} Zeichen oder weniger haben",
    "min_max_length": "Dieser Wert sollte zwischen {{ min }} und {{ max }} Zeichen lang sein",
    "greater_than_or_equal": "Dieser Wert sollte größer als oder gleich {{ compared_value }} sein.",
    "price_type_invalid": "Der Preistyp ist ungültig",
    "This value should not be blank": "Dieser Wert sollte nicht leer sein",
    "required_message": "Dieser Wert darf nicht leer sein",
    "This value is not a valid URL": "Dieser Wert ist keine gültige URL",
    "must_upload_a_file": "Sie müssen eine Datei hochladen",
    "file_format_not_supported": "Die Datei, die Sie hochladen möchten, wird nicht unterstützt",
    "category_not_empty": "Kategorie ist nicht leer",
    "brand_has_items": "Die Marke hat Produkte zugewiesen",
    "template_has_items": "Dem Template sind Produkte zugewiesen",
    "item_has_variants": "Das Produkt hat Varianten zugewiesen",
    "no_valid_variant": "Keine gültige Variante",
    "no_stage_set": "Es wurde keine Stufe gesetzt",
    "no_pipeline_set": "Es wurde keine Pipeline gesetzt",
    "no_users_found": "Keine Benutzer gefunden",
    "no_team_set": "Kein Team gesetzt",
    "budget_required": "Bitte das Budget definieren",
    "client_required": "Bitte einen Kunden zuordnen",
    "order_required": "Bitte eine Bestellung zuordnen",
    "item_required": "Bitte ein Produkt zuordnen",
    "document_required": "Bitte stellen Sie sicher, dass Dokumente vorhanden und gültig sind",
    "brand_required": "Bitte eine Marke auswählen",
    "model_required": "Bitte ein Modell auswählen",
    "order_type_invalid": "Bestellungstyp nicht gültig",
    "item_already_reserved": "Einige Produkte sind an ausgewählten Tagen nicht verfügbar",
    "wrong_credentials": "Ihr Benutzername oder Passwort wurde falsch eingegeben. Versuchen Sie es noch einmal oder klicken Sie auf \"Passwort vergessen\" ",
    "no_car_found": "Kein Fahrzeug für dieses Lead gefunden",
    "data_push_event_invalid": "Daten-Push-Event ist ungültig",
    "data_push_auth_invalid": "Daten-Push-Authentifizierungstyp ist ungültig",
    "cart_type_invalid": "Warenkorb-Typ ist ungültig",
    "system_type_invalid": "Der Systemtyp ist ungültig",
    "menu_version_invalid": "Menüversion ist nicht gültig",
    "logo_version_invalid": "Logoversion ist nicht gültig",
    "payment_method_invalid": "Wir konnten die von Ihnen angegebene Zahlungsmethode nicht verarbeiten",
    "nationality_required": "Bitte geben Sie Ihre Nationalität ein",
    "place_of_birth_required": "Bitte geben Sie Ihren Geburtsort ein",
    "date_of_birth_required": "Bitte geben Sie Ihr Geburtsdatum ein",
    "id_required": "Bitte geben Sie das ID ein",
    "meta_identifier_required": "Bitte wählen Sie eine Kennung aus",
    "payment_status_invalid": "Der Zahlungsstatus ist nicht gültig",
    "iban_invalid": "Der von Ihnen eingegebene Wert ist keine gültige Kontonummer",
    "swift_bic_required": "Bitte geben Sie eine gültige SWIFT/BIC Nummer ein",
    "reference_required": "Bitte geben Sie eine Referenz ein",
    "service_status_invalid": "Der Servicestatus ist nicht gültig",
    "date_proposal_type_invalid": "Vorgeschlagene Datumstyp ist nicht gültig",
    "mobile_phone_invalid": "Mobiltelefonnummer ist ungültig",
    "mobile_phone_required": "Bitte geben Sie Ihre Mobilnummer ein",
    "phone_invalid": "Telefonnummer ist ungültig",
    "fax_invalid": "Faxnummer ist ungültig",
    "salutation_required": "Bitte eine Anrede auswählen",
    "gender_required": "Bitte ein Geschlecht auswählen",
    "stripe_error": "Fehler beim Verbinden mit Stripe",
    "stripe_connect_error_with_type": "Wir konnten CreaCheck nicht mit Ihrem Stripe-Konto verbinden. Fehlercode: {type}.",
    "car_rental_pickup_time_invalid": "Die gewünschte Abholzeit ist außerhalb der Öffnungszeiten des Standortes",
    "car_rental_drop_off_time_invalid": "Die angeforderte Abgabezeit für ein Auto liegt außerhalb der Öffnungszeiten des Standortes",
    "accept_terms_and_cancellation_before_continuing": "Bitte bestätigen Sie, dass Sie unsere Allgemeinen Geschäftsbedingungen und die Widerufsbelehrung gelesen haben",
    "problem_processing_your_credit_card": "Wir haben ein Problem mit der Verarbeitung Ihrer Kreditkarte",
    "invoice_type_invalid": "Rechnungsart ist ungültig",
    "doc_number_required": "Bitte geben Sie die Dokumentennummer ein",
    "doc_issuing_city_required": "Bitte geben Sie die ausstellende Stadt an",
    "doc_issuing_authority_required": "Bitte geben Sie die ausstellende Behörde an",
    "doc_issue_date_required": "Bitte geben Sie das Ausstellungsdatum ein",
    "doc_expiration_date_required": "Bitte geben Sie das Ablaufdatum ein",
    "test_domain_required": "Bitte geben Sie einen Test-Domainnamen ein",
    "iban_has_wrong_format": "Das IBAN-Format ist nicht korrekt",
    "car_cash_payment_data_missing_or_invalid": "Barzahlungsdaten fehlen oder sind ungültig",
    "car_registration_service_only_available_in_germany": "Der Zulassungsdienst ist derzeit nur in Deutschland verfügbar",
    "car_transport_only_available_in_germany": "Unser Transportdienst ist derzeit nur in Deutschland verfügbar",
    "target_type_invalid": "Zieltyp nicht gültig",
    "company_email_must_be_different_from_users_email": "Die E-Mail-Adresse Ihres Unternehmens muss sich von Ihrer E-Mail-Adresse unterscheiden",
    "car_transport_distance_too_short": "Der Transport des Autos ist nur an Orten möglich, die weiter als 100 km entfernt sind",
    "first_offer_price_too_low": "Ihr erstes Angebot ist zu niedrig und wurde nicht angenommen",
    "first_offer_price_too_high": "Ihr erstes Angebot ist höher als der ursprüngliche Preis und wurde nicht angenommen",
    "current_offer_too_low": "Ihr aktuelles Angebot ist zu niedrig",
    "not_your_turn_to_negotiate_the_price": "Sie können Ihr Angebot jetzt nicht aktualisieren. Bitte warten Sie auf eine Antwort unseres Mitarbeiters",
    "the_question_must_be_answered": "Die Frage \"{{ question }}\" muss beantwortet werden",
    "validation_problems_with_item_attributes": "Überprüfen Sie, ob die Attribute ordnungsgemäß ausgefüllt sind. Aufgetretene Probleme sind rot markiert",
    "the_field_name_contains_following_errors": "Das Feld {fieldName} enthält folgenden Fehler: {helpText}",
    "plugin_required": "Bitte wählen Sie einen Aboplan aus.",
    "invalidRegex": "Der von Ihnen angegebene reguläre Ausdruck ist ungültig",
    "assignment_strategy_type_invalid": "Zuweisungsstrategietyp ist nicht gültig",
    "cannot_remove_role_company": "Es ist nicht möglich, Benutzerrolle ROLE_COMPANY zu entfernen",
    "coupon_type_invalid": "Bitte wählen Sie einen gültigen Gutscheintyp",
    "subscription_starting_fee": "Startgebühr für das Abonnement",
    "comms_center_monthly_fee": "Creacheck Communications Center",
    "comms_center_chat": "Creacheck Communication Center - Kundenchat",
    "comms_center_call": "Creacheck Communication Center - Kundenanruf",
    "401": "<strong>Fehler beim Einloggen</strong>",
    "You are not authenticated": "Das Email und Kennwort ist uns leider nicht bekannt",
    "access_denied": "Um sich einzuloggen, musst du den Zugang für Creacheck.com akzeptieren",
    "oauth_email_missing": "Wir können Sie sich nicht durch Social Media einloggen. Bitte melden Sie sich mit Ihrer E-Mail-Adresse ein.",
    "User already exists": "Die angegebene E-Mail-Adresse existiert schon in unserer Datenbank. Hast du dein Passwort vergessen?",
    "next": "Weiter",
    "create_comment": "Einen Kommentar schreiben",
    "new_pipeline": "Neue pipeline",
    "new_stage": "Neue Stufe",
    "quantity": "Menge",
    "login_error": "Fehler beim Einloggen",
    "not_found": "Nicht gefunden",
    "deal_information": "Deal Informationen",
    "login_domain": "Login-Domäne",
    "login_domain_successfully_changed": "Die Login-Domäne wurde erfolgreich geändert.",
    "missing_lead_information": "Bitte geben Sie fehlende Lead-Informationen an",
    "missing_informations": "Fehlende Informationen",
    "missing_client_signature": "Fehlende Kundenunterschrift",
    "previous": "Vorherig",
    "current": "Aktuell",
    "customCssInvalid": "Benutzerdefiniertes CSS ist ungültig ({{ error }})",
    "accountAndLogin": "Konto & Login",
    "invalidEIN": "Ungültige eVB Nummer (elektronische Versicherungsbestätigung)",
    "radio": "Radio",
    "airConditioning": "Klimaanlage",
    "parkingAssistanceRear": "Einparkhilfe hinten",
    "airConditioningAutomatic": "Klimaautomatik",
    "navigation": "Navi",
    "alloyRims": "Alufelgen",
    "parkingAssistanceFrontRear": "Einparkhilfe vorne & hinten",
    "metallicPaint": "Metalliclackierung",
    "cruiseControl": "Tempomat",
    "seatHeating": "Sitzheizung",
    "leatherSeats": "Ledersitze",
    "equipmentLevel": "Ausstattungslevel",
    "motorPower": "Leistung",
    "currentLocation": "Aktueller Standort",
    "distance": "Entfernung",
    "selected": "ausgewählt",
    "noBrandSelected": "Keine Marke ausgewählt",
    "showMore": "Mehr anzeigen",
    "showLess": "Weniger anzeigen",
    "optional": "Optional",
    "altogether": "Insgesamt",
    "monday": "Montag",
    "tuesday": "Dienstag",
    "wednesday": "Mittwoch",
    "thursday": "Donnerstag",
    "friday": "Freitag",
    "saturday": "Samstag",
    "sunday": "Sonntag",
    "payment_data_missing_or_invalid": "Zahlungsdaten fehlen oder sind ungültig",
    "previousSearchRequests": "Vorherige Suchanfragen",
    "reset": "Zurücksetzen",
    "number_days": "{days, plural, zero {# Tage} one {# Tag} other {# Tage}}",
    "number_months": "{months, plural, zero {# Monate} one {# Monat} other {# Monate}}",
    "outdated_browser": "Ihr Browser ist veraltet!",
    "update_browser_text": "Bitte aktualisieren Sie Ihren Browser, um diese Website korrekt darzustellen.",
    "update_browser_btn_text": "Den Browser jetzt aktualisieren",
    "save_successfully": "Erfolgreich gespeichert",
    "add_quickly": "Schnell hinzufügen",
    "load_data": "Daten laden",
    "car_data_import_vin_info": "Geben Sie die gültige FIN ein, um das Fahrzeugdatenformular automatisch auszufüllen.",
    "car_data_import_missing_attr_values": "Einige Attributewerte fehlen - sie sind im Formular markiert.",
    "vin_invalid": "FIN ist ungültig",
    "others": "Sonstige",
    "activate_plugin_to_enable_feature": "Diese Funktion ist nur mit dem folgenden Plugin verfügbar. Aktivieren Sie das Plugin, um diese Funktionalität weiterhin zu nutzen.",
    "car_data_import_failed": "Der Import von Fahrzeugdaten war für diese FIN nicht möglich. Prüfen Sie, ob die angegebene VIN korrekt ist.",
    "send_mail_via_outlook": "Die E-Mail wird mit Ihrem Microsoft Outlook Konto ({email}) versandt und ist später in Ihrem \"Gesendet\"-Ordner verfügbar.",
    "permission_required": "Erforderliche Genehmigungen",
    "phone_call_permissions_required": "Diese Applikation muss auf Ihre Telefonkonten zugreifen, um Anrufe tätigen zu können",
    "calling": "Mich anrufen",
    "callingTo": "Aufruf an",
    "startingCall": "Einen Anruf einleiten",
    "callEnded": "Anruf beendet",
    "inCall": "'In Aufruf",
    "callCancelled": "Anruf abgebrochen",
    "callRejected": "Anruf abgelehnt",
    "is_calling": "%name% ruft...",
    "contacts": "Kontakte",
    "addresses": "Adressbuch",
    "chat": "Chat",
    "calls": "Anrufe",
    "endCustomerNewMessage": "Nachricht vom neuen Kunden",
    "returnToTheActiveCall": "Zurück zum aktiven Anruf",
    "agentStatus": "Agentenstatus",
    "switchOnAgentNote": "Aktivieren, um Kunden-Chats und Videoanrufe anzunehmen",
    "switchOffAgentNote": "Deaktivieren, um keine Kunden-Chats und Videoanrufe anzunehmen",
    "activateAgent": "Aktiviere die Annahme von Kundenchats",
    "deactivateAgent": "Deaktiviere die Annahme von Kundenchats",
    "startAudioCallWith": "Starte Telefonanruf mit {{to}}",
    "startVideoCallWith": "Starte Videoanruf mit {{to}}",
    "terms_and_conditions": "Geschäftsbedingungen",
    "form_incorrect_data": "Die von Ihnen eingegebenen Daten sind nicht korrekt. Bitte überprüfen Sie Ihre Eingaben und senden sie erneut.",
    "accept_terms_and_condition_start_chat": "Wenn Sie auf \"Chat starten\" klicken, akzeptieren Sie die Geschäftsbedingungen",
    "start_chat": "Chat starten",
    "connecting_with": "Verbinden mit",
    "connected_with": "Verbunden mit",
    "do_calling": "ruft an",
    "chat_inactivity_warning": "Der Chat ist zu lange inaktiv und wird bald geschlossen.",
    "get_in_contact_with_us": "Nehmen Sie Kontakt mit uns auf",
    "no_agent_available": "Leider ist keiner der Agenten online. Bitte hinterlassen Sie eine Nachricht und wir werden Sie so schnell wie möglich kontaktieren.",
    "hello": "Hallo!",
    "we_are_here_for_you": "Wir sind für Sie da und freuen uns auf Ihre Fragen oder Ihr Feedback.",
    "start_conversation": "Konversation starten",
    "welcome_customer": "Mein Name ist {firstName} {lastName}. Wie kann ich Ihnen helfen?",
    "end_chat": "Beenden",
    "send_request": "Anfrage senden",
    "checkout_payment_agreement": "Ich willige ein, dass die {company} vor Beginn der Widerrufsfrist  mit der Erbringung der Zusatzleistungen beginnt und habe zur Kenntniss genommen, dass ich mein Widerrufsrecht bei vollständiger Erbringung dieser Zusatzleistungen verliere.",
    "checkout_buy_order_legal_text": "Für den Kauf gelten die {general_terms_and_conditions} sowie die {cancellation_policy_and_cancellation_form} von {company} ({privacy_policy})",
    "checkout_services_terms_cancelation_legal_text": "Für die gebuchten Zusatzleistungen ({booked_services}) gelten {general_terms_and_conditions} sowie die {cancellation_policy_and_cancellation_form} von {company} ({privacy_policy})",
    "no_credit_cards": "Keine Kreditkarten",
    "minimize_video": "Videoansicht minimieren",
    "maximize_video": "Videoansicht maximieren",
    "enable_fullscreen": "Vollbildmodus aktivieren",
    "disable_fullscreen": "Vollbildmodus deaktivieren",
    "enable_screensharing": "Desktop teilen",
    "disable_screensharing": "Desktop teilen beenden",
    "mute_microphone": "Mikrofon stummschalten",
    "unmute_microphone": "Mikrofon Stummschaltung aufheben",
    "enable_camera": "Kamera einschalten",
    "disable_camera": "Kamera ausschalten",
    "end_call": "Den Anruf beenden",
    "end_videocall": "Den Videostream beenden",
    "muted": "Stumgeschaltet",
    "overall_feedback": "Wie bewerten Sie Ihren Kontakt mit uns?",
    "connection_quality_feedback": "Wie bewerten Sie die Verbindungsqualität?",
    "send_feedback": "Feedback senden",
    "customer_chat_ended_info": "Ihr Chat wurde beendet",
    "checking_connection": "Verbindung wird geprüft",
    "call_again": "Erneut anrufen",
    "end_call_local_disconnected": "Anrufbeendigung wegen Verbindungsproblemen",
    "end_call_remote_disconnected": "Anrufbeendigung wegen Verbindungsproblemen auf der {remoteUserName}-Seite",
    "network_disconnected_warning": "Es besteht zur Zeit keine Verbindung zum Internet.",
    "network_disconnected": "Verbindung unterbrochen",
    "network_want_reconnect": "Ihre Verbindung wurde unterbrochen. Möchten Sie die Verbindung wieder aufnehmen?",
    "leave_as_an_email_message": "Hinterlassen Sie uns eine E-Mail-Nachricht",
    "continue_call": "Anruf fortsetzen",
    "continue_text_chat": "Text-Chat fortsetzen",
    "continue_conversation": "Gespräch fortsetzen",
    "disconnected": "Getrennt",
    "disconnect_reason": "Möglicher Grund",
    "disconnect_reason_transport_close": "Die Seite verlassen",
    "disconnect_reason_ping_timeout": "Keine Internetverbindung",
    "customer_may_return": "Der Kunde kann bald wiederkommen.",
    "mobile": "Handy",
    "headquarter": "Zentrale",
    "extension-number": "Durchwahlnummer",
    "add_contact": "Kontakt hinzufügen",
    "add_address": "Adresse hinzufügen",
    "choose_contact": "Kontakt wählen",
    "number": "Nummer",
    "new_number": "Neue Nummer",
    "address_mail": "Postanschrift",
    "address_delivery": "Lieferanschrift",
    "address_invoice": "Rechnungsanschrift",
    "new_address": "Neue Adresse",
    "find_address": "Adresse finden",
    "searching": "Suchen...",
    "address_change": "Adresse ändern",
    "address_ask_change": "Das Ändern dieser Adresse wirkt sich auf alle Kontakte mit dieser Anschrift aus. Möchten Sie die Adresse ändern?",
    "designation": "Bezeichnung",
    "net": "Netto",
    "gross": "Brutto",
    "exp_delivery_time": "vsl. Lieferzeit",
    "workdays": "Werktage",
    "special_offer": "Aktion",
    "transportation_cost": "Versandkosten",
    "discount": "Rabatt",
    "total": "Gesamt",
    "total_gross": "Gesamt (inkl. MwSt.)",
    "product_details": "Produktdetails",
    "type_of_order": "Art der Bestellung",
    "number_of_copies": "Auflage",
    "type_of_order_choices": "Reguläre Druckproduktion oder Download der Druckdaten als PDF-Datei",
    "type_of_order_website": "Bereitstellung dieser Webseite",
    "print_production": "Druckproduktion",
    "pdf_download": "PDF-Download",
    "website_upload": "Upload der Webseite",
    "production_time": "Produktionszeit",
    "production_time_description": "Bitte wählen Sie die gewünschte Option",
    "production_standard": "Standard-Produktion",
    "production_express": "Express-Produktion",
    "production_overnight": "Overnight-Produktion",
    "choose_edition": "Auflage auswählen",
    "choose_edition_description": "Auflagen mit Preisstaffelung. Rabatte und Aktionen werden, falls verfügbar, hervorgehoben.",
    "more_options": "Weitere Optionen",
    "more_options_description": "Fügen Sie Ihrer Bestellung weitere Optionen hinzu.",
    "manual_data_review": "Manuelle Datenprüfung (inkl. Versicherung)",
    "manual_item_entry": "Manuelle Eingabe der Position",
    "web_enabled_pdf": "Druckdaten zusätzlich als webfähiges PDF erhalten.",
    "cart_production_type_change_warning_title": "Die Auflage wird angepasst",
    "cart_production_type_change_warning": "Die ausgewählte Auflage ist nicht für die {{shippingType}} verfügbar. Sie wird auf die maximal verfügbare Auflage umgestellt. Wollen Sie wirklich fortfahren?",
    "cart_no_warranty_without_manual_data_check": "Hinweis: Manuelle Datenprüfung nicht ausgewählt.",
    "campaign_budget_exceed_warning": "Kampagnenbudget überschritten. Freigabe erforderlich!",
    "user_budget_exceed_warning": "Benutzerbudget überschritten. Freigabe erforderlich!",
    "order_quantity": "Bestellmenge",
    "data_check": "Datenprüfung",
    "split_shipping": "Teillieferung",
    "cart_not_logged_in": "Kein eingeloggter Benutzer",
    "cart_contact_or_address_not_found_invoice": "Bitte wählen Sie eine Rechnungsadresse aus",
    "cart_contact_or_address_not_found_shipping": "Bitte wählen Sie eine Lieferadresse aus",
    "cart_edtion_item_or_variant_not_found": "Ausgabe, Artikel oder Variante nicht gefunden",
    "cart_edtion_item_not_available": "Der Artikel %item_name% ist nicht mehr verfügbar",
    "cart_no_print_type_found": "Kein Drucktyp gefunden",
    "cart_print_type_invalid": "Drucktyp ungültig: ",
    "cart_no_shipping_type_found": "Keine Versandart gefunden",
    "cart_checkout_info_missing": "Checkbox-Informationen fehlen: ",
    "cart_reduced_vat_rate_check_not_supported": "Angeforderte Prüfung des reduzierten Mehrwertsteuersatzes für Artikel, der diesen nicht unterstützt: ",
    "cart_no_price_found": "Der Preis konnte nicht gefunden werden",
    "cart_price_mismatch": "Der Preis entspricht nicht dem Preis, den wir dem Benutzer präsentiert haben",
    "cart_web_pdf_price_mismatch": "Der Web-PDF-Preis entspricht nicht dem Preis, den wir dem Benutzer präsentiert haben",
    "cart_contact_or_address_not_found_split_shipping": "Bitte wählen Sie eine Adresse für geteilten Versand",
    "cart_quantity_not_found_split_shipping": "Mengenparameter beim geteilten Versand nicht gefunden",
    "cart_split_shipping_quantity_mismatch": "Die aufgeteilte Versandmenge stimmt nicht mit der Produktionsmenge überein",
    "cart_coupons_mismatch": "Die verwendeten Coupons stimmen nicht überein",
    "cart_coupon_cant_be_used": "Der angeforderte Coupon ist abgelaufen oder kann nicht verwendet werden",
    "cart_coupon_value_mismatch": "Der Couponwert stimmt nicht",
    "cart_camaign_budget_processing_error": "Unerwartetes Problem bei der Bearbeitung des Kampagnenbudgets aufgetreten",
    "cart_campaign_budget_has_changed": "Das Kampagnenbudget hat sich geändert",
    "cart_user_budget_has_changed": "Das Benutzerbudget hat sich geändert",
    "cart_user_budget_processing_error": "Unerwartetes Problem bei der Bearbeitung des Benutzerbudgets aufgetreten",
    "cart_domain_not_active": "Die Domain ist nicht aktiv",
    "cart_missing_desired_domain": "Angabe der Wunschdomäne fehlt",
    "lead_monitor_order_payment_receipt": "Zahlungseingang überwachen",
    "lead_format_message_description_route53_register_domain": "Domäne {0} registrieren und umleiten auf {1}",
    "lead_title_route53_register_domain": "AWS Domäne registrieren",
    "domain_not_available": "Domäne nicht verfügbar",
    "invoice_payment_option": {
      "enabled": "Aktiviert",
      "disabled": "Deaktiviert",
      "inherited": "Vererbt",
      "title": "Bezahlung der Rechnung",
      "loading": "Vererbte Einstellung laden",
      "effective_setting": "effektive Einstellung"
    },
    "invoice_payment_threshold": {
      "title": "Schwellenwert",
      "placeholder": "im Beispiel, 100",
      "no_effect": "keine Wirkung",
      "loading": "Laden vererbter Einstellungen",
      "info_text": "Mindestbestellwert für die Bezahlung per Rechnung",
      "effective_threshold": "Effektive Schwelle",
      "not_defined": "nicht definiert"
    },
    "remind_period": {
      "days": "Tage",
      "inherited_setting": "Vererbte Einstellung",
      "title": "Fälligkeit der Rechnung Erinnerungsfrist"
    },
    "sellDownloadPdf": "Download PDF verkaufen",
    "success_management": {
      "title": "Erfolgsmanagement",
      "manager": "Erfolgsmanager",
      "switch_title": "Zuletzt gesehen-Erinnerungen aktivieren",
      "interval": "Zuletzt gesehener Zeitraum, Tage",
      "last_seen": "Zuletzt gesehen",
      "error_no_self": "Kann sich selbst nicht als Success Manager zuweisen",
      "error_no_same_parent": "Success Manager muss einem anderen Unternehmen unterstellt sein"
    },
    "copy": "Kopieren",
    "is_client_registration_allowed": "Registrierung aktivieren",
    "is_email_validation_enabled": "Email validieren",
    "email_validation_pattern": "Regex für email Validierung",
    "confirmation_email_cc": "CC für Registrierungsbestätigung",
    "client_registration_title": "Registrierung Einstellungen",
    "registration_fields_show": "Felder Anzeigen",
    "registration_fields_require": "Pflichtfelder",
    "accept_terms_of_use_before_continuing": "Bevor Sie fortfahren, müssen Sie unsere Nutzungsbedingungen akzeptieren",
    "subscription_settings": "Abonnements",
    "client_subscription_settings": "Kunden und Produkt Abonnements",
    "subscription_settings_description": "Hier können Sie ein Basisabo erstellen und einen Typ zuweisen",
    "client_subscription_settings_description": "Hier können Sie das Basisabo typabhängig einem Kunden oder einem Artikel zuordnen und die Preise anpassen",
    "subscriptions_": "Abonnements",
    "subscription_name": "Name",
    "subscription_title": "Titel",
    "subscription_description": "Beschreibung",
    "subscription_fee": "Gebühr",
    "subscription_startingFee": "Startgebühr (optional)",
    "subscription_start": "Beginn",
    "subscription_end": "Ende",
    "subscription_status": "Status",
    "subscription_action": "Aktionen",
    "subscription_items": "Produkte",
    "subscription_clients": "Kunden",
    "subscription_companies": "Firmen",
    "subscription_companies_detailed_role": "Firmen User/Role",
    "subscription_basic": "Basis Abo",
    "add_subscription": "Abonnement hinzufügen",
    "create_subscription": "Abonnement erstellen",
    "edit_subscription": "Abonnement speichern",
    "subscription_language": "Sprachauswahl",
    "period_status": "am Periodenende deaktivieren",
    "subscription_edit": "Bearbeiten",
    "omit_subscription_fee": "Startgebühr weglassen",
    "invoice_email": "Emailadresse für Rechnungen",
    "order_processing_contract": "Auftragsverarbeitungsvertrag",
    "accept_order_processing_contract_before_continuing": "Bevor Sie fortfahren, müssen Sie unseren Auftragsverarbeitungsvertrag akzeptieren",
    "no_image_title": "Kein Bildtitel gefunden. Hier klicken zum Bearbeiten.",
    "no_image_description": "Keine Bildbeschreibung gefunden. Hier klicken zum Bearbeiten",
    "no_image_copyright": "Keine Informationen zum Copyright gefunden. Hier klicken zum Bearbeiten",
    "no_text": "Kein Text gefunden. Hier klicken zum Bearbeiten",
    "subscription_clients_and_companies": "Kunden und Unternehmen",
    "save_subscription": "Speichern",
    "go_to": "Gehe zu",
    "no_invoices_to_display": "Keine Rechnungen zum Anzeigen",
    "url_settings": "Url-Einstellungen",
    "update_notification": "Updateverwaltung",
    "update_notification_description": "Beschreibung",
    "update_notification_settings_description": "Hier können Sie Benachrichtigungen für Updates verwalten",
    "update_name": "Name",
    "update_notification_meta": {
      "title": "Updateverwaltung",
      "description": "Verwalten Sie hier Ihre Updatebenachrichtigungen",
      "name": "Updateverwaltung",
    },
    "new_update_notification": "Neue Updatebenachrichtigung erstellen",
    "update_from": "von",
    "update_to": "bis",
    "edit_update_notification": "Updatebenachrichtigung bearbeiten",
    "individual_prices": "Individuelle Preise",
    "template_pdf_profile_x3": "PDF in X-3 Format erstellen",
    "countries_for_prices": {
      "de": "Deutschland",
      "ch": "Schweiz",
      "at": "Österreich",
      "es": "Spanien",
    },
    "choose_country": "Wählen Sie bitte ein  Land",
    "product_groups": "Produktgruppen - Lieferanten",
    "product_groups_description": "Hier Können Sie die Produkgruppen von Lieferanten verwalten",
    "new_product_group": "Neue Produktgruppe hinzufügen",
    "edit_product_group": "Bearbeiten",
    "create_product_group": "Erstellen",
    "product_group_number": "Produktgruppe Nummer",
    "save_product_group": "Speichern",
    "product_group_actions": "Aktionen",
    "product_group_status": "Status",
    "hide_preview": "Vorschau im Editor deaktivieren",
    "editor_settings": "Einstellungen Editor",
    "share_edition": "Edition teilen",
    "canceled_quote": "Angebot abgelehnt",
    "active_quote": "Angebot ausstehend",
    "manual_quote_status": "Status der Angebote",
    "invalid_manual_quote_status": "Ungültiger Status des Angebots",
    "internal_status": "Interner Status",
    "edit_quote": "Angebot bearbeiten",
    "copy_quote": "Angebot kopieren",
    "open_lead": "Öffne Lead",
    "upload_pdf": "PDF hochladen und verlinken",
    "upload_pdf_title": "PDF Upload",
    "upload_pdf_status_done": "Ihre PDF Datei wurde erfolgreich hochgeladen",
    "upload_pdf_status_error": "PDF Upload fehlgeschlagen.",
    "placeholder_upload_error": "Beim Upload ist ein Problem aufgetreten!",
    "linkedin_insight_tag": "LinkedIn Insight Tag",
    "linkedin_partner_id": "Partner Id",
    "tracking_pixel": "Tracking Pixel z. B. Meta Pixel",
    "tracking_pixel_data": "Implementierungscode",
    "jsWebsite": "Javascript",
    "upload_website_favicon": "Favicon",
    "upload_website_favicon_label": "Bildupload und -vorschau",
    "upload_website_favicon_info": "Bitte beachten Sie! Das erforderliche Dateiformat ist PNG. Die optimale Auflösung beträgt je nach verwendetem Gerät zwischen 32 x 32px und maximal 64 x 64px. Sie können auch andere Größen verwenden solange sie quadratisch sind und die maximale Auflösung nicht überschreiten. ",
    "upload_favicon_error": "Bild hat nicht die erforderliche Auflösung",
    "go_back_to_campaign": "Zurück zur Kampagne",
    "no_orders_to_display": "Keine Bestellungen zum Anzeigen",
    "no_editions_to_display": "Keine Editionen zum Anzeigen",
    "google_tag_manager": "Google Tag Manager",
    "google_tag_manager_id": "GTM-ID",
    "website_analyse": "Website Analyse",
    "legal_information": "Rechtliche Informationen",
    "contact_info": "Kontaktinformationen",
    "import_users_via_csv": "Erstellen Sie Benutzer, indem Sie eine CSV Datei importieren",
    "remove_users_via_csv": "Benutzer durch Importieren einer CSV-Datei entfernen",
    "import_users": "Benutzer importieren",
    "remove_users": "Benutzer entfernen",
    "fill_data_policy_before_autosave": "Automatisches Speichern fehlgeschlagen. Bitte füllen Sie die Angaben zum Datenschutz und Impressum vollständig aus. Diese finden Sie in den Einstellungen",
    "invoice_customer_info": "ID - Firma / Name",
    "invoice_search_debtor": "Debitornummer",
    "invoice_search_vat": "MwSt",
    "invoice_search_amount": "Betrag",
    "search_field_name": "Wählen Sie ein Suchfeld",
    "search_field_info_text": "Wenn Sie im Suchfeld nach einem numerischen Wert suchen möchten, müssen Sie ein Feld auswählen",
    "your_export": "Ihr Export",
    "export_download_info": "Ihre Datei wurde erzeugt und kann nun verwendet werden.",
    "export_download": "Download",
    "export_file": "Datei",
    "export_share": "Teilen",
    "image_permission_title": "Achtung!",
    "image_permission_warning": "Bildrechte vorhanden/Einverständnis aller Personen eingeholt?",
    "other_settings": "Sonstige Einstellungen",
    "image_rights_query": "Bildrechteabfrage",
    "direct_ordering_label": "Direktbestellung",
    "direct_ordering_info_text": "Bei einer Direktbestellung kann die erstellte Edition nicht mehr bearbeitet werden. Diese wird direkt in den Warenkorb gelegt.",
    "subscription_automation": {
      "title": "Abrechnungsziel für neue Benutzerabonnements",
      "payer": "Zahler",
      "fee_for_role_user": "Gebühr für ROLE_USER",
      "fee_for_role_admin": "Gebühr für ROLE_ADMIN",
      "fee_for_role_wl_admin": "Gebühr für ROLE_WL_ADMIN",
      "hint_title": "Hinweis",
      "hint_body": "Bitte erstellen und aktivieren Sie ein Firmenabonnement für diese Firma, um alle später erstellten Benutzer abzudecken.",
      "debtor_ancestor": "Jedes Benutzerabonnement wird dem %billingTargetName% in Rechnung gestellt.",
      "debtor_ancestor_missing": "Kein Vorgängerunternehmen ist als Schuldner markiert. Bitte korrigieren Sie dies."
    },
    "need_content_authorization": "Inhaltsautorisierung erforderlich ",
    "publisher_info_text": "Der Herausgeber wird automatisch nach den gesetzlichen Richtlinien in jedes notwendige Werbemittel eingefügt.",
    "mark_campaign": "Markiere Kampagne",
    "template_exports": {
      "download_complete": "Download abgeschlossen",
      "export": "Export",
      "download_files": "Dateien herunterladen",
      "synchronize": "Synchronisiere",
    },
    "info_import_and_export": {
      "note": "Allgemeine Informationen zum Import und Export",
      "import": "Importieren: Importiert alle Informationen zu den Templateeinstellungen (CSS, Inhaltsblöcke, sonstige Einstellungen) und Dateien. Erstellt eine initiale Zip Datei für den Export. Bestehende Zip Dateien werden überschrieben.",
      "upload": "Upload: Fügt dem S3 Templateverzeichnis weitere Dateien hinzu. Die Zip Datei für den Export wird um diese Dateien ergänzt.",
      "sync": "Synchronisiere: Synchronisiert die Templateeinstellungen (CSS, Inhaltsblöcke, sonstige Einstellungen) mit den Dateien (index.html, seite.css, pdf.css, printpdf.css, website.css, website.js) im S3 Templateverzeichnis und der Zip Datei (Export)",
      "export": "Export: Erstellt die Struktur einer index.html zum Kopieren und Einfügen",
      "download": "Dateien herunterladen: Lädt die vorhandene Zip Datei herunter. (Führen Sie vor dem Herunterladen eine Synchronisation durch, um die aktuellsten Daten zu erhalten",
      "copy": "Copy Template: Die Funktion 'Copy Template' kopiert nur noch die Templateeinstellungen, jedoch nicht die Verzeichnisse und Dateien",
    },
    "template_upload_title": "Dateien zum Templateverzeichnis hochladen",
    "pls_choose_text": "Bitte wählen Sie einen Text",
    "discounts": {
      "discount_type_invalid": "Rabatttyp ist nicht gültig",
      "discounts_tab_title": "Rabatte",
      "intro_text": "Bitte geben Sie die FlyerAlarm-Produktgruppennummer und den entsprechenden Rabatttyp und -wert an. Diese werden während der Kaufabwicklung für alle Benutzer innerhalb des aktuellen Unternehmens angewendet.",
      "none_yet_add_some": "Es wurden noch keine Rabatte definiert. Bitte fügen Sie welche hinzu.",
      "number_must_be_unique": "Nummer muss eindeutig sein",
      "must_be_0_to_100": "Muss von 0 bis 100 sein"
    },
    "link_to_uploaded_pdf": "Url zur hochgeladenen PDF-Datei",
    "upload_qrcode_logo": "Logo für QRCode hochladen",
    "choose_file": "Datei auswählen",
    "upload_successful": "Upload erfolgreich",
    "upload_failed": "Upload fehlgeschlagen",
    "qr_logo": "Logo",
    "qr_example": "Beispiel",
    "qr_settings": "QR-Code Einstellungen",
    "qr_margin": "Abstand",
    "qr_upload": "Upload QRCode-Logo",
    "google_search_console": "Google Search Console",
    "gsc_html_tag": "HTML-TAG Verifizierung",
    "content_id": "Content-Id",
    "gsc_html_tag_example": "Verwenden Sie bitte die HTML-Tag Verifizierung. Kopieren Sie die Id z.B. Xu9c238409d8JDSF8... aus dem Content-Attribute des Meta-Tags das Sie von Google für die Bestätigung erhalten und fügen Sie die Id hier ein. Beispiel: <meta name='google-site-verification' content='Xu9c238409d8JDSF8...' />",
    "order_complete": {
      "good_luck": "Viel Erfolg mit Ihrer Kampagne!",
      "order_complete": "Ihre Bestellung wurde erfolgreich abgeschlossen",
      "pls_check_inbox": "Überprüfen Sie bitte Ihren Posteingang.",
      "order_info": "Alle Bestelldetails wurden an Ihre, bei uns hinterlegte, E-Mailadresse gesendet. Die Bestellhistorie können Sie jederzeit in Ihrem Benutzerkonto unter",
      "orders": "Bestellungen",
      "view": "einsehen.",
      "start_next_campaign": "Jetzt eine weitere Kampagne starten"
    },
    "no_product_selected": "Kein Produkt ausgewählt",
    "mail_messages": {
      "header": {
        "header_title": "Das smarte Online-Designportal",
        "support": "Hilfe & FAQ",
        "login": "LOGIN",
      },
      "footer": {
        "email_info": "Sie erhalten diese E-Mail, weil Sie sich bei %company% angemeldet haben.",
        "email_info_first_name_form": "Du erhältst diese E-Mail, weil du dich bei %company% angemeldet hast.",
        "dont_reply": "Diese E-Mail wurde von einer Adresse aus versandt, die keine eingehenden E-Mails empfangen kann. </br>Bitte nicht auf diese Nachricht antworten. Wenn Sie irgendwelche Fragen oder Bedenken haben, kontaktieren Sie uns unter",
        "agb": "AGB",
        "privacy_policy": "Datenschutz",
        "imprint": "Impressum",
      }
    },
    "content_check": "Inhaltsprüfung",
    "terms_of_payment": "Zahlungsbedingungen",
    "terms_of_payment_edit": "Zahlungsbedingungen bearbeiten",
    "cancel_impersonation": "Rollentausch beenden",
    "impersonate": "Rolle annehmen",
    "template_deleted_warning": "Achtung! Das Template der Edition ist nicht mehr verfügbar.",
    "click_to_enable_editing": "Klicken Sie hier, um die Bearbeitung auf der aktuellen Registerkarte zu aktivieren.",
    "click_to_send_a_request_to_enable_editing": "Klicken Sie hier, um eine Anfrage zum Aktivieren der Bearbeitung zu senden",
    "waiting_for_form_release": "Warte auf Freigabe",
    "already_edited_by_another_user": "Diese Edition wird möglicherweise bereits von einem anderen Benutzer bearbeitet.",
    "please_wait": "Bitte warten Sie.",
    "user_already_requested_release": "hat bereits die Freigabe beantragt",
    "user_requested_that_you_give_up_the_editing": "bittet um die Erlaubnis zum Bearbeiten.",
    "emergency_release": "Freigabe erzwingen. Nutzung auf eigenes Risiko. Edition könnte von einem anderen Benutzer überschrieben werden, wenn dieser im Moment daran arbeitet.",
    "alt_debtor_number": "Alternative Debitor-Id",
    "use_bootstrap_label": "Bootstrap",
    "use_bootstrap_infotext": "Wenn Sie diese Option aktivieren, werden die Bootstrap Bibliotheken eingebunden",
    "last_queried_attributes": "Zuletzt abgefragte Preise",
    "transfer_campaign": "Übertragen",
    "duplicate_campaign_description": "Duplizieren Sie die Kampagne und weisen Sie sie einzelnen Nutzern oder allen Nutzern eines Unternehmens zu",
    "duplicate_campaign": "Duplizieren",
    "clone_to_single_user": "Duplikat erstellen für einen oder mehrere Benutzer",
    "clone_to_all_users_of_company": "Duplikat erstellen für alle Benutzer eines Unternehmens",
    "enum_option_invalid": "Ungültige Option",
    "pdf_download_option": {
      "enabled": "Aktiviert",
      "disabled": "Deaktiviert",
      "inherited": "Vererbt",
      "title": "PDF-Download erlaubt",
      "loading": "Vererbte Einstellung laden",
      "effective_setting": "effektive Einstellung"
    },
    "choose_image_for_filter": "Bitte wählen Sie ein Bild",
    "invoice_email_bcc": {
      "send_new_invoice_email_bcc": "Neue Rechnung per E-Mail senden BCC",
      "email_address_bcc": "E-Mail Adresse BCC",
      "info_text": "Eine versteckte Kopie der neuen Rechnungs-E-Mail wird auch an diese Adresse gesendet."
    },
    "reduced_vat_rate": {
      "cart_item_hint": "Dieser Artikel kommt nach einer manuellen Überprüfung durch einen Creacheck-Mitarbeiter für einen reduzierten Mehrwertsteuersatz in Frage"
    },
    "forgot_password_infotext": "Wenn Sie Ihr Passwort vergessen haben, können Sie es hier zurücksetzen. Bitte geben Sie Ihre registrierte E-Mail Adresse in das untenstehende Formular ein und klicken Sie auf „Fortsetzen“. Darauf senden wir Ihnen eine E-Mail mit weiteren Infos.",
    "forgot_password_infotext_robethood": "Wenn Du dein Passwort vergessen hast, kannst du es hier zurücksetzen. Bitte gebe die registrierte E-Mail Adresse in das untenstehende Formular ein und klicke auf „Fortsetzen“. Darauf senden wir Dir eine E-Mail mit weiteren Infos.",
    "forgot_password_info_email_not_used": "Wenn Sie die mit Ihrem Konto verknüpfte E-Mail-Adresse nicht mehr verwenden, kontaktieren Sie uns bitte über unser Kontaktformular:",
    "forgot_password_info_email_not_used_robethood": "Wenn Du die mit Deinem Konto verknüpfte E-Mail-Adresse nicht mehr verwendest, kontaktiere uns bitte über unser Kontaktformular:",
    "invoice_greeting_male": "Sehr geehrter Herr ",
    "invoice_greeting_female": "Sehr geehrte Frau ",
    "invoice_greeting_text": "herzlichen Dank für Ihre Bestellung über %company%. Wir berechnen Ihnen folgende Leistungen:",
    "bank_account": "Bankverbindung",
    "mandatory_field": "Deklarieren Sie dieses als Pflichtfeld",
    "custom_checkbox": "Benutzerdefinierte Checkbox",
    "set_label_checkbox": "Bitte vergeben Sie ein Label für die Checkbox",
    "set_checkbox_link": "Hier können Sie das Label verlinken",
    "change_checkbox_link": "Link ändern",
    "checkbox_infotext": "Sie können das Label verlinken, indem Sie den entsprechenden Bereich in $-Zeichen setzen. z.B. Ich stimmte den $Datenschutzbestimmungen$ zu.",
    "perforation": {
      "cart_radiobox_title": "Optionen für die Abheftlochung",
      "attribute_is_custom_fa_setting": "Ist kundenspezifisch FA vorgewählte Perforationsoptionen",
      "set_perforation_options": "Perforationsoptionen einstellen",
      "preset": "Voreinstellung",
      "how_to": "Bitte suchen Sie nach der Produktgruppenkennung, wählen Sie einige Varianten aus, probieren Sie verschiedene Mengen und Versandarten aus. Wählen Sie dann die Perforationsoption aus, die mit diesem Attribut vorgewählt werden soll. Es können mehrere Optionen hinzugefügt werden.",
      "select_variant": "Bitte wählen Sie eine Variante aus, um deren Mengen und Versandarten anzuzeigen.",
      "select_shipping_type": "Bitte klicken Sie auf eine Versandart, um die verfügbaren Optionen anzuzeigen.",
      "select_option_value": "Bitte klicken Sie auf eine Option, um sie zur Auswahl hinzuzufügen.",
      "please_try_other": "Bitte versuchen Sie eine andere Variante, Menge oder Versandart",
      "options": "Optionen",
      "other_options": "andere Optionen",
      "selected_options": "gewählte Optionen",
      "perforation_options": "Perforationsmöglichkeiten",
      "product_group": "Produktgruppe",
      "variants": "Varianten",
      "quantities_in_variant": "Mengen in Variante"
    },
    "textile_select_exactly_n_items": "Bitte wählen Sie genau {total}",
    "textile_exact_number_selected": "Alles gut: genau {total} ausgewählte Artikel",
    "textile_order_quantity_mismatch": "Die Anzahl der Textilgrößen muss mit der gewählten Auflage übereinstimmen.",
    "tracking_pixel_help_text": "Bitte beachten Sie, dass sich die Implementierung je nach Anbieter unterscheiden kann. In unserem Beispiel verwenden wir das Tracking Pixel von Meta. Die Integration erfordert in erster Linie eine Domainverifizierung. Dazu müssen Sie ein Meta-Tag integrieren, das in unserem Beispiel von Meta bereitgestellt wurde. Siehe im Beispielcode unten. Bitte beachten Sie, dass die Verifizierung je nach Anbieter bis zu 72 Stunden in Anspruch nehmen kann. Im zweiten Schritt ergänzen Sie den Basiscode für die Tracking Pixel Integration. Für weitere Informationen lesen Sie bitte die bereitgestellte Dokumentation Ihres jeweiligen Anbieters.",
    "reg_greeting_male": "Sehr geehrter Herr ",
    "reg_greeting_female": "Sehr geehrte Frau ",
    "reg_gender_male": "Herr ",
    "reg_gender_female": "Frau ",
    "reg_name": "Anrede und Name",
    "reg_phone_number": "Telefonnummer",
    "reg_domain": "Domäne",
    "reg_ancestors": "Struktur-ID's",
    "reg_explanation": "<p>vielen Dank für Ihre Registrierung. Bitte bestätigen Sie innerhalb von 24 Stunden Ihre E-Mail-Adresse.</p><p>Nur mit einer bestätigten E-Mail Adresse können Sie ein neues Passwort oder Infos über Änderungen bekommen.</p>",
    "loading": "Laden",
    "no_data": "Keine Daten",
    "whiteLabel": "WhiteLabel",
    "widget_login_stats": {
      "widget_title": "Login-Statistiken",
      "number_of_logins_label_title": "Anzahl der Anmeldungen"
    },
    "widget_edition_stats": {
      "widget_title": "Statistik der Ausgabe",
      "social_media_downloads_label_title": "Social Media Downloads",
      "total_editions_label_title": "Ausgaben insgesamt",
      "amount_of_new_edition_per_date": "Anzahl der neuen Ausgaben pro Datum",
      "amount_of_new_edition_per_template_per_date": "Anzahl der neuen Ausgaben pro Vorlage",
    },
    "widget_item_stats": {
      "widget_title": "Artikelstatistiken",
      "used_coupons_title": "Benutzte Gutscheine",
      "turnovers_title": "Umsatz",
      "for_items": "für Artikel",
      "total_used_coupons_count_label_title": "Benutzte Gutscheine insgesamt",
      "total_turnovers_count_label_title": "Umsätze insgesamt",
      "total_used_coupons_value_label_title": "Gesamtwert der verwendeten Gutscheine",
      "total_turnovers_value_label_title": "Gesamtwert der Umsätze",
    },
    "widget_order_stats": {
      "title1": "Aufträge insgesamt",
      "title2": "Die 10 meistbestellten Produkte",
      "orders_count": "Aufträge zählen",
      "items_count": "Produkte zählen",
      "item_types_count": "Produkte (Typen)",
      "orders_gross_total": "Aufträge brutto insgesamt",
      "no_orders_in_date_range": "Keine Aufträge innerhalb des gewählten Zeitraums",
      "loading": "Laden",
      "this_month": "Diesen Monat",
      "previous_month": "Letzter Monat",
      "last_3_months": "Letzte 3 Monate",
      "last_6_months": "Letzte 6 Monate",
      "last_12_months": "Letzte 12 Monate",
      "for_companies": "für Firma(en)",
      "for_clients": "für den/die Kunden"
    },
    "cart_domain": "Die von Ihnen angegebene Domäne",
    "cart_domain_available": "ist verfügbar!",
    "cart_domain_not_available": "ist nicht mehr verfügbar!",
    "cart_domain_not_available_title": "Domäne nicht verfügbar",
    "pages_to_delete": "Folgende Seiten werden entfernt: ",
    "page_to_delete": "Seite ",
    "sales_doc_reverse_charge": "Der Rechnungsausweis erfolgt ohne Umsatzsteuer, da vorliegend der Wechsel der Steuerschuldnerschaft (Reverse-Charge-Verfahren) greift. Die Umsatzsteuer ist vom Leistungsempfänger anzumelden und abzuführen.",
    "invoice_for_service": "Rechnung für Dienstleistung",
    "phone_invalid_country": "Ländercode der Telefonnummer ist ungültig",
    "mobile_phone_invalid_country": "Ländercode der Mobiltelefonnummer ist ungültig",
    "fax_invalid_country": "Ländercode der Faxnummer ist ungültig",
    "search_campaign": "Nach Kampagnen suchen",
    "coupon_usage": "Gutschein Verwendung",
    "coupon_balance_title": "Coupon Bilanz",
    "get_coupon_balance": "Restbetrag abfragen",
    "total_coupon_budget": "Budget Gesamt:",
    "remaining_coupon_budget": "Restliches Budget:",
    "spent_coupon_budget": "Verbrauchtes Budget:",
    "coupon_balance_success": "Abfrage erfolgreich",
    "upload_print_data_previews": "Druckdaten Vorschaubilder hochladen",
    "uploaded_print_data_previews": "Hochgeladene Druckdaten Vorschaubilder",
    "confirm_upload": "Bestätigen",
    "image_minimum_size": "Bild entspricht nicht der Mindestgröße!",
    "data_policy_info": "Datenschutz und Impressum",
    "fill_data_policy_before_save": "Bitte füllen Sie vor dem Speichern die Angaben zum Datenschutz und Impressum vollständig aus. Diese finden Sie in den Einstellungen",
    "subscription_liu_subscribe": "Abonnieren",
    "subscription_liu_cancel": "Kündigen",
    "subscription_liu_extend": "Verlängern",
    "subscription_status_changed_to_active": "Status geändert auf aktiv!",
    "subscription_status_changed_to_inactive": "Status geändert auf inaktiv!",
    "sub_start_end": "Das Startdatum kann nicht größer sein als das Enddatum!",
    "subscription_liu_send": "Absenden",
    "subscription_to_client": {
      "subscription_not_available": "Bitte wählen Sie ein Abonnement!",
      "type_parameter_not_present": "The parameter %type% is missing",
      "date_parameter_not_present": "Der Parameter %type% fehlt",
      "end_date_greater_then_start_date": "Das Enddatum kann nicht größer sein als das Startdatum!",
      "end_date_less_then_first_day_of_next_month": "Das Enddatum kann nicht früher liegen als das erste Datum des nächsten Monats!"
    },
    "subscription_billing_period": "Abrechnungsperiode",
    "subscription_set_availability_period": "Verfügbarkeitszeitraum des Abonnements festlegen",
    "mail_greeting_female": "Guten Tag Frau ",
    "mail_greeting_male": "Guten Tag Herr ",
    "mail_greeting_first_name_form": "Hallo ",
    "mail_preview": "E-Mail Vorschau",
    "mail_preview_update": "Vorschau starten/aktualisieren",
    "mail_preview_close": "Schließen",
    "mail_preview_hint": "Achtung, die Vorschau dient nur zur Prüfung des Layouts und der Messages. Alle Templates enthalten Dummydaten.",
    "tracking_details": "Für mehr Informationen klicken Sie auf die Sendungsverfolgungsnummer",
    "ups_tracking_title": "Sendungsverfolgung via API (UPS)",
    "ups_tracking_button": "Status abfragen",
    "tracking_informations": "Sendungsinformationen",
    "ups_tracking_status": "Status Ihrer Sendung (UPS)",
    "shipment_package": "Paket",
    "shipment": "Sendung",
    "shipment_package_current_status": "Aktueller Status",
    "shipment_package_current_status_description": "Beschreibung",
    "shipment_package_delivery_date": "Geliefert am",
    "shipment_package_delivery_location": "Empfangen wo",
    "shipment_package_delivery_receivedBy": "Empfangen von",
    "tracking_error": "Bei der Abfrage ist ein Fehler aufgetreten!",
    "website_title": "Title für Website (Suchmaschinen) und PDF (Barrierefreiheit)",
    "basic_billing_settings": "Grundsätzliche Abrechnungseinstellungen",
    "currency_and_vat": "Währung und MwSt",
    "basic_client_settings": "Grundeinstellungen",
    "client_settings_info_text": {
      "info_text_phone": "Um Ihre Festnetznummer korrekt zu erfassen, geben Sie bitte die vollständige Nummer inklusive Landesvorwahl an. Achten Sie darauf, dass Sie keine Leerzeichen oder Sonderzeichen verwenden. Beispiel für Deutschland: +49XXXXXXXXX.",
      "info_text_mobile": "Um Ihre Mobilnummer korrekt zu erfassen, geben Sie bitte die vollständige Nummer inklusive Landesvorwahl an. Achten Sie darauf, dass Sie keine Leerzeichen oder Sonderzeichen verwenden. Beispiel für Deutschland: +49XXXXXXXXX.",
      "info_text_demo": "Aktivieren Sie den Demo-Modus um z.b. Testbestellungen durchzuführen.",
      "info_text_image_rights_query": "Indem Sie diese Option aktivieren, wird bei jedem Hochladen eines Bildes eine zusätzliche Abfrage angezeigt. Sie werden dann aufgefordert zu bestätigen, dass Sie die Nutzungsrechte für das hochgeladene Bild besitzen. Dies hilft uns, sicherzustellen, dass alle hochgeladenen Bilder rechtlich einwandfrei sind und keine Urheberrechte verletzt werden.",
      "info_text_pdf_download_option": "Aktiviert die PDF-Download Option im Editor.",
      "info_text_needs_content_authorization": "Wenn Sie die Inhaltsprüfung aktivieren, müssen alle Bestellungen die von Ihren Firmenmitgliedern durchgeführt werden, manuell geprüft und bestätigt werden.",
      "info_text_main_language": "Bestimmen Sie hier die Sprache, in der Sie die Anwendung hauptsächlich nutzen möchten. Durch die Auswahl einer Sprache passen wir die Benutzeroberfläche Ihren Bedürfnissen an.",
      "info_text_password_renew": "Wenn Sie diese Option aktivieren, senden wir dem Benutzer bei der nächsten Anmeldung eine Email zur Erneuerung des Passwortes",
      "info_text_editor_auto_save": "Wenn Sie diese Option aktivieren, speichert der Editor automatisch alle 5 Minuten Ihre Änderungen."
    },
    "content_check_status_rejected": "abgelehnt",
    "content_check_status_approved": "genehmigt",
    "cart_remaining_user_budget": "Restliches Benutzerbudget",
    "cart_remaining_user_budget_after_order": "Verbleibendes Benutzerbudget nach der Bestellung",
    "cart_budget_user": "Benutzerbudget",
    "archived_product_templates": "Archivierte Templates",
    "archived_product_templates_description": "Hier finden Sie alle archivierten Templates",
    "archive_template": "Archivieren",
    "restore_template": "Wiederherstellen",
    "double_opt_in": "Double Opt-In Zustimmungsverfahren",
    "export_video": "Video exportieren",
    "available_actions": "Verfügbare Aktionen",
    "mail_text_rhenus": {
      "support": "FAQ & Hilfe",
      "login": "Login",
      "thank_you": "Vielen Dank für deine Bestellung!",
      "possible_actions_default": "Unter <a href='%domain%'><span class='r_url bold'>%domain%</span></a> kannst du dir jederzeit deine Bestellung ansehen.",
      "call_to_action_order_details_default": "Bestelldetails",
      "order_foreign_status": {
        "in_delivery": {
          "explanation_first_name_form": "deine Bestellung wurde produziert und an den Kurier zur Auslieferung übergeben.",
          "possible_actions_tracking_first_name_form": "Verfolge deine Lieferung unter",
        }
      },
      "order_item_sales": {
        "explanation": "nachfolgend sind die Daten zu deiner Bestellung aufgelistet:",
      },
      "order_content_check_status": {
        "explanation": "danke für deine Bestellung. Die Bestellung wurde nach der Inhaltsprüfung %status%.",
      },
      "contact_form_edition": {
        "subject": "Neuer Kontakt",
        "info": "Benachrichtigung über die Einreichung eines Kontaktformulars. Bitte bearbeite dies umgehend und stelle sicher, dass der Kunde die bestmögliche Unterstützung erhält.",
        "form_fields": "Ausgefüllte Formularfelder"
      },
      "contact_form_cms": {
        "subject": "Neuer Kontakt von %name%",
        "info": "Benachrichtigung über die Einreichung eines Kontaktformulars. Bitte bearbeite dies umgehend und stelle sicher, dass der Kunde die bestmögliche Unterstützung erhält."
      },
      "password_renew": {
        "greeting": "Hallo %email%,",
        "explanation_first_name_form": "du hast versucht dich bei %platformName% anzumelden. Dein Passwort ist abgelaufen. Du musst ein neues Passwort vergeben um dich zuk&uuml;nftig anmelden zu können.",

      },
      "password_reset": {
        "greeting": "Hallo %email%,",
        "explanation_first_name_form": "über unsere Plattform wurde soeben ein neues Passwort bei %platformName% angefordert. Sofern du diese Anfrage gestellt hast, klicke auf den unten stehenden Link.",

      }
    },
    "open_in_new_tab": "Öffnen",
    "failed_video_generation": "Video konnte nicht generiert werden! Bitte überprüfen Sie, ob die hochgeladenen Dateien gültig sind!",
    "video_uploads_missing": "Keine Video-Uploads in Editionsblöcken gefunden!",
    "subscription_video_missing": "Das Video-Abonnement wurde nicht gefunden!",
    "download_invoices": "Download Rechnungen",
    "download_user_list": "Download Benutzerliste",
    "cookie_management": "Cookie-Zustimmungsmanager",
    "cookie_manager_id": "Cookie-Manager-URL (optional) - ccm19.de",
    "supported_cookie_manger_provider": "Es werden derzeit nur Cookie Constent Manager von ccm19.de unterstützt.",
    "cookie_management_custom": "Diese Einstellung ist optional. Es wird standardmäßig ein Cookie-Zustimmungsmanger auf Ihrer Website integriert.",
    "print_accessibility_pdf": "Druck-PDF (barrierefreies PDF)",
    "accessibility_settings": {
      "accessibility": "Barrierefreiheit",
      "language_of_text": "Sprache des Textes (Ländercodes - ISO 639-1)",
      "language_example": "Geben Sie hier den Ländercode der Sprache ein, die Sie in Ihrem Dokument oder auf Ihrer Webseite verwenden. (Ländercodes - ISO 639-1) z.B. de für deutsch (Standard), oder en für englisch",
      "activate_accessibility_pdf": "Barrierefreies PDF - UA-1",
      "bookmarks": "Lesezeichen",
      "use_bookmark_classes": "Lesezeichen-Klassen",
      "template_settings_bookmarks_info": "Aktiviert die Klassen (.bookmarks-l1 bis .bookmarks-l6) die als Lesezeichen gesetzt werden. Die Lesezeichen-Optionen H1-H6 werden dabei deaktiviert.",
      "edition_settings_bookmarks_info_classes": "Verwenden Sie die verfügbaren Titel- bzw. Lesezeichenelemente, um Lesezeichen in der PDF-Ausgabe zu setzen.",
      "edition_settings_bookmarks_info_h": "Wenn Sie diese Option aktivieren, werden die jeweils verwendeten Überschriften in der PDF-Ausgabe als Lesezeichen gesetzt. Beispielsweise 'Lesezeichen H1' setzt Lesezeichen für alle Elemente die eine Überschrift 1 bzw. den Tag H1 beinhalten.",
      "accessible_pdf_infos": "Anforderungen für barrierefreie PDF Dokumente",
      "accessible_pdf_infos_1": "1. Metadaten: Setzen Sie einen Titel und eine kurze Inhaltsangabe.",
      "accessible_pdf_infos_2": "2. Spracheinstellungen: Setzen Sie die jeweilige Sprache die Sie in Ihrer Edition verwenden.",
      "accessible_pdf_infos_3": "3. Tagged (etikettiertes) PDF: Tags und die Lesereihenfolge werden automatisch gesetzt.",
      "accessible_pdf_infos_4": "4. Alternativer Text für Bilder: Sie haben in der Mediathek oder während des Uploads  die Möglichkeit „Beschreibungen“ festzulegen. Diese werden als alternativer Text für Bilder verwendet.",
      "accessible_pdf_infos_5": "5. Barrierefreie Tabellen: Wenn Sie Tabellen verwenden, setzen Sie die jeweiligen Kopfzeilen bzw. Kopfspalten.",
      "accessible_pdf_infos_6": "6. Text als Bild: Benutzen Sie möglichst keinen Text als Bild",
      "accessible_pdf_infos_7": "7. Lesbare Schriftarten: Verwenden Sie Schriftarten, die von Bildschirmleseprogrammen leicht dekodiert werden können. Vermeiden Sie Schriftarten, die für Screenreader schwer zu interpretieren sind.",
      "accessible_pdf_infos_8": "8. Navigationshilfen: Fügen Sie die entsprechenden Elemente (Titel- und Lesezeichenelemente) ein, um Lesezeichen, Links und Inhaltsverzeichnisse zu erstellen, um die Navigation im Dokument zu erleichtern. Möglicherweise stehen Ihnen weitere Optionen in den Einstellungen zur Verfügung. Siehe Einstellungen -> Lesezeichen.",
      "accessible_pdf_infos_9": "9. Farbkontrast: Achten Sie darauf, dass Texte und Hintergründe für Menschen mit Sehbehinderungen ausreichend Kontrast bieten. Vermeiden Sie Farbkombinationen, die schwer zu unterscheiden sind.",
    },
    "save_options": "Speichern",
    "print_options": "PDF exportieren",
    "css_accessibility_pdf": "CSS für Ausgabe barrierefreie PDF",
    "css_accessibility_pdf_info_text": "Bitte beachten Sie, bei der Erstellung von barrierefreien PDF's wird vom Produkt-CSS nur das 'generelle CSS' und das 'CSS für barrierefreie PDF's' verwendet. Vom Client, Whitelabel oder Template wird auch das 'CSS für die PDF Ausgabe' verwendet.",
    "payment_options": "Paypal / Kreditkarte / Lastschrift ",
    "default_logo_size": "E-Mail Logo. Bitte beachten Sie, dass für das Email-Standard-Layout eine Logogröße von ...px x 175px (H x B) benötigt wird. Außerdem muss die Option 'Mail Logo' aktiviert werden.",
    "lead_authorization": {
      "authorization": "Autorisierung",
      "status": "Status",
      "approved": "genehmigt",
      "rejected": "abgelehnt",
      "pending": "ausstehend",
      "type": "Typ"
    },
    "pipeline_owner": "Eigentümer",
    "set_to_null": "Setze Eigentümer auf null"
  }
}
