import React from 'react';
import { connect } from 'react-redux';
import {Editor} from '../cc-editor';

import Loader from "../components/layout/Loader";
import {injectIntl} from "react-intl";
import {withRouter} from "react-router-dom";
import {setLoginForwardUrl} from "../actions/LoginActions";
import {Prompt} from "react-router";

import {getEditionR, save_ContentR, setVariables, saveToR, initTemplateR, getCampaignsR, setChangesR} from '../../js/actions/EditorActions';


class EditorCC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editionId: props.match.params.editionId,
      edition: null,
      worker: null,
      unsaved:false,
      pending:true,
    }
  }

  setUnsaved = (unsave) => {
    this.setState({unsaved: unsave});
    const {setVariables} = this.props;
   setVariables(unsave);
  }

  /*
  getEdition = (editionId) => {
    const {getEditionR} = this.props;
    console.log("geteditionId", editionId);
  getEditionR(editionId);


    Axios.get('/api/editions/'+editionId+'.json')
      .then(response => {

        this.setState({
          edition: response.data,
          pending: false
        })
      })
      .catch(error => {
        console.log(error);
      })
      .then( () => {
        // always executed
      });

  }
*/
  componentDidMount = () => {
    const { appIntl, location, history, user, setLoginForwardUrl, getEditionR, pendingR} = this.props;
    const {pending} = this.state;
    if (!pendingR && !user.logged && !user.adhocLogout && !prevProps.user.adhocLogout) {
      setLoginForwardUrl(location.pathname);
      history.push(appIntl.urlPrefix + 'login');
    }

    getEditionR(this.state.editionId);
    if (
      'serviceWorker' in navigator
    ) {
      window.addEventListener('load', () => {
         const sw = navigator.serviceWorker.register('https://' + window.location.host + '/sw.js', {scope: appIntl.urlPrefix +'editor'}).then(r => {
           navigator.serviceWorker.addEventListener('message', function _worker(event) {
             console.log( 'Message:', event.data.msg );
           });
           this.setState({
             worker: sw
           });
        });

      });
    } else {
      console.log('serviceWorker not available')
    }
  }


  componentDidUpdate = (prevProps) => {
    const { appIntl, location, history, user, setLoginForwardUrl, editionRV, pendingR } = this.props;
    const {pending} = this.state;
    // if the user is not logged in, then redirect him to the login page
    if (!pendingR && !user.logged && !user.adhocLogout && !prevProps.user.adhocLogout) {
      if (!prevProps.user.logged) {
        setLoginForwardUrl(location.pathname);
      }
      history.push(appIntl.urlPrefix + 'login');
    }
  };



  render() {
    const { user, history, appIntl, editionRV, pendingR, save_ContentR, savingR, unsavedR, setVariables, linkDataR, saveToR, initTemplateR, getCampaignsR, forwardingR, previewingR, accessibleR, printingR, printx, exportingR, campaignOptionsR, changesR, setChangesR, showErrorMessage, waitForSaving} = this.props;
    const {edition, pending, worker, unsaved} = this.state;
    const {intl: { messages }} = this.props;
    //todo firstTime from user data
    return (

      <div className="content">
        <Prompt
          when={unsavedR}
          message={(location, action) => {
            return location.pathname.includes("/editor/")
              ? true
              : <>{messages.editor.unsaved_content}</>
          }}
        />
       {user.logged && !pendingR ? (
          <Editor
            edition = {editionRV}
            appIntl = {appIntl}
            history={history}
            showTour={false}
            user={user}
            worker = {worker}
            setUnsaved={this.setUnsaved}
            save_ContentR={save_ContentR}
            savingR = {savingR}
            setVariables = {setVariables}
            unsavedR = {unsavedR}
            saveToR = {saveToR}
            linkDataR = {linkDataR}
            initTemplateR = {initTemplateR}
            getCampaignsR = {getCampaignsR}
            forwardingR = {forwardingR}
            globalCss=".page p{line-height:initial;} nav.page-sidebar, #main-footer, #cm_footer_menu, .creacheck_footer{display:none !important;} #cm_footer_menu_whitelabel {display:none !important;} #main-container{padding-left: 0 !important;} .with-sidebar .horizontal-menu .header .brand{padding-left:35px !important;}body .chatter-position, body.themed .chatter-position, div.themed .chatter-position{bottom:65px !important;}"
            previewingR={previewingR}
            accessibleR={accessibleR}
            printingR={printingR}
            printx={printx}
            exportingR={exportingR}
            campaignOptionsR={campaignOptionsR}
            changesR={changesR}
            setChangesR={setChangesR}
            showErrorMessage={showErrorMessage}
            waitForSaving={waitForSaving}
          />) : (
          <Loader/>
        )}
      </div>

    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.user,
    appIntl: state.intl,
    editionRV:state.editor.editionRV,
    pendingR: state.editor.pendingR,
    savingR: state.editor.savingR,
    unsavedR: state.editor.unsavedR,
    linkDataR: state.editor.linkDataR,
    campaignOptionsR: state.editor.campaignOptionsR,
    forwardingR: state.editor.forwardingR,
    previewingR:state.editor.previewingR,
    accessibleR:state.editor.accessibleR,
    exportingR:state.editor.exportingR,
    printingR: state.editor.printingR,
    printx: state.editor.printx,
    changesR: state.editor.changesR,
    showErrorMessage: state.editor.showErrorMessage,
    waitForSaving: state.editor.waitForSaving,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setLoginForwardUrl: forwardUrl => {
      dispatch(setLoginForwardUrl(forwardUrl));
    },
    getEditionR: (data) => {
      dispatch(getEditionR(data));
    },
    save_ContentR: (data, datab) => {
      dispatch(save_ContentR(data, datab));
    },
    setVariables: (data) => {
      dispatch(setVariables(data));
    },
    saveToR: (data) => {
      dispatch(saveToR(data));
    },
    initTemplateR: (data, datab) => {
      dispatch(initTemplateR(data, datab));
    },
    getCampaignsR: (filters) => {
      dispatch(getCampaignsR(filters));
    },
    setChangesR: (data) => {
      dispatch(setChangesR(data));
    },
  };
};

export default injectIntl(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(EditorCC)
  )
);
